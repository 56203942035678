import axios from 'axios'


export default {
    sendLoginRequest(formData, client_name) {
        console.log("in authService > sendLoginRequest")
        return sendLoginRequestCustomer(formData, client_name)
    },
    sendVerifyCodeEmailRequest(formData, client_name) {
        return sendVerifyCodeEmailRequestCustomer(formData, client_name)
    },
    sendVerifyLoginCodeByAppRequest(formData, client_name) {
        return sendVerifyLoginCodeByAppRequestCustomer(formData, client_name)
    },
    sendVerifyLoginCodeByEmailRequest(formData, client_name) {
        return sendVerifyLoginCodeByEmailRequestCustomer(formData, client_name)
    },

    sendForgetPasswordEmailRequest(formData, client_name) {
        return sendForgetPasswordEmailRequestCustomer(formData, client_name)
    },
    sendResetPasswordCodeRequest(formData, client_name) {
        return sendResetPasswordCodeRequestCustomer(formData, client_name)
    },
    sendResetPasswordRequest(formData, client_name) {
        return sendResetPasswordRequestCustomer(formData, client_name)
    },

    sendChangePasswordRequest(formData, client_name, token) {
        return sendChangePasswordRequestCustomer(formData, client_name, token)
    },
}

async function sendLoginRequestCustomer(formData, client_name) {
    let response_output = {
        code: 200,
        response: null
    }
    await axios
        .post("user/login", {
            email: formData.email,
            password: formData.password,
            client: client_name,
        })
        .then((response) => {
            console.log(response);
            console.log(response.data);

            console.log("success")

            response_output.code = 200
            response_output.response = { "user": response.data }

            // window.sessionStorage.setItem("email", this.formData.email);
            // window.sessionStorage.setItem("user", JSON.stringify(response.data));
            // window.sessionStorage.setItem("user_type", "user");
            // this.$router.push({ name: "ChooseVerificationMethod" });
            // window.localStorage.setItem("user", JSON.stringify(response.data));
            // window.localStorage.setItem("user_type", "user");
            // // this.$router.push({ name: "SituationAnalysisIndex" });

            // this.$router.push({ path: '/situation_analysis' })
            // .then(() => { this.$router.go(0) })


        })
        .catch((error) => {
            console.log("error");
            console.log(error);
            console.log("error.response.data.error");
            console.log(error.response.data.error);
            console.log("error.request");
            console.log(error.request);
            console.log("error.message");
            console.log(error.message);

            let error_message = ""

            if (error.response) {
                error_message = error.response.data.error

            } else {
                error_message = error.message
            }


            response_output.code = 400
            response_output.response = { error_message: error_message }
            // this.loginErrorMessage = error.response.data.error;
            // this.showLoginErrorMessage = true;

            // this.$swal({
            //     // title: "Title",
            //     text: error.response.data.error,
            //     icon: "error",
            //     // timer: 3000,
            //     customClass: {
            //         htmlContainer: "!text-2xl",
            //         confirmButton: "!bg-grey-protostars",
            //     },
            // }).then((r) => {
            //     // console.log(r.value);
            //     this.is_loading = false;
            // });
        });

    return response_output

}

async function sendVerifyCodeEmailRequestCustomer(formData, client_name) {
    console.log("sendVerifyCodeEmailRequestCustomer")
    console.log(client_name, formData)
    let response_output = {
        code: 200,
        response: null
    }
    await axios
        .post("common/two-factor/send-code/email", formData,
            {
                headers: {
                    client: client_name,
                },
            }
        )
        .then((response) => {
            console.log(response);
            console.log(response.data);

            console.log("success")

            response_output.code = 200
            response_output.response = response.data

            // window.sessionStorage.setItem("email", this.formData.email);
            // window.sessionStorage.setItem("user", JSON.stringify(response.data));
            // window.sessionStorage.setItem("user_type", "user");
            // this.$router.push({ name: "ChooseVerificationMethod" });
            // window.localStorage.setItem("user", JSON.stringify(response.data));
            // window.localStorage.setItem("user_type", "user");
            // // this.$router.push({ name: "SituationAnalysisIndex" });

            // this.$router.push({ path: '/situation_analysis' })
            // .then(() => { this.$router.go(0) })


        })
        .catch((error) => {
            console.log("error");
            console.log(error);
            console.log("error.response.data.error");
            console.log(error.response.data.error);
            console.log("error.request");
            console.log(error.request);
            console.log("error.message");
            console.log(error.message);

            let error_message = ""

            if (error.response) {
                error_message = error.response.data.error

            } else {
                error_message = error.message
            }


            response_output.code = 400
            response_output.response = { error_message: error_message }

        });

    return response_output

}


async function sendVerifyLoginCodeByAppRequestCustomer(formData, client_name) {
    console.log("sendVerifyCodeByAppRequestCustomer")
    console.log(client_name, formData)
    let response_output = {
        code: 200,
        response: null
    }
    await axios
        .post("common/two-factor/check-code/authenticator", formData,
            {
                headers: {
                    client: client_name,
                },
            }
        )
        .then((response) => {
            console.log(response);
            console.log(response.data);

            console.log("success")

            if (response.data.is_valid) {
                response_output.code = 200
                response_output.response = response.data
            } else {

                response_output.code = 400
                response_output.response = { error_message: 'The code is invalid' }
            }

        })
        .catch((error) => {
            console.log("error");
            console.log(error);
            console.log("error.response.data.error");
            console.log(error.response.data.error);
            console.log("error.request");
            console.log(error.request);
            console.log("error.message");
            console.log(error.message);

            let error_message = ""

            if (error.response) {
                error_message = error.response.data.error

            } else {
                error_message = error.message
            }


            response_output.code = 400
            response_output.response = { error_message: error_message }

        });

    return response_output

}
async function sendVerifyLoginCodeByEmailRequestCustomer(formData, client_name) {
    console.log("sendVerifyCodeByEmailRequestCustomer ee")
    console.log(client_name, formData)
    let response_output = {
        code: 200,
        response: null
    }
    await axios
        .post("common/two-factor/check-code/email", formData,
            {
                headers: {
                    client: client_name,
                },
            }
        )
        .then((response) => {
            console.log(response);
            console.log(response.data);

            console.log("success")

            if (response.data.is_valid) {
                response_output.code = 200
                response_output.response = response.data
            } else {
                response_output.code = 400
                response_output.response = { error_message: 'The code is invalid' }
            }
        })
        .catch((error) => {
            console.log("error");
            console.log(error);
            console.log("error.response.data.error");
            console.log(error.response.data.error);
            console.log("error.request");
            console.log(error.request);
            console.log("error.message");
            console.log(error.message);

            let error_message = ""

            if (error.response) {
                error_message = error.response.data.error

            } else {
                error_message = error.message
            }


            response_output.code = 400
            response_output.response = { error_message: error_message }

        });

    return response_output

}

async function sendForgetPasswordEmailRequestCustomer(formData, client_name) {
    let response_output = {
        code: 200,
        response: null
    }
    await axios
        .post(
            "user/forget-password",
            {
                email: formData.email,
                client: formData.guard,
            },
            {
                headers: {
                    // Accept: "application/json",
                    // "Content-Type": "application/json",
                    client: client_name,
                },
            }
        )
        .then((response) => {
            console.log(response);
            console.log(response.data);
            // console.log("ssss")
            response_output.code = 200
            response_output.response = { "success": response.data.success }
            // window.sessionStorage.setItem("email", this.form.email)
            // this.$router.push({ name: "CRiskResetPasswordCode" });

            //   window.localStorage.setItem("user", JSON.stringify(response.data));
            // // this.$parent.$parent.isLogin = true;
            //   
        }).catch((error) => {
            // this.sendForgetPasswordRequestAdmin()

            console.log("error");
            console.log(error);
            console.log("error.response.data.error");
            console.log(error.response.data.error);
            console.log("error.request");
            console.log(error.request);
            console.log("error.message");
            console.log(error.message);

            let error_message = ""

            if (error.response) {
                error_message = error.response.data.error

            } else {
                error_message = error.message
            }


            response_output.code = 400
            response_output.response = { error_message: error_message }

            // console.log(error);
            // console.log(error.response);
            // console.log(error.response.data.error);
            // console.log(error.request);
            // console.log(error.message);
            // this.$fire({
            //   // title: "Title",
            //   text: error.response && error.response.data && error.response.data.error ? error.response.data.error : error.message,
            //   type: "error",
            //   // timer: 3000,
            //   customClass: {
            //     container: this.swal_container_class,
            //     confirmButton: this.swal_confirm_button_class,
            //   },
            // }).then((r) => {
            //   // console.log(r.value);
            //   // this.$router.push({ name: "CRiskResetPasswordCode" });

            // });
            // document.body.classList.remove("swal2-height-auto");
        });



    return response_output;
}

async function sendResetPasswordCodeRequestCustomer(formData, client_name) {
    let response_output = {
        code: 200,
        response: null
    }
    await axios
        .post(
            "user/verfiy-otp-token",
            {
                email: formData.email,
                client: formData.guard,
                code: formData.code,
            },
            {
                headers: {
                    // Accept: "application/json",
                    // "Content-Type": "application/json",
                    client: client_name,
                },
            }
        )
        .then((response) => {
            console.log(response);
            console.log(response.data);
            // console.log("ssss")
            response_output.code = 200
            response_output.response = { "success": response.data.success }
            window.sessionStorage.setItem("code", formData.code)
            // window.sessionStorage.setItem("email", this.form.email)
            // this.$router.push({ name: "CRiskResetPasswordCode" });

            //   window.localStorage.setItem("user", JSON.stringify(response.data));
            // // this.$parent.$parent.isLogin = true;
            //   
        }).catch((error) => {
            // this.sendForgetPasswordRequestAdmin()

            console.log("error");
            console.log(error);
            console.log("error.response.data.error");
            console.log(error.response.data.error);
            console.log("error.request");
            console.log(error.request);
            console.log("error.message");
            console.log(error.message);

            let error_message = ""

            if (error.response) {
                error_message = error.response.data.error

            } else {
                error_message = error.message
            }


            response_output.code = 400
            response_output.response = { error_message: error_message }

            // console.log(error);
            // console.log(error.response);
            // console.log(error.response.data.error);
            // console.log(error.request);
            // console.log(error.message);
            // this.$fire({
            //   // title: "Title",
            //   text: error.response && error.response.data && error.response.data.error ? error.response.data.error : error.message,
            //   type: "error",
            //   // timer: 3000,
            //   customClass: {
            //     container: this.swal_container_class,
            //     confirmButton: this.swal_confirm_button_class,
            //   },
            // }).then((r) => {
            //   // console.log(r.value);
            //   // this.$router.push({ name: "CRiskResetPasswordCode" });

            // });
            // document.body.classList.remove("swal2-height-auto");
        });



    return response_output;
}

async function sendResetPasswordRequestCustomer(formData, client_name) {
    console.log("sendResetPasswordRequestCustomer")
    console.log(formData)
    console.log({
        password: formData.new_password,
        password_confirmation: formData.confirm_password,
        client: client_name,
        code: formData.code,
    })
    let response_output = {
        code: 200,
        response: null
    }
    await axios
        .post(
            "user/reset-password",
            {
                code: formData.code,
                password: formData.new_password,
                password_confirmation: formData.confirm_password,
                client: client_name,
            },
            {
                headers: {
                    // Accept: "application/json",
                    // "Content-Type": "application/json",
                    client: client_name,
                },
            }
        )
        .then((response) => {
            console.log(response);
            console.log(response.data);
            // console.log("ssss")
            response_output.code = 200
            response_output.response = { "success": response.data.success }
            // window.sessionStorage.setItem("email", this.form.email)
            // this.$router.push({ name: "CRiskResetPasswordCode" });

            //   window.localStorage.setItem("user", JSON.stringify(response.data));
            // // this.$parent.$parent.isLogin = true;
            //   
        }).catch((error) => {
            // this.sendForgetPasswordRequestAdmin()

            console.log("error");
            console.log(error);
            console.log("error.response.data.error");
            console.log(error.response.data.error);
            console.log("error.request");
            console.log(error.request);
            console.log("error.message");
            console.log(error.message);

            let error_message = ""

            if (error.response) {
                error_message = error.response.data.error

            } else {
                error_message = error.message
            }


            response_output.code = 400
            response_output.response = { error_message: error_message }

            // console.log(error);
            // console.log(error.response);
            // console.log(error.response.data.error);
            // console.log(error.request);
            // console.log(error.message);
            // this.$fire({
            //   // title: "Title",
            //   text: error.response && error.response.data && error.response.data.error ? error.response.data.error : error.message,
            //   type: "error",
            //   // timer: 3000,
            //   customClass: {
            //     container: this.swal_container_class,
            //     confirmButton: this.swal_confirm_button_class,
            //   },
            // }).then((r) => {
            //   // console.log(r.value);
            //   // this.$router.push({ name: "CRiskResetPasswordCode" });

            // });
            // document.body.classList.remove("swal2-height-auto");
        });



    return response_output;
}

async function sendChangePasswordRequestCustomer(formData, client_name, token) {


    console.log("sendChangePasswordRequestCustomer")
    console.log(formData)


    let axios_url = "user/update-password"
    //   if (this.user_type == 'admin' && this.role_type === 'reseller') {
    //     axios_url = "admin/update-password"
    //   } else if (this.user_type == 'user' && this.role_type === 'direct_client') {
    //     axios_url = "user/update-password"
    //   }

    let response_output = {
        code: 200,
        response: null
    }

    await axios
        .post(axios_url, formData, {
            headers: {
                client: client_name,
                Authorization: token,
            },
        })
        .then((response) => {
            console.log(response);

            response_output.code = 200
            response_output.response = { "success": response.data.success }
            // this.$fire({
            //     // title: "Title",
            //     text: this.$t("success_update_password"),
            //     type: "success",
            //     // timer: 3000,
            //     customClass: {
            //         container: this.swal_container_class,
            //         confirmButton: this.swal_confirm_button_class,
            //     },
            // }).then((r) => {
            //     // console.log(r.value);
            //     this.is_loading_change_password = false;
            //     this._password = null
            //     this._confirm_password = null
            //     this.old_password = null
            //     this.change_password = false
            // });
        })
        .catch((error) => {

            console.log("error");
            console.log(error);
            console.log("error.response.data.error");
            console.log(error.response.data.error);
            console.log("error.request");
            console.log(error.request);
            console.log("error.message");
            console.log(error.message);

            let error_message = ""

            if (error.response) {
                error_message = error.response.data.error

            } else {
                error_message = error.message
            }


            response_output.code = 400
            response_output.response = { error_message: error_message }

        });
    return response_output
}
