<template>
    <div class="h-full">
        <!-- <navbar-protostars class="!border-b-2 !border-b-[#e5e7eb] !border-solid	"></navbar-protostars> -->

        <!-- <h1 class="text-3xl font-bold text-center">
           Test Form Builder
        </h1> -->

        <!-- <div class="container bg-red-200">
            <h1 class="font-bold text-yellow-800 bg-[#f000fa] ml-[15px] mr-[27px] w-16 md:w-32 lg:w-48 hover:text-white">
                Test</h1>

            <h2 class="text-center bg-purple-500 w-128 md:max-lg:w-2/4 xl:w-[200px] font-serif font-bold hover:font-light 
    underline hover:decoration-blue-400">Test custom width</h2>
            <h2 class="text-center bg-purple-500 w-128 md:max-lg:w-2/4 xl:w-[200px]  font-['Inter']">Test custom width</h2>

            <div class="columns-[50%] gap-2">
                <div class="bg-blue-200 p-4">Test</div>
                <div class="bg-blue-200 p-4">Test</div>
            </div>

            <div class="container">
                <div class="grid">
                    <div class="sm:col-6">
                        Column 1
                    </div>
                    <div class="sm:col-6">
                        Column 2
                    </div>
                </div>
            </div>

        </div> -->


        <div class="form-builder-container  ">

            <VueformBuilder @save="handleSave" ref="builder$" />
            <div>
                <div class="row my-2">
                    <div class="w-full">
                        <router-link to="/form_builder/output"
                        class="btn btn-protostars btn-primary text-white px-4 py-2 rounded-lg float-right">Preview</router-link>
                      
                        
                    </div>
                </div>
                <!-- {{ myForm }}
                <hr /> -->
            </div>
        </div>

    </div>
</template>


<script>
// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';

export default {
    name: "TestFormBuilder",    
    components: {  },

    data() {
        let temp = this;
        return {
            myForm: JSON.parse(localStorage.getItem('situation_analysis')),
            // {

            //     "schema": 
            //     {
            //         "question_1": {
            //             "name": "question_1",
            //             "type": "checkboxgroup",
            //             "items": [
            //                 {
            //                     "value": "yes",
            //                     "label": "Yes"
            //                 },
            //                 {
            //                     "value": "no",
            //                     "label": "No"
            //                 }
            //             ],
            //             "label": "1. Do projects have a point of contact for security issues or incidents?",
            //             "builder": {
            //                 "type": "checkboxgroup",
            //                 "label": "1. Do projects have a point of contact for security issues or incidents?"
            //             }
            //         },
            //         "question_2": {
            //             "name": "question_2",
            //             "type": "checkboxgroup",
            //             "items": [
            //                 {
            //                     "value": "yes",
            //                     "label": "Yes"
            //                 },
            //                 {
            //                     "value": "no",
            //                     "label": "No"
            //                 }
            //             ],
            //             "label": "2. Does your organization have an assigned security response team?",
            //             "builder": {
            //                 "type": "checkboxgroup",
            //                 "label": "2. Does your organization have an assigned security response team?"
            //             }
            //         },
            //         "question_3": {
            //             "name": "question_3",
            //             "type": "checkboxgroup",
            //             "items": [
            //                 {
            //                     "value": "yes",
            //                     "label": "Yes"
            //                 },
            //                 {
            //                     "value": "no",
            //                     "label": "No"
            //                 }
            //             ],
            //             "label": "3. Are project teams aware of their security point(s) of contact and response team(s)?",
            //             "builder": {
            //                 "type": "checkboxgroup",
            //                 "label": "3. Are project teams aware of their security point(s) of contact and response team(s)?"
            //             }
            //         },
            //         "question_4": {
            //             "name": "question_4",
            //             "type": "checkboxgroup",
            //             "items": [
            //                 {
            //                     "value": "yes",
            //                     "label": "Yes"
            //                 },
            //                 {
            //                     "value": "no",
            //                     "label": "No"
            //                 }
            //             ],
            //             "label": "4. Does the organization utilize a consistent process for incident reporting and handling?",
            //             "builder": {
            //                 "type": "checkboxgroup",
            //                 "label": "4. Does the organization utilize a consistent process for incident reporting and handling?"
            //             }
            //         },
            //         "question_5": {
            //             "name": "question_5",
            //             "type": "checkboxgroup",
            //             "items": [
            //                 {
            //                     "value": "yes",
            //                     "label": "Yes"
            //                 },
            //                 {
            //                     "value": "no",
            //                     "label": "No"
            //                 }
            //             ],
            //             "label": "5. Are project stakeholders aware of relevant security disclosures related to their software projects?",
            //             "builder": {
            //                 "type": "checkboxgroup",
            //                 "label": "5. Are project stakeholders aware of relevant security disclosures related to their software projects?"
            //             }
            //         }
            //     },
            //     "theme": {}, "form": { "nesting": true }, "export": { "output": "inline", "api": "options", "theme": "none" }, "builder": { "name": "MyForm" }
            // },

            //null,
            // {
            //     "schema": {
            //         "container": {
            //             "name": "container",
            //             "type": "object",
            //             "builder": {
            //                 "type": "container",
            //                 "label": "Container"
            //             },
            //             "schema": {
            //                 "h3": {
            //                     "name": "h3",
            //                     "type": "static",
            //                     "content": "<h3 style=\"font-size: 24px; font-weight: 600; line-height: 1.5;\">Personal Info</h3>",
            //                     "builder": {
            //                         "type": "h3",
            //                         "label": "H3 header"
            //                     }
            //                 },
            //                 "text": {
            //                     "name": "text",
            //                     "type": "text",
            //                     "label": "Name",
            //                     "builder": {
            //                         "type": "text",
            //                         "label": "Text input"
            //                     }
            //                 },
            //                 "email_1": {
            //                     "name": "email_1",
            //                     "type": "text",
            //                     "inputType": "email",
            //                     "rules": [
            //                         "nullable",
            //                         "email"
            //                     ],
            //                     "label": "Email",
            //                     "builder": {
            //                         "type": "email",
            //                         "label": "Email address"
            //                     }
            //                 },
            //                 "password": {
            //                     "name": "password",
            //                     "type": "text",
            //                     "inputType": "password",
            //                     "label": "Password",
            //                     "builder": {
            //                         "type": "password",
            //                         "label": "Password"
            //                     }
            //                 }
            //             }
            //         },
            //         "textarea": {
            //             "name": "textarea",
            //             "type": "textarea",
            //             "label": "Textarea",
            //             "builder": {
            //                 "type": "textarea",
            //                 "label": "Textarea"
            //             }
            //         },
            //         "datetime": {
            //             "name": "datetime",
            //             "type": "date",
            //             "label": "Datetime",
            //             "time": true,
            //             "builder": {
            //                 "type": "datetime",
            //                 "label": "Datetime"
            //             }
            //         },
            //         "date": {
            //             "name": "date",
            //             "type": "date",
            //             "label": "Date",
            //             "builder": {
            //                 "type": "date",
            //                 "label": "Date"
            //             }
            //         },
            //         "time": {
            //             "name": "time",
            //             "type": "date",
            //             "label": "Time",
            //             "time": true,
            //             "date": false,
            //             "builder": {
            //                 "type": "time",
            //                 "label": "Time"
            //             }
            //         },
            //         "dates": {
            //             "name": "dates",
            //             "type": "dates",
            //             "label": "Dates",
            //             "builder": {
            //                 "type": "dates",
            //                 "label": "Multiple dates"
            //             }
            //         },
            //         "dateRange": {
            //             "name": "dateRange",
            //             "type": "dates",
            //             "label": "Date range",
            //             "mode": "range",
            //             "builder": {
            //                 "type": "dateRange",
            //                 "label": "Date range"
            //             }
            //         }
            //     },
            //     "theme": {
            //         "primary": "#07bf9b",
            //         "primary-darker": "#06ac8b",
            //         "color-on-primary": "#ffffff",
            //         "danger": "#ef4444",
            //         "danger-lighter": "#fee2e2",
            //         "success": "#10b981",
            //         "success-lighter": "#d1fae5",
            //         "ring-width": "2px",
            //         "ring-color": "#07bf9b66",
            //         "gray-50": "#f9fafb",
            //         "gray-100": "#f3f4f6",
            //         "gray-200": "#e5e7eb",
            //         "gray-300": "#d1d5db",
            //         "gray-400": "#9ca3af",
            //         "gray-500": "#6b7280",
            //         "gray-600": "#4b5563",
            //         "gray-700": "#374151",
            //         "gray-800": "#1f2937",
            //         "gray-900": "#111827",
            //         "font-size": "1rem",
            //         "font-size-sm": "0.875rem",
            //         "font-size-lg": "1rem",
            //         "font-size-small": "0.875rem",
            //         "font-size-small-sm": "0.8125rem",
            //         "font-size-small-lg": "0.875rem",
            //         "line-height": "1.5rem",
            //         "line-height-sm": "1.25rem",
            //         "line-height-lg": "1.5rem",
            //         "line-height-small": "1.25rem",
            //         "line-height-small-sm": "1.125rem",
            //         "line-height-small-lg": "1.25rem",
            //         "letter-spacing": "0px",
            //         "letter-spacing-sm": "0px",
            //         "letter-spacing-lg": "0px",
            //         "letter-spacing-small": "0px",
            //         "letter-spacing-small-sm": "0px",
            //         "letter-spacing-small-lg": "0px",
            //         "gutter": "1rem",
            //         "gutter-sm": "0.5rem",
            //         "gutter-lg": "1rem",
            //         "min-height-input": "2.375rem",
            //         "min-height-input-sm": "2.125rem",
            //         "min-height-input-lg": "2.875rem",
            //         "py-input": "0.375rem",
            //         "py-input-sm": "0.375rem",
            //         "py-input-lg": "0.625rem",
            //         "px-input": "0.75rem",
            //         "px-input-sm": "0.5rem",
            //         "px-input-lg": "0.875rem",
            //         "py-btn": "0.375rem",
            //         "py-btn-sm": "0.375rem",
            //         "py-btn-lg": "0.625rem",
            //         "px-btn": "0.875rem",
            //         "px-btn-sm": "0.75rem",
            //         "px-btn-lg": "1.25rem",
            //         "py-btn-small": "0.25rem",
            //         "py-btn-small-sm": "0.25rem",
            //         "py-btn-small-lg": "0.375rem",
            //         "px-btn-small": "0.625rem",
            //         "px-btn-small-sm": "0.625rem",
            //         "px-btn-small-lg": "0.75rem",
            //         "py-group-tabs": "0.375rem",
            //         "py-group-tabs-sm": "0.375rem",
            //         "py-group-tabs-lg": "0.625rem",
            //         "px-group-tabs": "0.75rem",
            //         "px-group-tabs-sm": "0.5rem",
            //         "px-group-tabs-lg": "0.875rem",
            //         "py-group-blocks": "0.75rem",
            //         "py-group-blocks-sm": "0.625rem",
            //         "py-group-blocks-lg": "0.875rem",
            //         "px-group-blocks": "1rem",
            //         "px-group-blocks-sm": "1rem",
            //         "px-group-blocks-lg": "1rem",
            //         "py-tag": "0px",
            //         "py-tag-sm": "0px",
            //         "py-tag-lg": "0px",
            //         "px-tag": "0.4375rem",
            //         "px-tag-sm": "0.4375rem",
            //         "px-tag-lg": "0.4375rem",
            //         "py-slider-tooltip": "0.125rem",
            //         "py-slider-tooltip-sm": "0.0625rem",
            //         "py-slider-tooltip-lg": "0.1875rem",
            //         "px-slider-tooltip": "0.375rem",
            //         "px-slider-tooltip-sm": "0.3125rem",
            //         "px-slider-tooltip-lg": "0.5rem",
            //         "space-addon": "0px",
            //         "space-addon-sm": "0px",
            //         "space-addon-lg": "0px",
            //         "space-checkbox": "0.375rem",
            //         "space-checkbox-sm": "0.375rem",
            //         "space-checkbox-lg": "0.375rem",
            //         "space-tags": "0.1875rem",
            //         "space-tags-sm": "0.1875rem",
            //         "space-tags-lg": "0.1875rem",
            //         "floating-top": "0rem",
            //         "floating-top-sm": "0rem",
            //         "floating-top-lg": "0.6875rem",
            //         "bg-input": "#ffffff",
            //         "bg-input-hover": "#ffffff",
            //         "bg-input-focus": "#ffffff",
            //         "bg-input-danger": "#ffffff",
            //         "bg-input-success": "#ffffff",
            //         "bg-disabled": "var(--vf-gray-200)",
            //         "bg-selected": "#1118270d",
            //         "bg-passive": "var(--vf-gray-300)",
            //         "bg-icon": "var(--vf-gray-500)",
            //         "bg-danger": "var(--vf-danger-lighter)",
            //         "bg-success": "var(--vf-success-lighter)",
            //         "bg-tag": "var(--vf-primary)",
            //         "bg-slider-handle": "var(--vf-primary)",
            //         "bg-toggle-handle": "#ffffff",
            //         "bg-date-head": "var(--vf-gray-100)",
            //         "bg-addon": "#ffffff00",
            //         "bg-btn": "var(--vf-primary)",
            //         "bg-btn-danger": "var(--vf-danger)",
            //         "bg-btn-secondary": "var(--vf-gray-200)",
            //         "color-input": "var(--vf-gray-800)",
            //         "color-input-hover": "var(--vf-gray-800)",
            //         "color-input-focus": "var(--vf-gray-800)",
            //         "color-input-danger": "var(--vf-gray-800)",
            //         "color-input-success": "var(--vf-gray-800)",
            //         "color-disabled": "var(--vf-gray-400)",
            //         "color-placeholder": "var(--vf-gray-300)",
            //         "color-passive": "var(--vf-gray-700)",
            //         "color-muted": "var(--vf-gray-500)",
            //         "color-floating": "var(--vf-gray-500)",
            //         "color-floating-focus": "var(--vf-gray-500)",
            //         "color-floating-success": "var(--vf-gray-500)",
            //         "color-floating-danger": "var(--vf-gray-500)",
            //         "color-danger": "var(--vf-danger)",
            //         "color-success": "var(--vf-success)",
            //         "color-tag": "var(--vf-color-on-primary)",
            //         "color-addon": "var(--vf-gray-800)",
            //         "color-date-head": "var(--vf-gray-700)",
            //         "color-btn": "var(--vf-color-on-primary)",
            //         "color-btn-danger": "#ffffff",
            //         "color-btn-secondary": "var(--vf-gray-700)",
            //         "border-color-input": "var(--vf-gray-300)",
            //         "border-color-input-hover": "var(--vf-gray-300)",
            //         "border-color-input-focus": "var(--vf-primary)",
            //         "border-color-input-danger": "var(--vf-gray-300)",
            //         "border-color-input-success": "var(--vf-gray-300)",
            //         "border-color-checked": "var(--vf-primary)",
            //         "border-color-passive": "var(--vf-gray-300)",
            //         "border-color-slider-tooltip": "var(--vf-primary)",
            //         "border-color-tag": "var(--vf-primary)",
            //         "border-color-btn": "var(--vf-primary)",
            //         "border-color-btn-danger": "var(--vf-danger)",
            //         "border-color-btn-secondary": "var(--vf-gray-200)",
            //         "border-width-input-t": "1px",
            //         "border-width-input-r": "1px",
            //         "border-width-input-b": "1px",
            //         "border-width-input-l": "1px",
            //         "border-width-radio-t": "1px",
            //         "border-width-radio-r": "1px",
            //         "border-width-radio-b": "1px",
            //         "border-width-radio-l": "1px",
            //         "border-width-checkbox-t": "1px",
            //         "border-width-checkbox-r": "1px",
            //         "border-width-checkbox-b": "1px",
            //         "border-width-checkbox-l": "1px",
            //         "border-width-dropdown": "1px",
            //         "border-width-btn": "1px",
            //         "border-width-toggle": "0.125rem",
            //         "border-width-tag": "1px",
            //         "shadow-input": "0px 0px 0px 0px rgba(0,0,0,0)",
            //         "shadow-input-hover": "0px 0px 0px 0px rgba(0,0,0,0)",
            //         "shadow-input-focus": "0px 0px 0px 0px rgba(0,0,0,0)",
            //         "shadow-handles": "0px 0px 0px 0px rgba(0,0,0,0)",
            //         "shadow-handles-hover": "0px 0px 0px 0px rgba(0,0,0,0)",
            //         "shadow-handles-focus": "0px 0px 0px 0px rgba(0,0,0,0)",
            //         "shadow-btn": "0px 0px 0px 0px rgba(0,0,0,0)",
            //         "shadow-dropdown": "0px 0px 0px 0px rgba(0,0,0,0)",
            //         "radius-input": "0.25rem",
            //         "radius-input-sm": "0.25rem",
            //         "radius-input-lg": "0.25rem",
            //         "radius-btn": "0.25rem",
            //         "radius-btn-sm": "0.25rem",
            //         "radius-btn-lg": "0.25rem",
            //         "radius-small": "0.25rem",
            //         "radius-small-sm": "0.25rem",
            //         "radius-small-lg": "0.25rem",
            //         "radius-large": "0.25rem",
            //         "radius-large-sm": "0.25rem",
            //         "radius-large-lg": "0.25rem",
            //         "radius-tag": "0.25rem",
            //         "radius-tag-sm": "0.25rem",
            //         "radius-tag-lg": "0.25rem",
            //         "radius-checkbox": "0.25rem",
            //         "radius-checkbox-sm": "0.25rem",
            //         "radius-checkbox-lg": "0.25rem",
            //         "radius-slider": "0.25rem",
            //         "radius-slider-sm": "0.25rem",
            //         "radius-slider-lg": "0.25rem",
            //         "radius-image": "0.25rem",
            //         "radius-image-sm": "0.25rem",
            //         "radius-image-lg": "0.25rem",
            //         "radius-gallery": "0.25rem",
            //         "radius-gallery-sm": "0.25rem",
            //         "radius-gallery-lg": "0.25rem",
            //         "checkbox-size": "1rem",
            //         "checkbox-size-sm": "0.875rem",
            //         "checkbox-size-lg": "1rem",
            //         "gallery-size": "6rem",
            //         "gallery-size-sm": "5rem",
            //         "gallery-size-lg": "7rem",
            //         "toggle-width": "3rem",
            //         "toggle-width-sm": "2.75rem",
            //         "toggle-width-lg": "3rem",
            //         "toggle-height": "1.25rem",
            //         "toggle-height-sm": "1rem",
            //         "toggle-height-lg": "1.25rem",
            //         "slider-height": "0.375rem",
            //         "slider-height-sm": "0.3125rem",
            //         "slider-height-lg": "0.5rem",
            //         "slider-height-vertical": "20rem",
            //         "slider-height-vertical-sm": "20rem",
            //         "slider-height-vertical-lg": "20rem",
            //         "slider-handle-size": "1rem",
            //         "slider-handle-size-sm": "0.875rem",
            //         "slider-handle-size-lg": "1.25rem",
            //         "slider-tooltip-distance": "0.5rem",
            //         "slider-tooltip-distance-sm": "0.375rem",
            //         "slider-tooltip-distance-lg": "0.5rem",
            //         "slider-tooltip-arrow-size": "0.3125rem",
            //         "slider-tooltip-arrow-size-sm": "0.3125rem",
            //         "slider-tooltip-arrow-size-lg": "0.3125rem"
            //     },
            //     "form": {
            //         "nesting": true,
            //         "steps": {
            //             "page0": {
            //                 "label": "Step 1",
            //                 "elements": [
            //                     "container",
            //                     "textarea"
            //                 ],
            //                 "builder": {
            //                     "type": "steps"
            //                 }
            //             },
            //             "page1": {
            //                 "label": "Date",
            //                 "elements": [
            //                     "datetime",
            //                     "date",
            //                     "time",
            //                     "dates",
            //                     "dateRange"
            //                 ],
            //                 "builder": {
            //                     "type": "steps"
            //                 }
            //             }
            //         }
            //     },
            //     "export": {
            //         "output": "schema",
            //         "api": "options",
            //         "theme": "none"
            //     },
            //     "builder": {
            //         "name": "MyForm",
            //         "width": "432px"
            //     }
            // },
            form_history: null

        }
    },
    mounted() {
        console.log(this.myForm)
        
        window.scrollTo({ top: 0, behavior: 'smooth' });
        
        if (this.myForm != null) {
            
            this.$refs.builder$.load(this.myForm, [this.myForm])
        }
    },
    methods: {
        handleSave(builderObject, history) {
            // builderObject - the object that should be saved to db (and loaded)
            // history - the array of previous builderObjects
            console.log(JSON.parse(JSON.stringify(builderObject)))
            console.log(JSON.parse(JSON.stringify(history)))
            this.myForm = builderObject
            this.form_history = history
        },
    }

}
</script>
