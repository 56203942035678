<template>
    <div class="h-full px-4">
        <BreadcrumbComponent class="px-3 md:px-4 xl:px-5 py-1" :items="breadcrumb_items" />

        <div class="w-full mt-4 px-1">

            <div class="card block h-100 w-full mx-auto  ">

                <VueFlow ref="vueflowref" v-model="elements" class="basicflow h-full mt-3"
                    :default-edge-options="{ type: 'smoothstep' }" :default-viewport="{ zoom: 1 }" :min-zoom="0.2"
                    :max-zoom="4" :zoom-on-scroll="false" :nodes-draggable="true" :elements-selectable="true"
                    multi-selection-key-code="shift" @nodes-selection-change="onNodeSelectionChange"
                    :apply-default="false" @node-click="clickNode" @pane-click="clickPane">
                    <!-- <Background pattern-color="#aaa" gap="8" /> -->

                    <MiniMap />

                    <Controls />
                </VueFlow>

            </div>

        </div>
        <div class="w-10/12 mx-auto mt-8 mb-4 text-center">
            <p class="text-base font-semibold text-grey-protostars-900 dark:text-white" v-if="selected_node">You selected this section: <span
                    class="font-semibold text-primary dark:text-primary-400">{{ selected_node.label }}</span></p>
        </div>
        <!-- {{ selected_node }} -->
        <div class="w-10/12 mx-auto mt-8 mb-4 h-20">
            <div class="w-full flex justify-center">

                <button class="btn btn-protostars btn-primary text-white px-4 py-2 rounded-lg "
                    :disabled="selected_node == null" @click="clickPublish">Publish</button>

            </div>
        </div>
    </div>
</template>

<script>
import { VueFlow, useVueFlow } from '@vue-flow/core'
// import { Background } from '@vue-flow/background'
import { Controls } from '@vue-flow/controls'
import { MiniMap } from '@vue-flow/minimap'
import { ref } from 'vue'
import { MarkerType, Position } from '@vue-flow/core'

const { setNodesSelection } = useVueFlow();

import axios from 'axios'

const { onConnect, addEdges } = useVueFlow()

// const elements = ref(initialElements)

onConnect((params) => addEdges(params))

export default {
    name: "QuestionnaireVueFlow",
    components: { VueFlow, Controls, MiniMap },
    data() {
        let temp = this;
        return {
            breadcrumb_items: [
                {
                    icon: 'fa-solid fa-house',
                    // text:'',
                    to: '#'
                },
                {
                    to: '/questionnaires/list',
                    text: 'Questionnaires'
                },
                {
                    text: 'Create Questionnaire'
                }
            ],

            elements: [
                {
                    id: '1',
                    type: 'input',
                    label: 'Situational Analysis',
                    selected: false,
                    position: { x: 10, y: 50 },
                    sourcePosition: Position.Right,
                    display: 'all'
                },
                {
                    id: '2',
                    label: 'Audit Project Setup',
                    position: { x: 250, y: 50 },
                    selected: false,
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    display: 'all'
                },
                {
                    id: '3',
                    label: 'Physical Security',
                    position: { x: 500, y: 50 },
                    selected: false,
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    display: 'all'
                },
                {
                    id: '4',
                    label: 'People Security',
                    selected: false,
                    position: { x: 750, y: 50 },
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                },
                {
                    id: '5',
                    label: 'Data Security',
                    selected: false,
                    position: { x: 750, y: 200 },
                    sourcePosition: Position.Left,
                    targetPosition: Position.Right,
                },
                {
                    id: '6',
                    label: 'Infrastructure Security',
                    selected: false,
                    position: { x: 500, y: 200 },
                    sourcePosition: Position.Left,
                    targetPosition: Position.Right,
                },
                {
                    id: '7',
                    type: 'output',
                    label: 'Crisis Management',
                    position: { x: 250, y: 200 },
                    sourcePosition: Position.Left,
                    targetPosition: Position.Right,
                    selected: false,
                    display: 'mobile'
                },
                // { id: '3', label: 'Node 3', position: { x: 250, y: 100 }, sourcePosition: Position.Right, targetPosition: Position.Left },
                // { id: '4', label: 'Node 4', position: { x: 500, y: 150 }, sourcePosition: Position.Right, targetPosition: Position.Left },
                // {
                //   id: '5',
                //   type: 'output',
                //   label: 'Node 5',
                //   position: { x: 750, y: 50 },
                //   sourcePosition: Position.Right,
                //   targetPosition: Position.Left,
                // },
                { id: 'e1-2', source: '1', target: '2', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e2-3', source: '2', target: '3', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e3-4', source: '3', target: '4', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e4-5', source: '4', target: '5', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e5-6', source: '5', target: '6', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e6-7', source: '6', target: '7', markerEnd: MarkerType.Arrow, display: 'all' },
                // {
                //   id: 'e4-5',
                //   source: '4',
                //   target: '5',
                //   style: { stroke: 'orange' },
                //   labelBgStyle: { fill: 'orange' },
                // },
                // { id: 'e3-4', source: '3', target: '4' },
            ],
            selected_items: [],
            selected_node: null,

            form_id: null

        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        this.form_id = this.$route.params.id
    },
    beforeUnmount() {
    },
    updated() {
    },
    watch: {
    },
    methods: {
        setNodesSelection,
        clickPublish() {
            // this.$router.push('/success_publish')

            let requestData = {
                "id": this.form_id,
                "section": this.selected_node.label,
                client: 'PROTOSTARS'
            }


            axios
                .post("forms/update", requestData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: 'PROTOSTARS',
                        Authorization:
                            "Bearer " +
                            JSON.parse(window.localStorage.getItem("user")).access_token,
                    }
                })
                .then((response) => {
                    console.log(response);
                    console.log(response.data);

                    console.log("success")
                    this.$swal({
                        // title: "Title",
                        text: response.data.message,
                        icon: "success",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-grey-protostars",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                        this.$router.push("/questionnaires/" + response.data.form.form_id + "/success_publish")

                    });


                })
                .catch((error) => {
                    console.log(error.response.data.error);
                    console.log(error.request);
                    console.log(error.message);
                    // this.loginErrorMessage = error.response.data.error;
                    // this.showLoginErrorMessage = true;

                    this.$swal({
                        // title: "Title",
                        text: error.response.data.error,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-grey-protostars",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });

        },
        onNodeSelectionChange(selectedNodes) {
            console.log(selectedNodes)
        },
        test() {
            console.log("select node")
            console.log(this.$refs.vueflowref)
            console.log("get selcted nodes")
            console.log(JSON.parse(JSON.stringify(this.$refs.vueflowref)))
            console.log(JSON.parse(JSON.stringify(this.$refs.vueflowref)).getSelectedElements)

            // console.log(this.$refs.vueflowref.getSelectedElements)
            // console.log(this.elements)
        },
        clickNode(temp) {
            console.log("clicked node")
            console.log(temp)
            // this.elements[2].selected = true
            for (let i = 0; i < this.elements.length; i++) {
                this.elements[i].selected = false
            }
            temp.node.selected = true
            console.log(temp)

            console.log(this.$refs.vueflowref)

            this.selected_items = JSON.parse(JSON.stringify(this.$refs.vueflowref)).getSelectedElements
            this.selected_node = temp.node
            // this.filteredElements
            // setNodesSelection([3])
            // addSelectedNodes
        },
        clickPane(temp) {
            console.log("Click pane")
            console.log(temp)
        }
    }
}
</script>


<style scoped>
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.27.1/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.27.1/dist/theme-default.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/controls@latest/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/minimap@latest/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/node-resizer@latest/dist/style.css';



.vue-flow__minimap {
    transform: scale(75%);
    transform-origin: bottom right;
}

input:checked+label {
    border: 2px solid var(--grey_primary);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>