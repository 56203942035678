<template>
    <div class="px-4">
        <BreadcrumbComponent :items="breadcrumb_items" />

        <div class="mt-1 px-3 md:px-4 xl:px-5 flex-1 flex flex-col">
            <FormBuilderPreviewComponent :base_url="'/questionnaires'" class="flex-1"/>
        </div>
    </div>
</template>

<script>
import FormBuilderPreviewComponent from '@/components/form_builder/FormBuilderPreviewComponent.vue'
import axios from 'axios';
import moment from 'moment'

export default {
    name: "QuestionnairePreviewForm",
    components: {
        FormBuilderPreviewComponent
    },
    data() {
        let temp = this;
        return {
            breadcrumb_items: [
                {
                    icon: 'fa-solid fa-house',
                    // text:'',
                    to: '/dashboard'
                },
                {
                    to: '/questionnaires',
                    text: 'Questionnaires'
                },
                {
                    to: '/questionnaires/create_questionnaire',
                    text: 'Create Questionnaire'
                },
                {
                    text: 'Preview'
                }
            ],

        }
    },
    mounted() {
        // setTimeout(() => {
        //     initFlowbite();
        // }, 1500);

    },
    beforeUnmount() {
        // this.stopRecording(); // Cleanup resources before component unmounts
    },
    updated() {
    },
    watch: {
    },
    methods: {
    }
}
</script>
