<template>
    <div class="h-full">

        <div class="form-builder-container  ">
            <VueformBuilder @save="handleSave" @clear-toaster="handleClear" @preview="clickPreviewF" 
            @handleChangeView="handleChangeView2" :key="true" ref="builder$"
            @change-to-preview="onMovePage" @toast="onMoveToPage" />
            <div>
                <div class="row ">
                    <div class="w-full mt-3 mb-10">
                        <!-- <router-link :to="base_url + '/preview_form'"
                            class="btn-protostars btn-primary md:float-right submit">Preview </router-link> -->

                        <button class="btn-protostars btn-primary md:float-right submit" type="button"
                                :disabled="myForm && Object.keys(myForm.schema).length === 0"
                                @click="clickPreview">
                            Preview</button>    
                    </div>
                </div>
                <!-- <div class="text-black dark:text-white"> {{ myForm }}</div> -->
            </div>
        </div>

    </div>
</template>
<script>
import { inject } from 'vue';
export default {
    name: "FormBuilderComponent",
    components: {},
    props: ['base_url'],

    data() {
        let temp = this;
        return {
            myForm: null,

            form_history: null,

            isDark: inject('isDark'),


        }
    },
    async mounted() {
        console.log("this.$refs.builder$")
        console.log(this.$refs.builder$)
        console.log(this.$refs.builder$.darkMode)

        window.scrollTo({ top: 0, behavior: 'smooth' });

        if (this.myForm == null) {
            if (localStorage.getItem('vueform-builder')) {
                this.myForm = JSON.parse(localStorage.getItem('vueform-builder'))
                this.$refs.builder$.load(this.myForm, [this.myForm])
            }
            else {
                this.myForm = { "schema": {}, "theme": {}, "form": { "nesting": true, "displayErrors": true, "displayMessages": false, "validateOn": "step|change", "forceLabels": false, "floatPlaceholders": false }, "export": { "output": "inline", "api": "options", "theme": "none" }, "builder": { "name": "MyForm" } };
                this.$refs.builder$.load(this.myForm, [this.myForm])
            }
        }
        else {
            this.$refs.builder$.load(this.myForm, [this.myForm])
        }
    },
    updated() {
        console.log(this.$refs.builder$)
        console.log(this.$refs.builder$.view)
    },

    watch: {
        isDark(newval) {
            console.log("change isdark", newval)
            this.$refs.builder$.darkMode = newval ? 'dark' : 'light'

            // this.$refs.builder$.handleChangeDarkMode('light')
        }
    },
    methods: {
        handleSave(builderObject, history) {
            // builderObject - the object that should be saved to db (and loaded)
            // history - the array of previous builderObjects
            // console.log(JSON.parse(JSON.stringify(builderObject)))
            // console.log(JSON.parse(JSON.stringify(history)))
            this.myForm = builderObject
            this.form_history = history
        },
        handleClear() {
            console.log("clear form")
        },
        clickPreview(){
            this.$router.push(this.base_url + '/preview_form')
        },

        clickPreviewF(){
            console.log("click preview")
        },
        handleChangeView(e){
            console.log("click preview handleChangeView")
            console.log(e)
        },
        onMovePage(e,t,a){
            console.log("in move page")
            console.log(e)
            console.log(t)
            console.log(a)
        },
        onMoveToPage(e,t){
            console.log("in move to page")
            console.log(e)
            console.log(t)
        },
    }

}
</script>
