<template>
    <CardPartial class="card-form-container-protostars mb-2 "
        :card_class="' mx-auto xl:w-6/12 lg:w-8/12 md:w-11/12 w-[98%] p-4 mt-8'" >
        <CardHeader :header_class="'w-full mx-auto !pb-0'">
            <h2 class="card-title">Change Password</h2>
        </CardHeader>
        <CardBody>
            <Vueform add-class="vueFormContainer"  :endpoint="false" :float-placeholders="false" v-model="formData"
                validate-on="change|step" class="w-full mx-auto" ref="changePasswordRef"
                :remove-class="{ div: ['grid'] }">
                <!-- <StaticElement name="welcomeH3" tag="h3" :content="'Change password'"
                                    :attrs="{ class: ['page-title', 'mb-2'] }" /> -->

                <TextElement name="old_password" :input-type="show_password ? 'text' : 'password'" label="Old Password"
                    placeholder="Enter your password" :field-name="'Old password'" :rules='["required"]' class="mb-3">
                    <template #addon-after>
                        <button type="button" @click="show_password = !show_password" class="addon-button">
                            <font-awesome-icon icon="fa-solid fa-eye" v-if="!show_password" />
                            <font-awesome-icon icon="fa-solid fa-eye-slash" v-else />
                        </button>
                    </template>
                </TextElement>
                <TextElement name="password" :input-type="show_new_password ? 'text' : 'password'" label="New Password"
                    placeholder="Enter new password" :field-name="'New password'" class="mb-3" :rules='["required", "min:8", "at_least_uppercase", "at_least_lowercase",
                        "at_least_number", "at_least_special"]'>
                    <template #addon-after>
                        <button type="button" @click="show_new_password = !show_new_password" class="addon-button">
                            <font-awesome-icon icon="fa-solid fa-eye" v-if="!show_new_password" />
                            <font-awesome-icon icon="fa-solid fa-eye-slash" v-else />
                        </button>
                    </template>
                </TextElement>
                <TextElement name="password_confirmation" :input-type="show_confirm_new_password ? 'text' : 'password'"
                    label="Re-enter New Password" placeholder="Reenter your password" :field-name="'Confirm password'"
                    :rules='["required", "same:password"]' class="mb-3">
                    <template #addon-after>
                        <button type="button" @click="show_confirm_new_password = !show_confirm_new_password"
                            class="addon-button">
                            <font-awesome-icon icon="fa-solid fa-eye" v-if="!show_confirm_new_password" />
                            <font-awesome-icon icon="fa-solid fa-eye-slash" v-else />
                        </button>
                    </template>
                </TextElement>

                <HiddenElement name="client" :default="client_name" />

                <ButtonElement button-label="Confirm" :submits="true"
                    :button-class="['btn-protostars', 'btn-primary', 'mx-auto','mt-2', 'mb-4',]" full :loading="is_loading"
                    @click="clickConfirm" />

            </Vueform>
        </CardBody>
    </CardPartial>
</template>



<script>
import { inject } from 'vue';
import AuthService from '@/services/AuthService';

export default {
    name: "ChangePasswordCard",
    props: {
        client_name: {
            type: String,
        },
    },
    data() {
        let temp = this;
        return {

            formData: null,

            is_loading: false,

            isDark: inject('isDark'),
            show_password: false,
            show_new_password: false,
            show_confirm_new_password: false,

        }
    },
    mounted() {
    },
    updated() {
    },
    watch: {
    },
    methods: {
        clickConfirm() {
            let code_form = this.$refs.changePasswordRef
            code_form.validate()
            let form_errors = code_form.formErrors
            if (form_errors.length > 0) {
                console.log("not valid")

            } else {
                console.log('validated')
                this.is_loading = true
                this.sendChangePassword()
            }
        },

        async sendChangePassword() {
            let response = await AuthService.sendChangePasswordRequest(this.formData, this.client_name, "Bearer " +
                JSON.parse(window.localStorage.getItem("user")).access_token)
            console.log("reset password code card")
            console.log(response)

            if (response.code == 200) {
                console.log(response)
                this.$swal({
                    theme: "",
                    // title: "Title",
                    text: response.response.success,
                    icon: "success",
                    // timer: 3000,

                }).then((r) => {
                    // console.log(r.value);
                    this.$refs.changePasswordRef.reset()
                    this.is_loading = false;
                });

            } else {
                this.$swal({
                    theme: "",
                    // title: "Title",
                    text: response.response.error_message,
                    icon: "error",
                    // timer: 3000,

                }).then((r) => {
                    this.is_loading = false;
                });
            }
            this.is_loading = false
        }

    }
}
</script>

<style></style>