<template>
    <div class="h-full px-4">

        <BreadcrumbComponent class="px-3 md:px-4 xl:px-5 py-1" :items="breadcrumb_items" />

        <div class="row ">
            <div class="lg:w-1/2 md:w-6/12">
                <div class="p-3 md:p-4 xl:p-5">
                    <CardPartial class="mb-2 ">
                        <CardHeader
                            :header_class="'border-b-2 border-b-z border-b-grey-protostars-50  dark:border-b-grey-protostars-100'">
                            <div class="row">
                                <div class="lg:w-7/12 flex align-middle items-center">
                                    <!-- <div class="flex justify-between"> -->
                                    <div class="flex align-middle items-center">
                                        <img class="hidden dark:inline w-16"
                                            src="@/assets/images/protostars/ce-logo-light.png" />
                                        <img class="inline dark:hidden w-16"
                                            src="@/assets/images/protostars/ce-logo-dark.png" />
                                        <h2 class="inline-block">Conformity <span> Readiness</span></h2>
                                    </div>

                                </div>
                                <div class="lg:w-5/12 ">
                                    <ProgressBar :value="10" :value_text="'6/83'" />
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody :body_class="'!p-1'">
                            <PieChart :title="''" :subtitle="''" :series_name="''" :series_data="pie_chart_data_series"
                                :colors="colors_pie_chart" :caption="'Board Report <br/> 23 April 2024'" />
                        </CardBody>
                    </CardPartial>

                </div>
                <div class="grid gap-5 md:grid-cols-2 lg:grid-cols-2 p-3 md:p-4 xl:p-5">
                    <CardComponent :title="'No. of Participants'" :value="num_participants"
                        :img_src="isDark ? require('@/assets/images/protostars/dashboard/dashboard-people-dark-icon.png') : require('@/assets/images/protostars/dashboard/dashboard-people-icon.png')" />
                    <router-link to="/audits/list">
                        <CardComponent :title="'No. of Audits Projects'" :value="num_audits"
                            :img_src="isDark ? require('@/assets/images/protostars/dashboard/dashboard-audits-dark-icon.png') : require('@/assets/images/protostars/dashboard/dashboard-audits-icon.png')" />
                    </router-link>
                    <CardComponent :title="'Open Issues'" :value="num_tests"
                        :img_src="isDark ? require('@/assets/images/protostars/dashboard/dashboard-issues-dark-icon.png') : require('@/assets/images/protostars/dashboard/dashboard-issues-icon.png')" />
                    <CardComponent :title="'No. of Assets'" :value="num_saas_products"
                        :img_src="isDark ? require('@/assets/images/protostars/dashboard/dashboard-assets-dark-icon.png') : require('@/assets/images/protostars/dashboard/dashboard-assets-icon.png')" />
                </div>
            </div>
            <div class="lg:w-1/2 md:w-6/12">
                <div class="p-3 md:p-4 xl:p-5">

                    <CardPartial class="mb-8 " :card_class="'dark:border-2 dark:border-grey-protostars-100'">
                        <CardHeader :header_class="'card-header-bg'">
                            <div class="flex items-center justify-between ">
                                <h5
                                    class="text-sm capitalize font-semibold leading-none text-grey-protostars-900 dark:text-white">
                                    Live Audit Checklist
                                </h5>
                                <p
                                    class="text-xs font-semibold text-grey-protostars uppercase dark:text-grey-protostars-100">
                                    (2/5 steps done)
                                </p>
                            </div>
                        </CardHeader>
                        <CardBody :body_class="'!py-2'">
                            <div class="flow-root">
                                <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
                                    <li class="py-3 sm:py-4" v-for="(item, index) in live_audit_checklist" :key="index">
                                        <router-link :to="item.path" :disabled="item.done_on == ''">
                                            <div class="flex items-center">

                                                <div class="flex-1 min-w-0 ">
                                                    <p
                                                        class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                        {{ item.name }}
                                                    </p>
                                                    <p v-if="item.done_on"
                                                        class="text-xs text-gray-500 truncate dark:text-stone-300">
                                                        Done on {{ item.done_on }}
                                                    </p>
                                                    <p v-else
                                                        class="text-xs text-gray-500 truncate dark:text-stone-300">
                                                        Fill it now
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center ">
                                                    <img v-if="item.done_on"
                                                        src="@/assets/images/protostars/dashboard/dashboard-check-icon.png"
                                                        class="block dark:hidden w-8 h-6" />
                                                    <img v-else
                                                        src="@/assets/images/protostars/dashboard/dashboard-arrow-right-icon.png"
                                                        class="block dark:hidden w-8 h-6" />
                                                    <img v-if="item.done_on"
                                                        src="@/assets/images/protostars/dashboard/dashboard-check-dark-icon.png"
                                                        class="hidden dark:block w-8 h-6" />
                                                    <img v-else
                                                        src="@/assets/images/protostars/dashboard/dashboard-arrow-right-dark-icon.png"
                                                        class="hidden dark:block w-8 h-6" />
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </CardBody>
                    </CardPartial>

                    <!--reports list-->
                    <CardPartial class="mb-1 " card_class="dark:border-2 dark:border-grey-protostars-100">
                        <CardHeader :header_class="'card-header-bg'">
                            <div class="flex items-center justify-between ">
                                <h5
                                    class="text-sm capitalize font-semibold leading-none text-grey-protostars-900 dark:text-white">
                                    Lastest Board Reports
                                </h5>
                            </div>
                        </CardHeader>
                        <CardBody :body_class="'!py-2'">
                            <div class="flow-root">
                                <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
                                    <li class="py-3 sm:py-4" v-for="(item, index) in reports_list" :key="index">
                                        <div class="flex items-center">
                                            <div class="flex-shrink-0">

                                                <div
                                                    class="relative w-8 h-8 px-2 py-1 flex align-middle overflow-hidden bg-gray-100 rounded-full ">
                                                    <img class="w-3  m-auto"
                                                        src="@/assets/images/protostars/dashboard/dashboard-file-icon.png"
                                                        alt="report file">
                                                </div>
                                            </div>
                                            <div class="flex-1 min-w-0 ms-4">
                                                <p
                                                    class="text-sm font-medium font-Poppins text-sky-950  dark:text-grey-protostars-100 truncate">
                                                    {{ item.name }}
                                                </p>
                                            </div>
                                            <span
                                                class="w-32 text-center bg-sky-50 text-grey-protostars text-xs font-normal px-2.5 py-0.5 rounded-full dark:bg-sky-50 dark:text-grey-protostars">{{
                                                    item.date }}</span>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </CardBody>
                    </CardPartial>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
import axios from 'axios'
import CardComponent from '@/components/dashboard/CardComponent.vue';
import PieChart from '@/components/dashboard/charts/PieChart.vue';

export default {
    name: "DashbaordCustomer",
    components: { CardComponent, PieChart },

    data() {
        return {
            breadcrumb_items: [
                {
                    icon: 'fa-solid fa-house',
                    // text:'',
                    to: '#'
                },
                {
                    text: 'Dashboard',
                },
            ],


            num_participants: 777,
            num_audits: 0,
            num_tests: 685,
            num_saas_products: 9,

            colors_pie_chart:
                ["#69A0FF", "#69CFFF", "#69FFCC", "#FFE57F", "#FF8C82"],

            pie_chart_data_series: [

                {
                    name: 'Policy & Proceedures',
                    y: 5
                }, {
                    name: 'ISO Standard Issues',
                    y: 5
                },
                {
                    name: 'Satisfactory',
                    y: 10
                },
                {
                    name: 'Warnings',
                    y: 10
                },
                {
                    name: 'Critical Issues',
                    y: 10
                },

            ],

            live_audit_checklist: [
                { name: "Situational Analysis", done_on: "12 Jan 2024", path: "/situation_analysis" },
                { name: "Audit Project Setup", done_on: "", path: "/audits/create_audit" },
                { name: "Physical Security", done_on: "", path: "" },
                { name: "People Security ", done_on: "", path: "/user_management" },
                { name: "Data Security", done_on: "", path: "" },
                { name: "Infrastructure Security", done_on: "", path: "" },
                { name: "Crisis Management", done_on: "", path: "" },

            ],

            reports_list: [
                { name: "Security Audit Report", path: "", date: "20 Mar 2024" },
                { name: "Situation Analysis Report", path: "", date: "20 Feb 2024" },
                { name: "Value Proposition Report", path: "", date: "1 Jan 2024" },
            ],

            isDark: inject('isDark'),



        }
    },
    mounted() {
        axios
            .get("audits/list", {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    client: 'PROTOSTARS',
                    Authorization:
                        "Bearer " +
                        JSON.parse(window.localStorage.getItem("user")).access_token,
                },
            })
            .then(async (response) => {
                console.log(response);

                let audits_list = response.data

                this.num_audits = audits_list ? audits_list.length : 0
                if (audits_list.length > 0) {
                    this.live_audit_checklist[1] = { name: "Audit Project Setup", done_on: "23 Apr 2024", path: "/audits/list" }
                }
                this.is_loading = false
            })
            .catch((error) => {
                console.log(error)
                this.is_loading = false;
                // let error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                // if (error_message == null || error_message == '') {
                //     error_message = 'Sorry, '
                // }
                let error_message = ''
                if (error.response) {
                    console.error("error.response.data", error.response.data);
                    console.error("error.response.status", error.response.status);
                    console.error("error.response.headers", error.response.headers);
                    error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                } else if (error.request) {
                    console.error("error-request", error.request);
                    console.error("Error-message", error.message);
                    error_message = error.message
                } else {
                    console.error("Error-message", error.message);
                    error_message = error.message
                }
                this.$swal({
                    // title: "Title",
                    text: error_message,
                    icon: "error",
                    // timer: 3000,
                    customClass: {
                        htmlContainer: "!text-2xl",
                        confirmButton: "!bg-grey-protostars",
                    },
                }).then((r) => {
                    // console.log(r.value);
                    this.is_loading = false;
                });
            });

    }

}
</script>

<style></style>