<template>
    <div class="card card-auth">
        <img :src="logo_src" class="logo-img" />
        <Vueform add-class="vueFormContainer" :endpoint="false" :float-placeholders="false" v-model="formData" validate-on="change|step"
            class=""  :remove-class="{ div: ['grid'] }">
            <StaticElement name="welcomeH3" tag="h3" :content="'Choose Verification Method'"
                :attrs="{ class: ['title-h3', 'mb-6'] }" />

            <RadiogroupElement name="verify_method" class="radiogroup-container"
                :items="[{ 'label': 'Authenticator App', 'value': 'app' }, { 'label': 'Email', 'value': 'email' }]"
                view="blocks" default="app" />

            <ButtonElement button-label="Confirm" :submits="false" 
                :button-class="['btn-protostars', 'btn-auth-submit']"
                full :loading="is_loading" @click="clickConfirm" />

        </Vueform>



    </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import { inject } from 'vue';

export default {
    name: "ChooseVerificationMethodCard",
    props: {
        client_name: {
            type: String,
        },
        logo_src: {
            type: String
        },
        base_url: {
            type: String
        }
    },
    data() {
        let temp = this;
        return {

            formData: null,
            email: null,
            is_loading: false,

            isDark: inject('isDark'),
        }
    },
    mounted() {
        this.email = window.sessionStorage.getItem("email");
    },
    updated() {
    },
    watch: {
    },
    methods: {
        async clickConfirm() {
            if (this.formData.verify_method == 'app') {
                this.$router.push(this.base_url + '/verify_login/' + this.formData.verify_method);
            } else if (this.formData.verify_method == 'email') {
                console.log("Send email", this.email)
                this.is_loading = true
                let formDataRequest = {
                    user_email: this.email,
                    user_type: "u",
                    client: this.client_name,
                }
                console.log(formDataRequest)

                let response = await AuthService.sendVerifyCodeEmailRequest(formDataRequest, this.client_name)
                console.log("choose verify method card")
                console.log(response)

                if (response.code == 200) {
                        this.$swal({
                            // title: "Title",
                            text: response.response.success,
                            type: "success",
                            // timer: 3000,
                            // confirmButtonText: this.$t('ok_btn')
                        }).then((r) => {
                            this.$router.push(this.base_url + '/verify_login/' + this.formData.verify_method);
                        });
                } else {
                    this.$swal({
                        theme: "",
                        // title: "Title",
                        text: response.response.error_message,
                        icon: "error",
                        // timer: 3000,

                    }).then((r) => {
                        this.is_loading = false;
                    });

                }
                this.is_loading = false


            }
        },

    }
}
</script>
