<template>
    <div :id="id"
        class="z-20 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-2xl dark:bg-grey-protostars-800 dark:shadow-2xl shadow-slate-100">
        <ul class="py-2 text-sm/normal text-stone-800 dark:text-white" :aria-labelledby="button_id">
            <slot></slot>
        </ul>
    </div>
</template>

<script>
export default {
    name: "DropdownItemsUl",
    props: {
        id:{
            type: String,
            required: true
        },
        button_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>