<template>
    <CardPartial class=" col-span-12 p-6 ">
        <template v-for="(item, key) in schema_obj" :key="key">
            <template v-if="item.type == 'static'">
                <!-- <span class="col-span-12"> {{ item }} </span> -->
                <StaticElement v-if="!item.label && item.tag!='hr'" class="static-element mb-2" :name="item.name" :tag="item.tag"
                    :allow-html="true" :content="item.content" :label="item.label" :info="item.info"
                    :info-position="item.align == 'right' ? 'bottom' : 'right'" :align="item.align" :top="item.top"
                    :bottom="item.bottom" :attrs="item.attrs" :conditions="item.conditions" :alt="item.alt"
                    :title="item.title" :src="item.src" :width="item.width" :height="item.height" :href="item.href"
                    :target="item.target" :description="item.description" :override-classes="{
                        ElementLayout: {
                            outerWrapper: ['flex', 'flex-row-reverse', 'justify-' + static_align(item.align)],
                        }
                    }"
                    :remove-classes="{
                        ElementLayout: {
                            innerContainer: ['flex-1'],
                        }
                    }" :add-classes="{
                                ElementInfo: {
                                    container: ['-mt-3'],
                                },
                                ElementLabel: {
                                    container: ['flex items-center']
                                }
                            }">
                    <template #label>
                        <template>{{ item.label }} </template>
                    </template>
                    <!-- <template #default>
                        <template>{{ item.info }}</template>
                    </template> -->

                </StaticElement>
                <StaticElement v-else class="static-element mb-2" :name="item.name" :tag="item.tag" :allow-html="true"
                    :content="item.content" :label="item.label" :info="item.info" info-position="right"
                    :align="item.align" :top="item.top" :bottom="item.bottom" :attrs="item.attrs"
                    :conditions="item.conditions" :alt="item.alt" :title="item.title" :src="item.src"
                    :width="item.width" :height="item.height" :href="item.href" :target="item.target"
                    :description="item.description">
                    <template #label>
                        <template>{{ item.label }} </template>
                    </template>

                </StaticElement>

                <!-- <CustomElement class="static-element" :name="item.name + '_2'" :tag="item.tag" :allow-html="true"
                    :content="item.content" :label="item.label" :info="item.info" info-position="right"
                    :align="item.align" :top="item.top" :bottom="item.bottom" :attrs="item.attrs"
                    :conditions="item.conditions" :alt="item.alt" :title="item.title" :src="item.src"
                    :width="item.width" :height="item.height" :href="item.href" :target="item.target" /> -->

                <!-- <h1 v-if="item.tag == 'h1'" v-html="item.content" class="col-span-12 text-4xl"></h1>
                                        <h2 v-if="item.tag == 'h2'" v-html="item.content" class="col-span-12 text-3xl"></h2>
                                        <h3 v-if="item.tag == 'h3'" v-html="item.content" class="col-span-12 text-2xl"></h3>
                                        <h4 v-if="item.tag == 'h4'" v-html="item.content" class="col-span-12 text-xl"></h4>
                                        <p v-if="item.tag == 'p'" v-html="item.content" class="col-span-12 text-base"></p> -->
            </template>
            <template v-if="item.type == 'text'">
                <!-- <TextElement v-if="item.inputType == 'tel'" :name="item.name" :input-type="item.inputType"
                                    :placeholder="item.placeholder" :description="item.description" :addons="item.addons"
                                    :rules="item.rules" :field-name="item.fieldName" :info="item.info">
                        
                                </TextElement> -->

                <!-- <PhoneElement v-if="item.inputType == 'tel'" class="my-2" :name="item.name" :input-type="item.inputType"
                    :label="item.label" :placeholder="item.placeholder" :description="item.description"
                    :addons="item.addons" :rules="item.rules" :field-name="item.fieldName" :info="item.info"
                    :conditions="item.conditions" :disabled="item.disabled" :default="item.default" /> -->

                <TextElement class="my-2" :name="item.name" :input-type="item.inputType" :label="item.label"
                    :placeholder="item.placeholder" :description="item.description" :addons="item.addons"
                    :rules="item.rules" :field-name="item.fieldName" :info="item.info" :info-position="'right'"
                    :conditions="item.conditions" :disabled="item.disabled" :readonly="item.readonly"
                    :default="item.default" :id="item.id" :autocomplete="item.autocomplete" />
            </template>
            <template v-else-if="item.type == 'phone'">

                <!-- {{ item.rules }} , {{ typeof item.rules }}, {{Array.isArray(item.rules)}}                 -->
                <PhoneElement class="my-2" :name="item.name" :input-type="item.inputType" :label="item.label"
                    :placeholder="item.placeholder" :description="item.description" :addons="item.addons"
                    :rules="item.rules && Array.isArray(item.rules) ? item.rules.concat('completed') : ['completed']"
                    :field-name="item.fieldName" :info="item.info" :conditions="item.conditions"
                    :disabled="item.disabled"  :readonly="item.readonly" :default="item.default" :include="item.include" :exclude="item.exclude"
                    :allow-incomplete="true" :debounce="500" :unmask="true" autocomplete="off" />
            </template>
            <template v-else-if="item.type == 'location'">
                <LocationElement class="my-2" :name="item.name" :input-type="item.inputType" :label="item.label"
                    :placeholder="item.placeholder" :description="item.description" :addons="item.addons"
                    :rules="item.rules" :field-name="item.fieldName" :info="item.info" :conditions="item.conditions"
                    :disabled="item.disabled" :default="item.default" />
            </template>
            <template v-else-if="item.type == 'textarea'">
                <TextareaElement class="my-2" :name="item.name" :input-type="item.inputType" :label="item.label"
                    :placeholder="item.placeholder" :description="item.description" :addons="item.addons"
                    :rules="item.rules" :field-name="item.fieldName" :info="item.info" :rows="item.rows"
                    :conditions="item.conditions" :disabled="item.disabled" :default="item.default" />
            </template>
            <template v-else-if="item.type == 'editor'">
                <QuillEditorElement class="my-2" :name="item.name" :label="item.label" :placeholder="item.placeholder"
                    :description="item.description" :addons="item.addons" :rules="item.rules"
                    :field-name="item.fieldName" :info="item.info" :conditions="item.conditions"
                    :disabled="item.disabled" />

                <EditorElement :name="item.name + '_1'" :input-type="item.inputType" :label="item.label"
                    :placeholder="item.placeholder" :description="item.description" :addons="item.addons"
                    :rules="item.rules" :field-name="item.fieldName" :info="item.info" />

                <!-- <quill-editor theme="snow" v-model:content="testeditor"></quill-editor> -->
            </template>
            <template v-else-if="item.type == 'date'">
                <DateElement class="my-2" :name="item.name" :label="item.label" :placeholder="item.placeholder"
                    :description="item.description" :addons="item.addons" :rules="item.rules"
                    :field-name="item.fieldName" :info="item.info" :default="item.default" :date="item.date"
                    :time="item.time" :hour24="item.hour24" :seconds="item.seconds" :display-format="item.displayFormat"
                    :min="item.min" :max="item.max" :disables="item.disables" :conditions="item.conditions"
                    :disabled="item.disabled" />
            </template>
            <template v-else-if="item.type == 'dates'">
                <DatesElement class="my-2" :name="item.name" :label="item.label" :placeholder="item.placeholder"
                    :description="item.description" :addons="item.addons" :rules="item.rules" :mode="item.mode"
                    :field-name="item.fieldName" :info="item.info" :default="item.default" :date="item.date"
                    :time="item.time" :hour24="item.hour24" :seconds="item.seconds" :display-format="item.displayFormat"
                    :min="item.min" :max="item.max" :disables="item.disables" :conditions="item.conditions"
                    :disabled="item.disabled" />


            </template>
            <template v-else-if="item.type == 'slider'">
                <SliderElement :name="item.name" :label="item.label" :description="item.description"
                    :addons="item.addons" :rules="item.rules" :mode="item.mode" :field-name="item.fieldName"
                    :info="item.info" :default="item.default" tooltip-position="top" :min="item.min" :max="item.max"
                    :step="item.step" :merge="item.merge" :format="item.format" :conditions="item.conditions"
                    :disabled="item.disabled" class="form-slider my-2" />
            </template>
            <template v-else-if="item.type == 'checkbox'">
                <CheckboxElement class="my-2" :name="item.name" :label="item.label" :text="item.text"
                    :description="item.description" :addons="item.addons" :true-value="item.trueValue"
                    :false-value="item.falseValue" :rules="item.rules" :field-name="item.fieldName" :info="item.info"
                    :default="item.default" :conditions="item.conditions" :disabled="item.disabled" align="left">
                </CheckboxElement>
            </template>
            <template v-else-if="item.type == 'checkboxgroup'">
                <CheckboxgroupElement class="checkboxgroup-container my-2" :name="item.name" :label="item.label"
                    :text="item.text" :description="item.description" :addons="item.addons" :items="item.items"
                    :rules="item.rules" :field-name="item.fieldName" :info="item.info" :default="item.default"
                    :conditions="item.conditions" :disabled="item.disabled" :view="item.view ? item.view : 'blocks'" />
            </template>

            <template v-else-if="item.type == 'radiogroup'">
                <RadiogroupElement class="radiogroup-container  my-2" :name="item.name" :label="item.label"
                    :text="item.text" :description="item.description" :addons="item.addons" :items="item.items"
                    :rules="item.rules" :field-name="item.fieldName" :info="item.info" :default="item.default"
                    :conditions="item.conditions" :disabled="item.disabled" view="blocks" />
            </template>
            <template v-else-if="item.type == 'toggle'">
                <ToggleElement class="my-2" :name="item.name" :label="item.label" :text="item.text"
                    :labels="item.labels" :description="item.description" :addons="item.addons"
                    :true-value="item.trueValue" :false-value="item.falseValue" :rules="item.rules"
                    :field-name="item.fieldName" :info="item.info" :default="item.default" :conditions="item.conditions"
                    :disabled="item.disabled" />
            </template>

            <template v-else-if="item.type == 'select'">
                <SelectElement class="my-2" :name="item.name" :label="item.label" :description="item.description"
                    :items="item.items" :rules="item.rules" :field-name="item.fieldName" :info="item.info"
                    :placeholder="item.placeholder" :default="item.default" :conditions="item.conditions"
                    :disabled="item.disabled" :native="false" :search="true" input-type="search" :strict="false"
                    :truncate="true" :autocomplete="false" open-direction="bottom" :no-options-text="item.noOptionsText"
                    :no-results-text="item.noResultsText" :trackBy="item.trackBy" :can-clear="item.canClear"
                    :close-on-select="true" :value-prop="item.valueProp" :label-prop="item.labelProp" />
                <!-- object: true // to retrun object not value -->
            </template>
            <template v-else-if="item.type == 'tags' || item.type == 'multiselect'">
                <TagsElement class="my-2" :name="item.name" :label="item.label" :description="item.description"
                    :items="item.items" :rules="item.rules" :field-name="item.fieldName" :info="item.info"
                    :default="item.default" :conditions="item.conditions" :disabled="item.disabled" :native="false"
                    :search="true" input-type="search" :trackBy="item.trackBy" :no-options-text="item.noOptionsText"
                    :no-results-text="item.noResultsText" :strict="false" :break-tags="true" :create="item.create"
                    :append-new-option="item.appendNewOption" :addOptionOn="item.addOptionOn" :max="item.max"
                    :can-clear="item.canClear" :hide-selected="item.hideSelected" :close-on-select="item.closeOnSelect"
                    open-direction="bottom" />
            </template>

            <template v-else-if="item.type == 'file' || item.type == 'multifile'">
                <!-- <FileInputElement  class="my-2" :name="item.name" :label="item.label" :description="item.description" :items="item.items"
                    :rules="item.rules" :field-name="item.fieldName" :info="item.info" :conditions="item.conditions"
                    :disabled="item.disabled" :accept="item.accept" :multiple="item.type == 'multifile'" /> -->
                <!-- <FileElement v-if="item.type == 'file'" :drop="false" :name="item.name + '_1'"  :label="item.label" view="gallery" /> -->
                <FileElement v-if="item.type == 'file'" :drop="true" :name="item.name + '_2'" :label="item.label"
                    view="gallery" />

                <!-- <hr />

                <MultifileElement v-if="item.type == 'multifile'" :name="item.name + '_1'" :label="item.label"
                    :description="item.description" :items="item.items" :rules="item.rules" :field-name="item.fieldName"
                    :info="item.info" :conditions="item.conditions" :disabled="item.disabled" :auto="false"
                    :drop="false" view="gallery" /> -->
                <MultifileElement v-if="item.type == 'multifile'" :name="item.name + '_2'" :label="item.label"
                    :description="item.description" :items="item.items" :rules="item.rules" :field-name="item.fieldName"
                    :info="item.info" :conditions="item.conditions" :disabled="item.disabled" :auto="false" :drop="true"
                    view="gallery" />
            </template>

            <template v-else-if="item.type == 'list'">

                <ListElement class="my-2" :name="item.name" :label="item.label" :info="item.info"
                    :initial="item.initial" :min="item.min" :max="item.max" :add-text="item.addText"
                    :controls="item.controls" :description="item.description" :conditions="item.conditions">
                    <template #default="{ index }">
                        <ObjectElement :name="index" :label="`${item.element.label} ${index + 1}`"
                            :info="item.element.info" :conditions="item.element.conditions">
                            <!-- {{ item.element.schema }} -->
                            <FormBuilderSchema :schema_obj="item.element.schema" />
                        </ObjectElement>
                    </template>
                </ListElement>
            </template>

            <template v-else-if="item.type == 'group'">

                <GroupElement class="my-2" :name="item.name" :label="item.label" :info="item.info"
                    :conditions="item.conditions" :columns="item.columns">
                    <template v-if="item.builder.type == 'container'">
                        <FormBuilderSchema :schema_obj="item.schema" />
                    </template>
                    <template v-else>
                        <template v-for="(itemGroup, keyG) in item.schema" :key="keyG">
                            <GroupElement :name="itemGroup.name" :label="itemGroup.label" :info="itemGroup.info"
                                :conditions="itemGroup.conditions" :columns="itemGroup.columns">
                                <!-- {{ itemGroup.schema }} -->
                                <FormBuilderSchema :schema_obj="itemGroup.schema" />
                            </GroupElement>
                        </template>
                    </template>
                </GroupElement>
            </template>

            <!-- <DatepickerWrapper id="test" /> -->

            <!-- <hr /> -->
        </template>
    </CardPartial>
</template>

<script>
import CardIndex from '@/components/partials/card_components/CardIndex.vue';

export default {
    name: "FormBuilderSchema",
    components: {},//{ FwbButton, FwbModal,FwbTooltip },
    props: ['schema_obj'],
    data() {
        let temp = this;
        return {
        }
    },
    mounted() {
    },
    updated() {
    },
    methods: {
        static_align(position) {
            console.log("static align", position)
            switch (position) {
                case 'left':
                    return 'end';
                case 'right':
                    return 'start';
                case 'center':
                    return 'center';
                default:
                    return 'end';
            }
        }
    },
}
</script>