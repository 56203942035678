<template>
    <div class="h-full-height flex px-4 pb-10">

        <div class="align-middle my-auto text-center w-11/12 mx-auto">
            <h2 class="font-Poppins font-medium text-3xl/9 text-black-protostars dark:text-white mb-2">
                Creating your Cyber Resiliance SaaS Audit Report
            </h2>
            <p class="font-Poppins font-normal text-base/5 text-grey-protostars dark:text-grey-protostars-100 mb-3">
                This could take up to 30 minutes. You can close this page and you will be notified when processing is done.</p>

            <div class="relative w-3/4 h-2 bg-gray-200 rounded-full mx-auto my-4">
                <div class="absolute top-0 left-0 h-full bg-yellow-protostars rounded-full animate-progress"></div>
            </div>

        </div>
    </div>
</template>

<script>
// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';
// // import AudioRecorder from 'vue-audio-recorder'
// import { initFlowbite } from 'flowbite'
// import axios from 'axios';
// import { FwbProgress } from 'flowbite-vue'


export default {
    name: "AuditProgress",
    components: {  },
    data() {
        let temp = this;
        return {

        }
    },
    mounted() {
        console.log(this.$refs.auditFormRef)

        let temp = this
        setTimeout(function () { 
           temp.$router.push('/audits/audit_steps/'+temp.$route.params.id)
        }, 5000);

    },
    beforeUnmount() {
    },
    updated() {
    },
    watch: {
    },
    methods: {
    }
}
</script>

<style>
@keyframes progress {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

.animate-progress {
    animation: progress 4s ease-in-out forwards;
}
</style>
