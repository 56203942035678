<template>



    <div class="h-full-height  px-4 pb-10 mb-5">

        <BreadcrumbComponent class="px-3 md:px-4 xl:px-5 py-1" :items="breadcrumb_items" />

        <div class="w-full h-full mt-4 px-3 md:px-4 xl:px-5">

            <h2 class="page-title">Glossary</h2>



            <div v-if="glossary_list != null" id="accordion-collapse" data-accordion="collapse" 
            class="mt-4 mb-8 rounded-b-none"
            data-active-classes="!text-primary dark:!text-grey-protostars-100 border-2 rounded-b-none">
                <div v-for="(item, index) in glossary_list" :key="index">
                    <h2 :id="'accordion-collapse-heading-' + (index + 1)">
                        <button type="button" class=" mt-3 flex items-center justify-between w-full 
                        p-5 font-medium rtl:text-right text-black-protostars border  
                        border-gray-200 rounded-xl focus:ring-4 
                        focus:ring-gray-200 dark:focus:ring-gray-800 
                        dark:border-gray-700 dark:text-white 
                        hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 capitalize bg-white  dark:bg-black-protostars 
                        " :data-accordion-target="'#accordion-collapse-body-' + (index + 1)" 
                            :aria-controls="'accordion-collapse-body-' + (index + 1)" :aria-expanded="index == 0">
                            <span>{{ item.title }}</span>
                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="M9 5 5 1 1 5" />
                            </svg>
                        </button>
                    </h2>
                    <div :id="'accordion-collapse-body-' + (index + 1)" class="hidden"
                        :aria-labelledby="'accordion-collapse-heading-' + (index + 1)">
                        <div class="p-5 bg-white border  border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                            <div v-html="item.content"></div>
                        </div>
                    </div>
                </div>
            </div>




        </div>

    </div>
</template>

<script>
import { initFlowbite } from 'flowbite'
import axios from 'axios';


export default {
    name: "GlossaryListIndex",
    components: {},
    data() {
        let temp = this;
        return {

            glossary_list: [],


            breadcrumb_items: [
                {
                    icon: 'fa-solid fa-house',
                    // text:'',
                    to: '#'
                },
                {
                    text: 'Compilance',
                },
            ],

            is_loading: false

        }
    },
    mounted() {
        this.glossary_list = [
            {
                title: "SOFTWARE BILL OF MATERIALS (SBOM)",
                content: `
                <p class="text-black-protostars dark:text-white text-sm font-bold">For the Board of Directors</p>
                <p class="text-black-protostars dark:text-white text-sm font-normal">The SBOM ‘software bill of materials’ means a formal record containing details and supply chain relationships of components included in the software elements of a product with digital elements.</p>
                <br/>
                <p class="text-black-protostars dark:text-white text-sm font-bold">For the CEO:</p>
                <p class="text-black-protostars dark:text-white text-sm font-normal">An SBOM (Software Bill of Materials) is a comprehensive list detailing every software component used in our systems, from direct products to their dependencies. It's instrumental in identifying security vulnerabilities, ensuring all software complies with relevant regulations, and providing transparency into our software architecture. This tool supports strategic decisions by offering clear insights into potential risks and compliance issues that could impact our operations and reputation. As we navigate the complexities of digital transformation, the SBOM helps us manage risks proactively, maintain trust with our customers, and streamline our response to security threats. Its integration into our software development lifecycle underscores our commitment to cybersecurity and operational excellence, aligning with our broader business goals and enhancing our competitive edge.</p>
                <br/>
                <p class="text-black-protostars dark:text-white text-sm font-bold">For the CFO:</p>
                <p class="text-black-protostars dark:text-white text-sm font-normal">The SBOM provides a detailed view of all software components used within our organization, serving as a critical tool for financial governance. It supports cost management by identifying redundant or outdated technologies that can be consolidated or replaced, thus optimizing technology spending. The SBOM also plays a vital role in compliance, helping us adhere to licensing agreements and avoid potential fines and legal complications. By maintaining an accurate and up-to-date SBOM, we can better predict and allocate budgets for software development and maintenance, ensuring financial efficiency and stability. This transparency not only aids in strategic planning but also protects the company from financial risks associated with software vulnerabilities and non-compliance.</p>
                <br/>
                <p class="text-black-protostars dark:text-white text-sm font-bold">For the CTO:</p>
                <p class="text-black-protostars dark:text-white text-sm font-normal">The SBOM is pivotal for technological governance, providing a clear inventory of all software components, including libraries and dependencies. This visibility is crucial for managing our tech stack effectively, ensuring that all components are up-to-date and secure. By integrating SBOMs into our development processes, we can quickly identify and address security vulnerabilities, reducing the potential for breaches that could disrupt our operations. The SBOM also facilitates better management of software licenses, ensuring compliance and avoiding legal issues. It enables us to streamline our development processes, foster innovation, and maintain a robust and secure IT infrastructure. As technology evolves, the SBOM will be vital in managing the complexities of new software integrations and deployments, keeping our systems resilient in the face of emerging threats.</p>
                <br/>
                <p class="text-black-protostars dark:text-white text-sm font-bold">For the CISO:</p>
                <p class="text-black-protostars dark:text-white text-sm font-normal">An SBOM is essential for cybersecurity management, cataloging every piece of software from applications to their smallest components. This detailed inventory is crucial for identifying security vulnerabilities and ensuring no component compromises our security posture. It allows for proactive risk management by highlighting potential security flaws before they can be exploited, thus strengthening our overall cybersecurity framework. The SBOM also supports compliance with data protection regulations, helping to prevent legal issues and potential penalties. By maintaining a comprehensive and up-to-date SBOM, we can enhance our incident response capabilities, making our organization more resilient against cyber attacks and reducing downtime.</p>
                <br/>
                <p class="text-black-protostars dark:text-white text-sm font-bold">For the CMO:</p>
                <p class="text-black-protostars dark:text-white text-sm font-normal">The SBOM ensures that all marketing technologies used across our campaigns are cataloged and compliant with industry standards. This transparency not only helps in managing software lifecycles effectively but also safeguards our brand reputation by mitigating risks associated with software vulnerabilities. An accurate SBOM enables us to leverage the latest technology safely and efficiently, maintaining a competitive edge in our marketing efforts. It also ensures that we adhere to data privacy laws, critical for maintaining customer trust. As the digital landscape evolves, the SBOM will be instrumental in helping the marketing team adopt new technologies securely and compliantly, thereby enhancing our marketing capabilities and brand integrity.</p>
                <br/>
                <p class="text-black-protostars dark:text-white text-sm font-bold">For the CRO:</p>
                <p class="text-black-protostars dark:text-white text-sm font-normal">For a Chief Risk Officer, the SBOM is a strategic tool in the risk management toolkit, detailing every software component used within the organization. This granularity helps in pinpointing where vulnerabilities might exist and assessing their potential impact on our operations. By integrating the SBOM into our risk management processes, we can better predict and mitigate risks associated with software dependencies and obsolescence. The SBOM also aids in regulatory compliance, reducing the risk of penalties from non-compliance with licensing and data protection laws. Maintaining an up-to-date SBOM ensures that we are prepared to address risks promptly, supporting our overall risk mitigation strategies and helping safeguard the organization's assets and reputation.</p>`},

            { title: "ARTICLE 8: HIGH-RISK AI SYSTEMS", content: "<p class='text-black-protostars dark:text-white text-sm font-normal'>ARTICLE 8: HIGH-RISK AI SYSTEMS</p>" },
            { title: "ARTICLE 10: OBLIGATIONS OF MANUFACTURERS", content: "<p class='text-black-protostars dark:text-white text-sm font-normal'>ARTICLE 10: OBLIGATIONS OF MANUFACTURERS</p>" },
            { title: "Article 11: Reporting obligations", content: "<p class='text-black-protostars dark:text-white text-sm font-normal'>Article 11: Reporting obligations</p>" },
            { title: "Article 12: authorised representatives", content: "<p class='text-black-protostars dark:text-white text-sm font-normal'>Article 12: authorised representatives</p>" },
            { title: "ANNEX 1: ESSENTIAL REQUIREMENTS", content: 
                `
                <p class="text-black-protostars dark:text-white text-sm font-bold">For the Board of Directors: </p>
                <p class="text-black-protostars dark:text-white text-sm font-normal">Annex I of the Cyber Resilience Act outlines comprehensive security requirements for products with digital elements, focusing on ensuring an appropriate level of cybersecurity throughout the product lifecycle. Board members should consider these requirements crucially as they reflect both strategic and compliance imperatives. Here’s what board members need to understand:</p>
                <ol class="ps-5 mt-3 mb-3 space-y-2 list-decimal list-inside text-sm font-normal text-black-protostars dark:text-white">
                    <li>Strategic Alignment: The Act requires products to be developed with robust cybersecurity measures, aligning with strategic objectives for risk management and product integrity. This necessitates board-level oversight to ensure that products not only comply with these regulations but also support the company’s broader strategic goals.</li>
                    <li>Governance and Risk Management: The Act emphasizes the elimination of known vulnerabilities and maintaining security through the product’s lifecycle, including through updates. This requires robust governance frameworks and continuous risk assessment processes to be in place, areas where board oversight is critical.</li>
                    <li>Compliance and Liability: Non-compliance with these stringent requirements could lead to legal and financial repercussions. Board members must ensure the organization adheres to these standards to avoid penalties and damage to reputation.</li>
                    <li>Financial Impact: Implementing and maintaining the required security measures may involve significant investment in technology, training, and process adjustments. Board members should ensure that these expenditures are budgeted and that they understand the financial impact, including potential ROI through enhanced market trust and product competitiveness.</li>
                    <li>Reputation Management: As cybersecurity becomes increasingly important to consumers, compliance with these regulations can enhance corporate reputation and trustworthiness. The board should consider how cybersecurity initiatives can be leveraged in corporate communication and branding strategies.</li>
                    <li>Innovation and Competitive Advantage: Board members should view these requirements not just as compliance measures but as opportunities to innovate and create products that offer competitive advantages in increasingly security-conscious markets.</li>
                    <li>Monitoring and Reporting: It’s vital for board members to receive regular updates on cybersecurity efforts and compliance status. This ensures that they can make informed decisions and provide appropriate guidance on strategic adjustments as needed.</li>
                </ol>
                <p class="text-black-protostars dark:text-white text-sm font-normal">Board members play a crucial role in ensuring that the organization not only complies with Annex I of the Cyber Resilience Act but also strategically leverages these requirements to enhance business value and market position.</p>
                <br/>

                <p class="text-black-protostars dark:text-white text-sm font-bold">For the CEO: </p>
                <p class="text-black-protostars dark:text-white text-sm font-normal">Annex I of the Cyber Resilience Act sets out stringent security requirements for products with digital elements, emphasizing the need to design, develop, and produce such products with a focus on high cybersecurity standards. Key stipulations include:</p>
                <ol class="ps-5 mt-3 mb-3 space-y-2 list-decimal list-inside text-sm font-normal text-black-protostars dark:text-white">
                    <li>Secure Design and Development: Products must be crafted to maintain cybersecurity proportionate to their associated risks.</li>
                    <li>Vulnerability Management: They should be free of known exploitable vulnerabilities at delivery.</li>
                    <li>Secure Configuration: Products must be secure by default, allowing users to reset to original secure settings as needed.</li>
                    <li>Access Control: Implementation of robust control mechanisms like authentication and access management is required to prevent unauthorized access.</li>
                    <li>Data Protection: Encryption and other state-of-the-art techniques must be used to safeguard the confidentiality and integrity of all processed data. This includes ensuring that data is adequate, relevant, and minimal as per its intended use.</li>
                    <li>Availability and Resilience: Products should ensure the reliability of essential functions and be resilient against attacks such as denial of service. They should also minimize negative impacts on other devices and networks.</li>
                    <li>Incident Impact Reduction: The design should limit potential attack surfaces and mitigate damages from any security incidents.</li>
                    <li>Monitoring and Updates**: Products must be capable of recording and monitoring relevant activities and be maintainable through timely security updates, which could be automatic and include user notifications.</li>
                </ol>
                <p class="text-black-protostars dark:text-white text-sm font-normal">These requirements are designed to ensure that all phases of product life—from design to end-use—contribute to a secure and resilient digital environment.</p>
                <br/>
                
                <p class="text-black-protostars dark:text-white text-sm font-bold">For the CFO: </p>
                <p class="text-black-protostars dark:text-white text-sm font-normal">Annex I of the Cyber Resilience Act outlines crucial security requirements for products with digital elements, emphasizing the necessity of integrating strong cybersecurity measures into the product lifecycle. Here are the financial and compliance implications for CFOs:</p>
                <ol class="ps-5 mt-3 mb-3 space-y-2 list-decimal list-inside text-sm font-normal text-black-protostars dark:text-white">
                    <li>Development Costs: Investments in secure design and development practices are mandatory, requiring up-front costs to ensure products meet the stipulated cybersecurity standards.</li>
                    <li>Risk Management: Products must be devoid of known vulnerabilities upon delivery, necessitating comprehensive risk assessments and potential increases in R&D expenditures.</li>
                    <li>Compliance and Configuration: Secure default settings and the capability for users to reset products to these settings may lead to additional development costs.</li>
                    <li>Data and Access Security: Implementing robust encryption and access control systems, including identity management, may require significant investment in technology and training.</li>
                    <li>Operational Resilience: Ensuring product availability and resilience against attacks like Denial of Service could involve substantial investment in infrastructure and ongoing maintenance costs.</li>
                    <li>Minimization of Impact: Designing products to limit attack surfaces and reduce incident impact requires continuous updates and mitigation strategies, potentially increasing operational costs.</li>
                    <li>Regulatory Compliance: Continuous monitoring and the provision of timely security updates, including automatic updates, necessitate a reliable, possibly automated, update system, adding to operational expenses.</li>
                    <li>Financial Reporting and Forecasting: CFOs must account for these increased expenditures in financial planning and reporting, ensuring that investments in cybersecurity translate into compliance, reduced risk, and potentially lower insurance costs in the long run.</li>                
                </ol>
                <p class="text-black-protostars dark:text-white text-sm font-normal">Overall, Annex I demands significant financial commitment to cybersecurity, underscoring its importance in safeguarding company assets and maintaining regulatory compliance.</p>
                <br/>
                                
                <p class="text-black-protostars dark:text-white text-sm font-bold">For the CMO: </p>
                <p class="text-black-protostars dark:text-white text-sm font-normal">Annex I of the Cyber Resilience Act imposes specific security requirements for products with digital elements, focusing on integrating robust cybersecurity features from the design stage through to production and use. For a Chief Marketing Officer (CMO), understanding these requirements is crucial for aligning marketing strategies with compliance and customer trust. Here’s what a CMO needs to consider:</p>
                <ol class="ps-5 mt-3 mb-3 space-y-2 list-decimal list-inside text-sm font-normal text-black-protostars dark:text-white">
                    <li>Product Positioning: Emphasize the security features of products as a key selling point, highlighting compliance with stringent regulations to build trust and differentiate in competitive markets.</li>
                    <li>Customer Communication: Clearly communicate the security benefits and measures, such as secure by default configurations and data protection through encryption, to reassure customers about the safety of their data.</li>
                    <li>Brand Reputation: Leveraging compliance with these high standards as part of the brand’s commitment to security can enhance reputation and customer loyalty, particularly in sectors where data security is critical.</li>
                    <li>Market Education: Educate the market on the importance of cybersecurity and how your products meet these new stringent requirements, potentially using this as a platform for thought leadership in your industry.</li>
                    <li>Feedback Integration: Use customer feedback on security features to guide product development and marketing strategies, ensuring that marketing messages align with customer expectations and regulatory standards.</li>
                    <li>Promotional Strategies: Develop marketing campaigns that focus on the robust security features of your products. This can include detailed explanations of how these features protect users against emerging cyber threats.</li>
                    <li>Collaboration with Product Teams: Work closely with product development teams to understand the specifics of security features and accurately reflect these in marketing materials and campaigns.</li>
                    <li>Crisis Management: Prepare marketing strategies to swiftly address potential breaches or vulnerabilities, mitigating any negative impact on customer trust and brand image.</li>
                </ol>  
                <p class="text-black-protostars dark:text-white text-sm font-noraml">For a CMO, Annex I of the Cyber Resilience Act is not just a regulatory requirement but an opportunity to enhance brand value and customer engagement through a strong commitment to cybersecurity.</p>
                ` },
            { title: "ISO 27001 INFORMATION SECURITY", content: "<p class='text-black-protostars dark:text-white text-sm font-normal'>ISO 27001 INFORMATION SECURITY</p>" },
            { title: "ISO 42001 AI MANAGEMENT SYSTEMS", content: "<p class='text-black-protostars dark:text-white text-sm font-normal'>ISO 42001 AI MANAGEMENT SYSTEMS </p>" },

        ]

        setTimeout(() => {
            initFlowbite();
        }, 1500);

    },
    beforeUnmount() {
    },
    updated() {
    },
    watch: {
    },
    methods: {


    }
}
</script>

<style></style>
