export const audits_steps =
    [
        {
            title: "Introduction", status: "success", status_msg: "", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
        {
            title: "Scope of Audit", status: "success", status_msg: "", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
        {
            title: "Audit Methodology", status: "warning", status_msg: "", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
        {
            title: "Software Code Risk Assessment",
            status: "danger",
            status_msg: "10 issues to be resolved",
            is_code: true,
            description: [
                {
                    title: "Unprotected raw SQL query",

                    issue_text: "This sample shows what would normally occur if we used raw SQL queries which are prone to the SQL injection attacks",
                    issue_code: `public function show($id){
                    try { 
                        $client = request()->header('client');        
                    $job = DB::raw('SELECT * FROM jobs WHERE client="'.$client.'" AND  id='.$id);
                            if($job==null) {
                    return $this->respondError('This job was not found!');
                                }
                            $job = new JobResource($job);
                            return $this->respondWithSuccess($job);
                            } catch (Exception $e) {
                            Log::error($e->getMessage().' ' .$e->getFile().' '.$e->getLine());
                            return $this->respondError($e->getMessage());
                            }
                    }`,
                    issue_highlight_lines: [4],

                    solution_text: "Here we can see a raw SQL query with the purpose of retrieving a single job from the database table based on the provided ID",
                    solution_code: `public function show($id)
                                { 
                                    try {
                                        $client = request()->header('client');
                                        $job = Job::query()
                                        ->where(['client'=>$client,'id'=>$id])
                                        ->first();
                                        if($job==null) {
                                            return $this->respondError('This job was not found!');
                                            }
                                        $job = new JobResource($job);
                                        return $this->respondWithSuccess($job);
                                        } catch (Exception $e) {
                                        Log::error($e->getMessage().' ' .$e->getFile().' '.$e->getLine());
                                        return $this->respondError($e->getMessage());
                                        }
                                }`,
                    solution_highlight_lines: [5, 6, 7],

                    comments: [
                        {
                            users: [{ name: 'Sara', }, { name: "Kim" }],
                            content: "The result of calling the API of the function and sending the ID parameter as “390 OR 1=1” will cause the SQL query condition to be always true and fetch all of the jobs in the database instead of just the intended one ",
                            user: "Kim",
                            time: "12/09/2021 - 13:38",

                            replies: [
                                {
                                    user: 'Sara',
                                    content: "The result of calling the API of the function and sending the ID parameter as “390 OR 1=1” will cause the SQL query condition to be always true and fetch all of the jobs in the database instead of just the intended one ",
                                    time: '12/09/2021 - 13:40',
                                },
                                {
                                    user: 'Kim',
                                    content: "The result of calling the API of the function and sending the ID parameter as “390 OR 1=1” will cause the SQL query condition to be always true and fetch all of the jobs in the database instead of just the intended one ",
                                    time: '12/09/2021 - 13:50',
                                },

                            ]
                        }
                    ]
                }
            ],
            description_standard: null
            // {
            //     "authController.js": {
            //         "Allow No More Than 10 Password Attempts Before a Lockout Initiates": {
            //             "implemented": true,
            //             "description": "exports.signin = (req, res) => { ... if (userData.attempts > MAX_ATTEMPTS) { return res.status(401).json({ error: 'Maximum login attempts exceeded. Please try again later.' }); } ... }"
            //         },
            //         "Use of Two-Factor Authentication (2FA) System required": {
            //             "implemented": true,
            //             "description": "exports.verify2FA = (req, res) => { ... const verified = speakeasy.totp.verify({ secret, encoding: 'base32', token, window: 2, }); ... }"
            //         },
            //         "Password rotation/change is 90+ days": {
            //             "implemented": true,
            //             "description": "exports.checkPasswordExpiration = (req, res, next) => { ... if (differenceInDays > PASSWORD_EXPIRATION_PERIOD) { return res.status(401).json({ error: 'Password expired. Please change your password.' }); } ... }"
            //         },
            //         "Ensure password masking option can be turned off to check password is correct": {
            //             "implemented": false,
            //             "description": "File name: authController.js.\n Ensure password masking option can be turned off to check password is correct"
            //         },
            //         "Don’t Reuse Passwords": {
            //             "implemented": false,
            //             "description": "File name: authController.js.\n Don’t Reuse Passwords"
            //         },
            //         "Use Passwords of at Least 8 Characters or Longer if Set By a Person": {
            //             "implemented": true,
            //             "description": "exports.signup = async (req, res) => { ... if (password.length < 8) { return res.status(400).json({ error: 'Password must be at least 8 characters long' }); } ... }"
            //         },
            //         "Use a Combination of All ASCII Character Types in user password": {
            //             "implemented": false,
            //             "description": "File name: authController.js.\n Use a Combination of All ASCII Character Types in user password"
            //         },
            //         "Dictionary Words disabled/ blocked": {
            //             "implemented": true,
            //             "description": "exports.signup = async (req, res) => { ... if (words.some(word => newPassword.includes(word))) { return res.status(400).json({ error: 'Password contains dictionary words with combinations' }); } ... }"
            //         },
            //         "Adjacent Keyboard Strings are blocked": {
            //             "implemented": false,
            //             "description": "File name: authController.js.\n Adjacent Keyboard Strings are blocked"
            //         },
            //         "Ensure passwords are hashed": {
            //             "implemented": false,
            //             "description": "File name: authController.js.\n Ensure passwords are hashed"
            //         }
            //     },
            //     "isValidEmail.js": {
            //         "boundary_value_analysis": {
            //             "implemented": false,
            //             "description": "File name: isValidEmail.js.\n Boundary value analysis (BVA) is a technique that tests the input values at the edges of the valid or invalid ranges. For example, if a field accepts numbers between 1 and 100, BVA would test the values 0, 1, 100, and 101. BVA can help identify off-by-one errors, incorrect assumptions, and unexpected behaviours at the boundaries."
            //         },
            //         "equivalence_partitioning": {
            //             "implemented": false,
            //             "description": "File name: isValidEmail.js.\n Equivalence partitioning (EP) is a technique that divides the input values into groups that share the same characteristics and expected outcomes. For example, if a field accepts only alphabetic characters, EP would create three partitions: valid (A-Z, a-z), invalid (0-9, symbols), and empty. EP can help reduce the number of test cases by selecting one representative value from each partition."
            //         },
            //         "syntax_testing": {
            //             "implemented": true,
            //             "description": "exports.isValidEmail = (email) => {\n    // Regular expression for validating email format\n    const emailRegex = /^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$/;\n    return emailRegex.test(email);\n};"
            //         }
            //     }
            // }
        },
        {
            title: "SBOM (Software Bill of Materials) ", status: "success", status_msg: "", description: [
                {
                    files_download: [
                        { title: "SPDX", path: 'https://microservices-staging.bumblebeeai.io/uploads/images/bZWItlmTaEuLM8gGlQ2vuRZQlPO7dzHQPfxJE3PJ.json' },
                        { title: "CycloneDX", path: 'https://microservices-staging.bumblebeeai.io/uploads/images/uzCuY0CGcOAcMPmUGPQlDGtmeKhRFkIbWkGTCOKx.txt' }
                    ],
                    link_more:'/glossary',
                    comments: []
                },

            ]
        },
        {
            title: "Data Protection and Privacy", status: "warning", status_msg: "", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
        {
            title: "Access Control", status: "danger", status_msg: "10 issues to be resolved", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
        {
            title: "Network Security", status: "warning", status_msg: "", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
        {
            title: "Vulnerability Management", status: "warning", status_msg: "", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
        {
            title: "Incident Response", status: "warning", status_msg: "", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
        {
            title: "Physical Security", status: "warning", status_msg: "", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
        {
            title: "Documentation and Compliance", status: "warning", status_msg: "", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
        {
            title: "Reporting", status: "warning", status_msg: "", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
        {
            title: "Follow-up Actions", status: "warning", status_msg: "", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
        {
            title: "Conclusion", status: "warning", status_msg: "", description: [
                {
                    title: "Work in progress",
                    comments: []
                }
            ]
        },
    ]