<template>
    <div class="h-full px-4">
        <h2 class="text-grey-protostars-900 dark:text-white text-2xl/6 ">Test flow builder</h2>
        <div class="w-full mt-4 px-1">

            <div class="card block h-100 w-full mx-auto  ">

                <VueFlow ref="vueflowref" :nodes="nodes" :edges="edges" class="basicflow h-full mt-3"
                    :default-edge-options="{ type: 'smoothstep' }" :default-viewport="{ zoom: 1 }" :min-zoom="0.2"
                    :max-zoom="4" fit-view-on-init :zoom-on-scroll="false" :nodes-draggable="true"
                    :elements-selectable="true" :apply-changes="true" multi-selection-key-code="shift"
                    @nodes-selection-change="onNodeSelectionChange" :apply-default="true" @node-click="clickNode"
                    @edge-click="clickEdge" @nodes-change="onChangeNodes" @edges-change="onChangeEdges" @connect="onConnect"
                    @edge-update="onEdgeUpdate" @pane-click="clickPane">
                    <!-- <Background pattern-color="#aaa" gap="8" /> -->
                    <Panel position="top-right" class="panel-form-container" v-if="display_panel">
                        <div class="field">
                            <label for="label">Label:</label>
                            <input id="label" v-model="label" @input="handleUpdate" />
                        </div>

                        <!-- <div class="field">
                            <label for="bgColor">Background color:</label>
                            <input id="bgColor" v-model="bgColor" type="color" @input="handleUpdate" />
                        </div> -->
                    </Panel>

                    <MiniMap />

                    <Controls />


                </VueFlow>

            </div>

        </div>
        <div class="w-10/12 mx-auto mt-8 mb-4 text-center">
            <p class="text-base font-semibold" v-if="selected_node">You selected this section: <span
                    class="text-yellow-protostars-600">{{ selected_node.label }}</span></p>
        </div>



        <div class="w-10/12 mx-auto mt-8 mb-4 h-20">
            <div class="w-full flex justify-center">

                <button class="w-52 btn btn-protostars btn-primary text-white px-4 py-2 rounded-lg mx-1 "
                    @click="onAddNode">Add
                    node</button>
                <button class="w-52 btn btn-protostars form-bg-btn-danger text-white px-4 py-2 rounded-lg mx-1 "
                    :disabled="selected_node == null" @click="onRemoveNode">Remove selected node</button>
                <button class="w-52 btn btn-protostars btn-warning  text-white px-4 py-2 rounded-lg mx-1 "
                    :disabled="selected_node == null" @click="onEditNode">Edit selected node</button>

            </div>
            <div class="w-full flex justify-center mt-5">
                <button class="w-52 btn btn-protostars form-bg-btn-danger text-white px-4 py-2 rounded-lg mx-1 "
                    :disabled="selected_edge == null" @click="onRemoveEdge">Remove selected edge</button>
              
            </div>
        </div>


        <!-- {{ selected_items }} -->
        <!-- <br /> -->
        <!-- {{ selected_node }} -->
    </div>
</template>

<script>
import { VueFlow, useVueFlow, Panel } from '@vue-flow/core'
// import { Background } from '@vue-flow/background'
import { Controls } from '@vue-flow/controls'
import { MiniMap } from '@vue-flow/minimap'
import { ref } from 'vue'
import { MarkerType, Position } from '@vue-flow/core'

const { setNodesSelection } = useVueFlow();

import axios from 'axios'

const { onConnect, addEdges } = useVueFlow()
const { addNodes, removeNodes } = useVueFlow()


// const elements = ref(initialElements)

onConnect((params) => addEdges(params))

export default {
    name: "QuestionnaireVueFlow",
    components: { VueFlow, Controls, MiniMap, Panel },
    data() {
        let temp = this;
        return {


            elements: [
                {
                    id: '1',
                    type: 'input',
                    label: 'Situational Analysis',
                    selected: false,
                    position: { x: 10, y: 50 },
                    sourcePosition: Position.Right,
                    display: 'all'
                },
                {
                    id: '2',
                    label: 'Audit Project Setup',
                    position: { x: 250, y: 50 },
                    selected: false,
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    display: 'all'
                },
                {
                    id: '3',
                    label: 'Physical Security',
                    position: { x: 500, y: 50 },
                    selected: false,
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    display: 'all'
                },
                {
                    id: '4',
                    label: 'People Security',
                    selected: false,
                    position: { x: 750, y: 50 },
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                },
                {
                    id: '5',
                    label: 'Data Security',
                    selected: false,
                    position: { x: 750, y: 200 },
                    sourcePosition: Position.Left,
                    targetPosition: Position.Right,
                },
                {
                    id: '6',
                    label: 'Infrastructure Security',
                    selected: false,
                    position: { x: 500, y: 200 },
                    sourcePosition: Position.Left,
                    targetPosition: Position.Right,
                },
                {
                    id: '7',
                    type: 'output',
                    label: 'Crisis Management',
                    position: { x: 250, y: 200 },
                    sourcePosition: Position.Left,
                    targetPosition: Position.Right,
                    selected: false,
                    display: 'mobile'
                },
                // { id: '3', label: 'Node 3', position: { x: 250, y: 100 }, sourcePosition: Position.Right, targetPosition: Position.Left },
                // { id: '4', label: 'Node 4', position: { x: 500, y: 150 }, sourcePosition: Position.Right, targetPosition: Position.Left },
                // {
                //   id: '5',
                //   type: 'output',
                //   label: 'Node 5',
                //   position: { x: 750, y: 50 },
                //   sourcePosition: Position.Right,
                //   targetPosition: Position.Left,
                // },
                { id: 'e1-2', source: '1', target: '2', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e2-3', source: '2', target: '3', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e3-4', source: '3', target: '4', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e4-5', source: '4', target: '5', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e5-6', source: '5', target: '6', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e6-7', source: '6', target: '7', markerEnd: MarkerType.Arrow, display: 'all' },
                // {
                //   id: 'e4-5',
                //   source: '4',
                //   target: '5',
                //   style: { stroke: 'orange' },
                //   labelBgStyle: { fill: 'orange' },
                // },
                // { id: 'e3-4', source: '3', target: '4' },
            ],

            nodes: [
                {
                    id: '1',
                    type: 'input',
                    label: 'Situational Analysis',
                    selected: false,
                    position: { x: 10, y: 50 },
                    sourcePosition: Position.Right,
                    display: 'all'
                },
                {
                    id: '2',
                    label: 'Audit Project Setup',
                    position: { x: 250, y: 50 },
                    selected: false,
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    display: 'all'
                },
                {
                    id: '3',
                    label: 'Physical Security',
                    position: { x: 500, y: 50 },
                    selected: false,
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    display: 'all'
                },
                {
                    id: '4',
                    label: 'People Security',
                    selected: false,
                    position: { x: 750, y: 50 },
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                },
                {
                    id: '5',
                    label: 'Data Security',
                    selected: false,
                    position: { x: 750, y: 200 },
                    sourcePosition: Position.Left,
                    targetPosition: Position.Right,
                },
                {
                    id: '6',
                    label: 'Infrastructure Security',
                    selected: false,
                    position: { x: 500, y: 200 },
                    sourcePosition: Position.Left,
                    targetPosition: Position.Right,
                },
                {
                    id: '7',
                    type: 'output',
                    label: 'Crisis Management',
                    position: { x: 250, y: 200 },
                    sourcePosition: Position.Left,
                    targetPosition: Position.Right,
                    selected: false,
                    display: 'mobile'
                },
            ],
            edges: [
                { id: 'e1-2', source: '1', target: '2', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e2-3', source: '2', target: '3', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e3-4', source: '3', target: '4', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e4-5', source: '4', target: '5', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e5-6', source: '5', target: '6', markerEnd: MarkerType.Arrow, display: 'all' },
                { id: 'e6-7', source: '6', target: '7', markerEnd: MarkerType.Arrow, display: 'all' },
            ],

            selected_items: [],
            selected_node: null,
            selected_node_index: null,
            selected_edge: null,

            form_id: null,

            label: null,
            display_panel: false,


        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        this.form_id = this.$route.params.id
    },
    beforeUnmount() {
    },
    updated() {
    },
    watch: {
    },
    methods: {
        setNodesSelection,

        onNodeSelectionChange(selectedNodes) {
            console.log("onNodeSelectionChange")
            console.log(selectedNodes)
        },
        test() {
            console.log("select node")
            console.log(this.$refs.vueflowref)
            console.log("get selcted nodes")
            console.log(JSON.parse(JSON.stringify(this.$refs.vueflowref)))
            console.log(JSON.parse(JSON.stringify(this.$refs.vueflowref)).getSelectedElements)

            // console.log(this.$refs.vueflowref.getSelectedElements)
            // console.log(this.elements)
        },
        clickNode(temp) {
            console.log("clicked node")
            console.log(temp)
            // this.elements[2].selected = true
            // for (let i = 0; i < this.elements.length; i++) {
            //     this.elements[i].selected = false
            // }
            for (let i = 0; i < this.nodes.length; i++) {
                this.nodes[i].selected = false
            }
            temp.node.selected = true
            console.log(temp)

            console.log(this.$refs.vueflowref)

            this.selected_items = JSON.parse(JSON.stringify(this.$refs.vueflowref)).getSelectedElements
            this.selected_node = temp.node
            console.log()
            this.selected_edge = null
            this.selected_node_index = this.getIndexOfSelectedNode()
            // this.filteredElements
            // setNodesSelection([3])
            // addSelectedNodes
        },
        clickPane(temp) {
            console.log("Click pane")
            console.log(temp)
            this.selected_node = null
            this.selected_node_index = null
            this.selected_items = []
            this.selected_edge = null
        },
        clickEdge(temp){
            console.log("clicked edge")
            console.log(temp)
            this.selected_node = null
            this.selected_node_index = null
            this.selected_items = []
            this.selected_edge = temp.edge

        },


        generateRandomNode() {
            console.log("generateRandomNode", new Date().getMilliseconds())
            const newNode = {
                // id: this.elements.length - 5,
                id: new Date().getMilliseconds(),
                position: { x: Math.random() * 500, y: Math.random() * 400 },
                label: 'Random Node',
                type: 'default', // You can omit this as it's the fallback type
                targetPosition: Position.Right, // or Bottom, Left, Right,Top
                sourcePosition: Position.Left, // or Top, Left, Right,Bottom
            }
            this.selected_node = newNode
            this.selected_node_index = this.getIndexOfSelectedNode()
            return newNode
        },
        onAddNode() {
            console.log("in add node")
            const newnode = this.generateRandomNode()
            console.log(newnode)
            // this.elements.push(newnode)
            // add a single node to the graph
            this.$refs.vueflowref.addNodes(newnode)
            // console.log(this.elements)
            console.log(this.nodes)
        },
        onRemoveNode() {
            console.log("in remove selected node")
            // let random_index = Math.floor(Math.random() * (this.elements.length-6))
            // console.log(random_index)
            this.$refs.vueflowref.removeNodes(this.selected_items, false, false)
            // console.log(this.elements)
            console.log(this.nodes)
            this.selected_items=[]
            this.selected_node = null
            this.selected_node_index = null
        },
        onEditNode() {
            console.log("in edit selected node")
            // this.selected_node.label = "Edit node"
            console.log(this.selected_node)
            console.log(this.selected_node.label)
            this.display_panel = true
            this.label = this.selected_node.label
            this.selected_node_index = this.getIndexOfSelectedNode()
            this.nodes[this.selected_node_index] = this.selected_node
            console.log(this.label)
        },
        handleUpdate() {
            console.log("handle update")
            this.selected_node.label = this.label
            this.$refs.vueflowref.updateNodeData(this.selected_node.id, this.selected_node)
        },
        onChangeEdges(changes) {
            console.log("in onChangeEdges")
            console.log(changes)
        },
        onChangeNodes(changes){
            console.log("in onChangenodes")
            console.log(changes)
            console.log(JSON.parse(JSON.stringify(this.$refs.vueflowref)).getSelectedElements)
            this.selected_items = JSON.parse(JSON.stringify(this.$refs.vueflowref)).getSelectedElements
            let temp = this
            
            this.selected_node = this.selected_items[0]
        },
        onConnect(params) {
            console.log("onconnect")
            console.log(params)
            params.markerEnd = MarkerType.Arrow
            this.$refs.vueflowref.addEdges([params])
        },
        onEdgeUpdate({ edge, connection }) {
            console.log("onEdgeUpdate", edge, connection)
            this.$refs.vueflowref.updateEdge(edge, connection)
        },
        
        onRemoveEdge() {
            console.log("in remove selected edge")
            // let random_index = Math.floor(Math.random() * (this.elements.length-6))
            // console.log(random_index)
            this.$refs.vueflowref.removeEdges(this.selected_edge)
            // console.log(this.elements)
            console.log(this.edges)
            this.selected_edge = null
        },

        getIndexOfSelectedNode(){
            return this.nodes.findIndex(x => x.id === this.selected_node.id);
        }
    }
}
</script>


<style scoped>
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.27.1/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.27.1/dist/theme-default.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/controls@latest/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/minimap@latest/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/node-resizer@latest/dist/style.css';



.vue-flow__minimap {
    transform: scale(75%);
    transform-origin: bottom right;
}

input:checked+label {
    border: 2px solid var(--grey_primary);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>