<template>
    <div class="auth-container protostars-auth-container  ">
        <div class="">
            <div class="row h-full">
                <div class="xl:w-4/12 lg:w-6/12 md:w-8/12 w-[98%] mx-auto my-auto">
                    <div class="card card-auth mx-auto  p-4">
                        <img :src="isDark ? require('@/assets/images/protostars/logo-light.png') : require('@/assets/images/protostars/logo.png')"
                            class="logo-img mb-6" />
                        <Vueform add-class="vueFormContainer" :float-placeholders="false" v-model="formData"
                            validate-on="change|step" class="w-10/12 mx-auto" ref="registerFormRef"
                            :remove-class="{ div: ['grid'] }">
                            <StaticElement name="personalInfoH3" tag="h3" :content="'Personal Info'"
                                :attrs="{ class: ['sub-title-h3', 'mb-2'] }" />

                            <TextElement name="first_name" input-type="text" label="First name"
                                placeholder="Enter first name" :field-name="'First name'" rules="required"
                                class="mb-3" />
                            <TextElement name="last_name" input-type="text" label="Last name"
                                placeholder="Enter last name" :field-name="'Last name'" rules="required" class="mb-3" />
                            <TextElement name="email" input-type="email" label="Email" placeholder="Enter email"
                                :field-name="'Email'" rules="required" class="mb-3" />

                            <PhoneElement name="phone_number" label="Phone number" field-name="Phone number"
                                class="mb-3" />

                            <TextElement name="password" :input-type="show_password ? 'text' : 'password'"
                                label="Password" placeholder="Enter password" :field-name="'Password'" class="mb-3"
                                :rules='["required", "min:8", "at_least_uppercase", "at_least_lowercase",
                                    "at_least_number", "at_least_special"]'>
                                <!-- :rules='["required", "min:8", "regex:(?=.*[0-9])",
                                    "regex:(?=.*[a-z])",
                                    "regex:(?=.*[A-Z])",
                                    "regex:(?=.*[@#$%^&+=])"
                                ]' :messages='{
                                    "regex": "tes test",
                                    "regex:(?=.*[0-9])": "Password must contain at least one number",
                                    "regex:(?=.*[a-z])": "Password must contain at least one lowercase letter",
                                    "regex:(?=.*[A-Z])": "Password must contain at least one uppercase",
                                    "regex:(?=.*[@#$%^&+=])": "Password must contain at least one special character",

                                }'> -->
                                <!-- :rules='["required", "min:8", "regex:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}"]'> -->

                                <template #addon-after>
                                    <button type="button" @click="show_password = !show_password" class="addon-button">
                                        <font-awesome-icon icon="fa-solid fa-eye" v-if="!show_password" />
                                        <font-awesome-icon icon="fa-solid fa-eye-slash" v-else />
                                    </button>
                                </template>
                            </TextElement>
                            <TextElement name="confirm_password"
                                :input-type="show_confirm_password ? 'text' : 'password'" label="Confirm password"
                                placeholder="Reenter your password" :field-name="'confirm password'"
                                :rules='["required", "same:password"]' class="mb-3">
                                <template #addon-after>
                                    <button type="button" @click="show_confirm_password = !show_confirm_password"
                                        class="addon-button">
                                        <font-awesome-icon icon="fa-solid fa-eye" v-if="!show_confirm_password" />
                                        <font-awesome-icon icon="fa-solid fa-eye-slash" v-else />
                                    </button>
                                </template>
                            </TextElement>


                            <StaticElement name="businessProfileH3" tag="h3" :content="'Business Profile'"
                                :attrs="{ class: ['sub-title-h3', 'mb-2', 'mt-4'] }" />

                            <TextElement name="company_name" input-type="text" label="Company name"
                                placeholder="Enter company name" :field-name="'company name'" rules="required"
                                class="mb-3" />

                            <TextElement name="comapny_address" input-type="text" label="Company address"
                                placeholder="Enter company address" :field-name="'company address'" rules="required"
                                class="mb-3" />


                            <SelectElement name="country" label="Country (Address of Company)" :native="false"
                                :items="countries_list" rules="required" label-prop="name" value-prop="name"
                                :search="true" input-type="search" :strict="false" :truncate="true"
                                :close-on-select="true" :can-clear="true" :field-name="'country'" class="mb-3" />

                            <SelectElement name="industry" label="Industry" :native="false" :items="industries_list"
                                rules="required" label-prop="name" value-prop="name" :search="true" input-type="search"
                                :strict="false" :truncate="true" :close-on-select="true" :can-clear="true"
                                :field-name="'industry'" />


                        </Vueform>

                        <div class="w-10/12 mx-auto my-4">
                            <button v-if="!is_loading" class="btn-protostars btn-primary !w-full h-10 "
                                @click="submitRegister">
                                Sign up</button>
                            <button v-else class="btn-protostars btn-primary !w-full h-10 " disabled>
                                <div role="status" class="inline-block">
                                    <svg aria-hidden="true"
                                        class="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor" />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill" />
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div> Loading
                            </button>

                        </div>

                        <!-- <div
                            class="w-10/12 mx-auto flex items-center gap-2 py-6 text-sm text-slate-600 dark:text-white">
                            <div class="h-px w-11/12 bg-slate-200"></div>
                            OR
                            <div class="h-px w-11/12 bg-slate-200"></div>
                        </div>

                        <div class="w-10/12 mx-auto mt-4 flex flex-col gap-2">
                            <button class="btn btn-protostars btn-primary-outline btn-social-media "><img
                                    src="https://www.svgrepo.com/show/475656/google-color.svg" alt="Google"
                                    class="h-[18px] w-[18px] ">Sign up
                                with Google
                            </button>
                            <button class="btn btn-protostars mt-2 btn-primary-outline btn-social-media"><img
                                    src="https://www.svgrepo.com/show/448234/linkedin.svg" alt="Google"
                                    class="h-[18px] w-[18px] ">Sign up with
                                LinkedIn
                            </button>
                        </div> -->


                        <div class="w-10/12 mx-auto mt-4 flex justify-center items-center gap-2">
                            <div class="text-gray-500 text-base font-normal dark:text-white  ">Already have an account?
                            </div>
                            <div class="text-green-protostars text-base font-normal">
                                <router-link to="/protostars/login">
                                    Login
                                </router-link>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCountriesList } from "@/utils/helper-functions-locations.js"

import { inject } from 'vue';
import axios from "axios";


export default {
    name: "TestFormBuilder",
    data() {
        let temp = this;
        return {
            client_name: 'PROTOSTARS',
            formData: null,

            countries_list: [],
            industries_list: [{ 'name': 'Professional Services' }],

            customer_id: null,
            is_loading: false,

            isDark: inject('isDark'),
            show_password: false,
            show_confirm_password: false,
        }
    },
    mounted() {
        this.countries_list = this.getCountriesList()

        console.log(this.$refs.registerFormRef)

    },
    updated() {
        // console.log(this.$refs.registerFormRef)
    },
    watch: {
    },
    methods: {
        getCountriesList,
        changephone(phone, phoneObject, input) {
            console.log(phone)
            console.log(phoneObject)
            console.log(input)
            if (phoneObject?.formatted) {
                this.phone_number = phoneObject.number;
            }
        },
        submitRegister() {
            console.log("in submit register")
            console.log(this.formData)

            let register_form = this.$refs.registerFormRef
            register_form.validate()
            let form_errors = register_form.formErrors
            if (form_errors.length > 0) {
                console.log("not valid")

            } else {
                console.log('validated')
                this.is_loading = true
                this.checkUniqureEmailPhoneRequest()
            }
        },
        checkUniqureEmailPhoneRequest() {
            let formDataReq = new FormData()
            formDataReq.append("client", this.client_name);
            formDataReq.append("email", this.formData.email.trim());
            // formDataReq.append("phone", this.formData.phone_number);
            formDataReq.append("user_type", "user");

            axios
                .post("common/check/unique-email-phone", formDataReq, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: this.client_name
                    },
                })
                .then(async (response) => {
                    console.log(response);
                    if (response.data.user_found == 1) {
                        // let message = "The email/phone number has been registered before"
                        let message = "The email has been registered before"
                        this.$swal({
                            // title: "Title",
                            text: message,
                            icon: "error",
                            // timer: 3000,
                            customClass: {
                                htmlContainer: "!text-2xl",
                                confirmButton: "!bg-grey-protostars",
                            },
                        }).then((r) => {
                            // console.log(r.value);
                            this.is_loading = false;
                            // this.tabIndex = 0
                        });
                    } else {
                        this.sendSaveCustomerRequest()

                    }
                })
                .catch((error) => {
                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }

                    console.log(error)
                    // console.log(error.response);
                    this.is_loading = false;
                    this.$swal({
                        // title: "Title",
                        text: error_message,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-grey-protostars",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });

        },
        sendSaveCustomerRequest() {
            let formData = new FormData();
            formData.append("client", this.client_name);
            formData.append("first_name", this.formData.first_name);
            formData.append("second_name", this.formData.last_name);
            formData.append("email", this.formData.email);
            formData.append("phone", this.formData.phone_number);
            formData.append("password", this.formData.password);

            axios
                .post("user/register", formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: this.client_name,
                    },
                })
                .then(async (response) => {
                    console.log(response);
                    this.customer_id = response.data.id;
                    await this.sendRegisterCustomerRequest();
                })
                .catch((error) => {
                    console.log(error);
                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }

                    this.is_loading = false;
                    this.$swal({
                        // title: "Title",
                        text: error_message,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-grey-protostars",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });
        },
        sendRegisterCustomerRequest() {
            console.log("in send register customer request");
            let requestData = {
                client: this.client_name,
                user_id: this.customer_id,
                user_type: "user",

                name: this.formData.company_name,
                address: this.formData.company_address,
                country: this.formData.country,
                industry: this.formData.industry,
                status: "registered"

            };

            console.log(requestData);

            axios
                .post("company/create-public-profile", requestData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: this.client_name,
                    },
                })
                .then(async (response) => {
                    console.log(response);

                    let message = "Your profile was registered successfully"
                    this.$swal({
                        // title: "Title",
                        text: message,
                        icon: "success",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-grey-protostars",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                        this.$router.push('/protostars/login')
                        // this.tabIndex = 0
                    });

                    // this.compony_profile_id = response.data.id
                    // console.log("call here login :D")
                    // await axios
                    //     .post("user/login", {
                    //         email: this.email,
                    //         password: this.password,
                    //         client: this.client_name,
                    //     })
                    //     .then((response) => {
                    //         console.log(response);
                    //         // console.log(response.data);
                    //         this.access_token = response.data.access_token
                    //         this.user = response.data

                    //         // console.log("success")
                    //         this.last_saved_time = new Date()
                    //         this.tabIndex = this.tabIndex + 1;
                    //         this.is_loading = false
                    //         //   window.sessionStorage.setItem("user", JSON.stringify(response.data).token);
                    //         //   window.sessionStorage.setItem("user_type", "user");



                    //     })
                    //     .catch((error) => {
                    //         console.log(error);
                    //         let error_message = ''
                    //         if (error.response) {
                    //             console.error("error.response.data", error.response.data);
                    //             console.error("error.response.status", error.response.status);
                    //             console.error("error.response.headers", error.response.headers);
                    //             error_message = error.response.data.error
                    //         } else if (error.request) {
                    //             console.error("error-request", error.request);
                    //             console.error("Error-message", error.message);
                    //             error_message = error.message
                    //         } else {
                    //             console.error("Error-message", error.message);
                    //             error_message = error.message
                    //         }
                    //         this.$fire({
                    //             // title: "Title",
                    //             text: error_message,
                    //             type: "error",
                    //             customClass: {
                    //                 container: this.swal_container_class,
                    //                 confirmButton: this.swal_confirm_button_class,
                    //             },
                    //             // timer: 3000,
                    //         }).then((r) => {
                    //             // console.log(r.value);
                    //         });

                    //     });

                })
                .catch((error) => {
                    console.log(error)
                    this.is_loading = false;
                    // let error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    // if (error_message == null || error_message == '') {
                    //     error_message = 'Sorry, '
                    // }
                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }
                    this.$fire({
                        // title: "Title",
                        text: error_message,
                        type: "error",
                        customClass: {
                            container: this.swal_container_class,
                            confirmButton: this.swal_confirm_button_class,
                        },
                        // timer: 3000,
                    }).then((r) => {
                        // console.log(r.value);
                    });
                });
        },
    }
}
</script>
