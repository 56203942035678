<template>
    <LayoutAuth>
        <ForgetPasswordCard :client_name="client_name"
            :logo_src="isDark ? require('@/assets/images/protostars/logo-light.png') : require('@/assets/images/protostars/logo.png')"
            :base_url="'/protostars'" />
    </LayoutAuth>
</template>

<script>
import { inject } from 'vue';
import LayoutAuth from '@/components/Layout/LayoutAuth.vue'
import ForgetPasswordCard from '@/components/auth/ForgetPasswordCard.vue';

export default {
    name: "ForgetPasswordView",
    components: {LayoutAuth, ForgetPasswordCard },
    data() {
        let temp = this;
        return {
            client_name: 'PROTOSTARS',

            isDark: inject('isDark')

        }
    },
    mounted() {

    },
    updated() {
    },
    watch: {
    },
    methods: {

    }
}
</script>
