<template>
    <div class="h-full px-4">
        <BreadcrumbComponent :items="breadcrumb_items" />
        <OverlayLoading :is_loading="is_loading">
            <!-- <template #content> -->

            <div class="w-full my-4 px-3 md:px-4 xl:px-5  ">
                <!-- <CardPartial class="mb-2 " :card_class="''"
                        :header_class="'hidden'"
                        :body_class="'!py-1 dark:!pt-0 !px-0 dark:border dark:border-grey-protostars-100 rounded-lg'">
                        <template #cardHeader></template>
<template #cardContent>
                            <DataTable :data="[[1,2],[3,4]]" class="display">
                                <thead>
                                    <tr>
                                        <th>A</th>
                                        <th>B</th>
                                    </tr>
                                </thead>
                            </DataTable>
                        </template>
</CardPartial> -->
                <CardPartial class="mb-2 " :card_class="''">
                    <CardHeader :header_class="'card-header-table-filter'">
                        <div class="row align-middle m-auto w-full">
                            <div
                                class="lg:w-7/12 md:w-6/12 w-full md:flex md:align-middle md:items-center md:mb-0 mb-4">

                                <SearchFilterInput @updateSearchValue="updateSearchFilterValue" />
                            </div>
                            <div
                                class=" lg:w-5/12 md:w-6/12 w-full md:flex md:align-middle md:justify-end md:items-center md:mb-0 mb-2">
                                <button href="/questionnaires/create_questionnaire"
                                    class="btn-protostars btn-primary lg:float-right"
                                    @click="clickAddQuestionnaire">Create
                                    Questionnaire</button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody :body_class="'card-body-border-dark'">
                        <div class="relative overflow-x-auto  mt-0 ">
                            <table class="w-full table-protostars">
                                <thead class="">
                                    <tr>

                                        <th scope="col" class="">
                                            Title
                                        </th>
                                        <th scope="col" class="">
                                            Form ID
                                        </th>
                                        <th scope="col" class="">
                                            Created On
                                        </th>
                                        <th scope="col">
                                            Section
                                        </th>
                                        <th scope="col">

                                        </th>

                                    </tr>
                                </thead>
                                <tbody class="" v-if="questionnaires_list.length > 0">
                                    <tr class="cursor-pointer"
                                        v-for="(questionnaire_item, index) in questionnaires_list" :key="index">
                                        <th scope="row" class="">
                                            {{ questionnaire_item.name }}
                                        </th>
                                        <td class="">
                                            <a :href="url + '/form/' + questionnaire_item.form_id" target="_blank">
                                                {{ questionnaire_item.form_id }}
                                            </a>
                                        </td>
                                        <td class="">
                                            <!-- {{ formatDate(questionnaire_item.created_at) }} -->
                                            {{ questionnaire_item.created_at_web }}
                                        </td>
                                        <td class="">
                                            {{ questionnaire_item.section }}
                                        </td>
                                        <td class="actions-td">
                                            <DropdownContainer class="m-auto">
                                                <DropdownButton :id="'dropdownLeftEndButton_' + (index + 1)"
                                                    :dropdown_id="'dropdownLeftEnd_' + (index + 1)" placement="bottom"
                                                    :offset_distance="0" :offset_skidding="0">
                                                    <img v-if="isDark"
                                                        src="@/assets/images/protostars/more-icon-dark.png"
                                                        class="w-6 h-6" />
                                                    <img v-else src="@/assets/images/protostars/more-icon.png"
                                                        class="w-6 h-6" />
                                                </DropdownButton>
                                                <DropdownItemsUl :id="'dropdownLeftEnd_' + (index + 1)"
                                                    :button_id="'dropdownLeftEndButton_' + (index + 1)">
                                                    <DropdownItem>
                                                        <a href="#"
                                                            class="flex align-middle items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                            <img v-if="isDark" src="@/assets/images/view-icon-dark.png"
                                                                class="w-4.5 h-4.5 mr-2 inline-block" />
                                                            <img v-else src="@/assets/images/view-icon.png"
                                                                class="w-4.5 h-4.5 mr-2 inline-block" />
                                                            View
                                                        </a>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <a href="#"
                                                            class="flex align-middle items-center  px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                            <img v-if="isDark"
                                                                src="@/assets/images/delete-icon-default-dark.png"
                                                                class="w-4.5 h-4.5 mr-2 inline-block" />
                                                            <img v-else src="@/assets/images/delete-icon-default.png"
                                                                class="w-4.5 h-4.5 mr-2 inline-block" />
                                                            Delete
                                                        </a>
                                                    </DropdownItem>
                                                </DropdownItemsUl>
                                            </DropdownContainer>


                                            <!-- <button :id="'adropdownLeftEndButton_' + (index + 1)"
                                                :data-dropdown-toggle="'adropdownLeftEnd_' + (index + 1)"
                                                data-dropdown-placement="bottom" data-dropdown-offset-distance="0"
                                                data-dropdown-offset-skidding="0"
                                                class="me-3 mb-3 md:mb-0  p-0 w-6 h-6 text-center inline-flex items-center "
                                                type="button">
                                                <img v-if="isDark" src="@/assets/images/protostars/more-icon-dark.png"
                                                    class="w-6 h-6" />
                                                <img v-else src="@/assets/images/protostars/more-icon.png"
                                                    class="w-6 h-6" />
                                            </button>

                                            <div :id="'adropdownLeftEnd_' + (index + 1)"
                                                class="z-20 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-2xl dark:bg-grey-protostars-800 dark:shadow-2xl shadow-slate-100">
                                                <ul class="py-2 text-sm/normal text-stone-800 dark:text-white"
                                                    :aria-labelledby="'adropdownLeftEndButton_' + (index + 1)">
                                                    <li>
                                                        <a href="#"
                                                            class="flex align-middle items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                            <img v-if="isDark" src="@/assets/images/view-icon-dark.png"
                                                                class="w-4.5 h-4.5 mr-2 inline-block" />
                                                            <img v-else src="@/assets/images/view-icon.png"
                                                                class="w-4.5 h-4.5 mr-2 inline-block" />
                                                            View</a>
                                                    </li>
                                                    <li>
                                                        <a href="#"
                                                            class="flex align-middle items-center  px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                            <img v-if="isDark"
                                                                src="@/assets/images/delete-icon-default-dark.png"
                                                                class="w-4.5 h-4.5 mr-2 inline-block" />
                                                            <img v-else src="@/assets/images/delete-icon-default.png"
                                                                class="w-4.5 h-4.5 mr-2 inline-block" />
                                                            Delete</a>
                                                    </li>
                                                </ul>
                                            </div> -->
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="" v-else>
                                    <tr>
                                        <td colspan="4" class="text-center">No data found</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>


                        <div class="mt-6 mb-2 w-full row justify-between px-4 pb-4">
                            <div class="lg:w-1/2 w-full flex items-center lg:mt-2 mt-5">
                                <p class="dataset-info ">Showing {{
                                    questionnaires_list.length > 0 ? 1 : 0 }} to
                                    {{ questionnaires_list.length }} of {{ questionnaires_list.length }} entries
                                </p>
                            </div>
                            <div class="lg:w-1/2 w-full flex lg:mt-2 mt-5">
                                <div class="w-auto max-w-full inline-flex items-center lg:float-right lg:mr-0 mx-auto">
                                    <vue-awesome-paginate class="paginate-container float-right "
                                        :total-items="questionnaires_list.length" :items-per-page="per_page"
                                        :max-pages-shown="3" :show-ending-buttons="true" v-model="currentPage"
                                        :on-click="onClickHandler">

                                        <template #prev-button>
                                            <font-awesome-icon icon="fa-solid fa-angle-left" />
                                        </template>
                                        <template #next-button>
                                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                                        </template>
                                        <template #first-page-button>
                                            <font-awesome-icon icon="fa-solid fa-angles-left" />
                                        </template>
                                        <template #last-page-button>
                                            <font-awesome-icon icon="fa-solid fa-angles-right" />
                                        </template>

                                    </vue-awesome-paginate>
                                </div>
                            </div>
                        </div>

                    </CardBody>
                    <!-- <template #cardContent>
                    </template> -->
                </CardPartial>

            </div>
            <!-- </template> -->
        </OverlayLoading>
    </div>
</template>

<script>

// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';
// // import AudioRecorder from 'vue-audio-recorder'
import { inject } from 'vue'
import { initFlowbite } from 'flowbite'
import axios from 'axios';
import moment from 'moment'

export default {
    name: "QuestionnaireList",
    components: {},
    data() {
        let temp = this;
        return {
            breadcrumb_items: [
                {
                    icon: 'fa-solid fa-house',
                    // text:'',
                    to: '/dashboard'
                },
                {
                    text: 'Questionnaires'
                }
            ],

            isDark: inject('isDark'),
            is_loading: false,

            search: null,

            currentPage: 1,
            per_page: 20,

            questionnaires_list: [
                // { title: 'Form 1', created_at: new Date(), section: "Situation analysis" }
                // { "title": "SaaS Project Title", "audit_by": "Sara", "created_on": "18 Apr 2024", "status": "success", "status_name": "Completed" },
                // { "title": "SaaS Project Title", "audit_by": "Mohamed", "created_on": "20 Feb 2024", "status": "warning", "status_name": "Data Protection and Privacy" },
                // { "title": "SaaS Project Title", "audit_by": "Kim", "created_on": "2 Jan 2024", "status": "danger", "status_name": "Software Code Risk Assessment" },
            ],

            url: window.location.origin
        }
    },
    mounted() {
        // setTimeout(() => {
        //     initFlowbite();
        // }, 1500);
        this.is_loading = true
        this.getQuestionnairesList()

    },
    beforeUnmount() {
        // this.stopRecording(); // Cleanup resources before component unmounts
    },
    updated() {
    },
    watch: {
    },
    methods: {
        updateSearchFilterValue(newval) {
            console.log("list -- updateSearchFilterValue", newval)
            this.search = newval
        },
        changePage(val) {
            console.log("changepage - list", val)
            this.currentPage = val
        },
        getQuestionnairesList() {
            axios
                .get("forms/list?per_page=" + this.per_page, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: 'PROTOSTARS',
                        Authorization:
                            "Bearer " +
                            JSON.parse(window.localStorage.getItem("user")).access_token,
                    },
                })
                .then(async (response) => {
                    console.log("Response")
                    console.log(response);
                    console.log(response.data);

                    this.questionnaires_list = response.data

                    this.num_questionnaires = this.questionnaires_list ? this.questionnaires_list.length : 0

                    setTimeout(() => {
                        initFlowbite();
                    }, 1500);

                    this.is_loading = false
                })
                .catch((error) => {
                    console.log(error)
                    this.is_loading = false;
                    // let error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    // if (error_message == null || error_message == '') {
                    //     error_message = 'Sorry, '
                    // }
                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }
                    this.$swal({
                        // title: "Title",
                        text: error_message,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-grey-protostars",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });


        },
        clickAddQuestionnaire() {
            console.log("add form")
            this.$router.push("/questionnaires/create_questionnaire")
        },

        formatDate(date) {
            return moment(date).format("DD MMM YYYY")
        },
        onClickHandler() {
            console.log("click page", this.currentPage)

        }
    }
}
</script>
