<template>
    <div class="h-full px-4">
        <BreadcrumbComponent class="px-3 md:px-4 xl:px-5 py-1" :items="breadcrumb_items" />


        <div class="row h-full mt-4 px-3 md:px-4 xl:px-5 ">
            <div class="w-full  py-2 px-1">
                <div class="h-full">
                    <h2 class="page-title">Link Social Engineering Audit Accounts
                    </h2>
                    <p class="page-description">
                        Protostars B2B SaaS integrations work by using APIs and requires little to no programming. APIs
                        enable
                        SaaS applications to exchange information by using predetermined rules and protocols. APIs
                        authenticate
                        applications to establish trust before authorizing any data transfer.</p>

                    <CardPartial class="card-form-container-protostars mb-2 "
                        :card_class="' mx-auto lg:w-7/12 md:w-9/12 sm:w-10/12 w-11/12 p-4 mt-8'"
                        >
                        <CardHeader :header_class="'w-full mx-auto !pb-0'">
                            <h2 class="card-title">Social Media Integrations</h2>
                        </CardHeader>
                        <CardBody >
                            <Vueform add-class="vueFormContainer" :float-placeholders="false" v-model="formData"
                            validate-on="change|step" class=" w-full mx-auto" ref="auditFormRef">
                           

                            <div class="flex justify-between w-full col-span-12 my-1">
                                <div class="flex justify-start my-auto">
                                    <img src="@/assets/images/linkedin-icon.png" class="w-5 h-5" />
                                    <p class="ml-4 font-Poppins font-normal text-base/normal leading-5 text-grey-protostars-400 dark:text-grey-protostars-100 ">
                                        LinkedIn</p>
                                </div>
                                <button class="btn-protostars btn-primary !min-w-6  h-8 w-28 text-base/5 py-1">
                                    Link</button>

                            </div>

                            <div class="flex justify-between w-full col-span-12 my-1">
                                <div class="flex justify-start my-auto">
                                    <img src="@/assets/images/facebook-icon.png" class="w-5 h-5" />
                                    <p class="ml-4 font-Poppins font-normal text-base/normal leading-5 text-grey-protostars-400 dark:text-grey-protostars-100 ">
                                        Facebook</p>
                                </div>
                                <button class="btn-protostars btn-primary !min-w-6  h-8 w-28 text-base/5 py-1">
                                    Link</button>

                            </div>
                            <div class="flex justify-between w-full col-span-12 my-1">
                                <div class="flex justify-start my-auto">
                                    <img src="@/assets/images/instagram-icon.png" class="w-5 h-5" />
                                    <p class="ml-4 font-Poppins font-normal text-base/normal leading-5 text-grey-protostars-400 dark:text-grey-protostars-100 ">
                                        Instagram</p>
                                </div>
                                <button class="btn-protostars btn-primary !min-w-6  h-8 w-28 text-base/5 py-1">
                                    Link</button>

                            </div>
                            <div class="flex justify-between w-full col-span-12 my-1">
                                <div class="flex justify-start my-auto">
                                    <img src="@/assets/images/twitter-icon.png" class="w-5 h-5" />
                                    <p class="ml-4 font-Poppins font-normal text-base/normal leading-5 text-grey-protostars-400 dark:text-grey-protostars-100 ">
                                        X</p>
                                </div>
                                <button class="btn-protostars btn-primary !min-w-6  h-8 w-28 text-base/5 py-1">
                                    Link</button>

                            </div>
                            <div class="flex justify-between w-full col-span-12 my-1">
                                <div class="flex justify-start my-auto">
                                    <img src="@/assets/images/tiktok-icon.png" class="w-5 h-5" />
                                    <p class="ml-4 font-Poppins font-normal text-base/normal leading-5 text-grey-protostars-400 dark:text-grey-protostars-100 ">
                                        Tiktok</p>
                                </div>
                                <button class="btn-protostars btn-primary !min-w-6  h-8 w-28 text-base/5 py-1">
                                    Link</button>

                            </div>
                            <div class="flex justify-between w-full col-span-12 my-1">
                                <div class="flex justify-start my-auto">
                                    <img src="@/assets/images/snapchat-icon.png" class="w-5 h-5" />
                                    <p class="ml-4 font-Poppins font-normal text-base/normal leading-5 text-grey-protostars-400 dark:text-grey-protostars-100 ">
                                        Snapchat</p>
                                </div>
                                <button class="btn-protostars btn-primary !min-w-6  h-8 w-28 text-base/5 py-1">
                                    Link</button>

                            </div>



                            <!-- <TextElement name="github" placeholder="Repository Link" :field-name="'Title'" rules="required"
                                class="mb-1">
                                <template #addon-before>
                                    <img src="@/assets/images/protostars/github-icon.png" width="20" height="20" />
                                </template>
                            </TextElement>


                            <TextElement name="bitbucket" placeholder="Repository Link" :field-name="'Title'"
                                rules="required" class="mb-1">
                                <template #addon-before>
                                    <img src="@/assets/images/protostars/bitbucket-icon.png" width="20" height="20" />
                                </template>
                            </TextElement>
                            <TextElement name="amazon" placeholder="Repository Link" :field-name="'Title'" rules="required"
                                class="mb-1">
                                <template #addon-before>
                                    <img src="@/assets/images/protostars/aws-icon.png" width="20" height="20" />
                                </template>
                            </TextElement>
                            <TextElement name="google" placeholder="Repository Link" :field-name="'Title'" rules="required"
                                class="mb-1">
                                <template #addon-before>
                                    <img src="@/assets/images/protostars/google-icon.png" width="20" height="20" />
                                </template>
                            </TextElement> -->


                        </Vueform>

                        <div class="w-full mx-auto mb-4 mt-8 flex justify-between">
                            <button class="btn-protostars btn-secondary-outline dark:btn-secondary" @click="clickCancel">
                                Cancel</button>
                            <button class="btn-protostars btn-primary  " @click="clickCreate">
                                Create Audit</button>

                        </div>
                        </CardBody>
                    </CardPartial>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';
// // import AudioRecorder from 'vue-audio-recorder'
// import { initFlowbite } from 'flowbite'
// import axios from 'axios';

export default {
    name: "CreateAudit",
    components: {},
    data() {
        let temp = this;
        return {
            formData: null,

            breadcrumb_items: [
                {
                    icon: 'fa-solid fa-house',
                    // text:'',
                    to: '/dashboard'
                },
                {
                    text: 'Audits',
                    to: '/audits/list'
                },
                {
                    text: 'Create Audit'
                }
            ],

        }
    },
    mounted() {
        // setTimeout(() => {
        //     initFlowbite();
        // }, 1500);

        console.log(this.$refs.auditFormRef)

        window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    beforeUnmount() {
        // this.stopRecording(); // Cleanup resources before component unmounts
    },
    updated() {
    },
    watch: {
    },
    methods: {
        clickCancel() {
            this.$router.push('/audits/create_audit')
        },
        clickCreate() {
            this.$router.push('/audits/create_audit/in_progress/'+this.$route.params.id)
        },
    }
}
</script>
