<template>
    <nav class="bg-white h-20 dark:bg-black-protostars fixed w-full z-20 top-0 border-0 dark:border-gray-600 shadow-lg">
        <!-- <nav class="bg-white border-gray-200 dark:bg-gray-900"> -->
        <div class="max-w-screen-xl min-w-full h-full flex flex-wrap items-center justify-end mx-auto p-4">
            <!-- <a href="/situation_analysis" class="flex items-center space-x-3 rtl:space-x-reverse">
                <img src="@/assets/images/protostars/logo.png" class="h-8" alt="Protostars Logo" />
            </a> -->
            <div class="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                <button class="text-gray-500 dark:text-gray-200 mx-2 " @click="clickToggleDark()">
                    <span v-if="!isDark"><font-awesome-icon icon="fa-solid fa-moon" /></span>
                    <span v-else><font-awesome-icon icon="fa-solid fa-sun" /></span>
                </button>
                <button type="button" class="flex text-sm bg-transparent   md:me-0 " id="user-menu-button"
                    aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
                    <span class="sr-only">Open user menu</span>
                    <!-- <div
                        class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-[#d9d9d9] rounded-full dark:bg-gray-600">
                        <span class="font-semibold text-lg bg-transparent uppercase">{{ user_chars }}</span>
                    </div> -->
                    <div
                        class="relative inline-flex items-center justify-center h-10 overflow-hidden text-black-protostars dark:text-white text-lg font-semibold capitalize ml-2">
                        {{ user_name }} 
                    </div>
                </button>
                <!-- Dropdown menu -->
                <div class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                    id="user-dropdown">
                    <div class="px-4 py-3">
                        <span class="block text-sm text-gray-900 dark:text-white capitalize">{{ user_name }}</span>
                        <span class="block text-sm  text-gray-500 truncate dark:text-gray-400">{{ user_email }}</span>
                    </div>
                    <ul class="py-2" aria-labelledby="user-menu-button">
                        <!-- <li>
                            <a href="#"
                                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Dashboard</a>
                        </li>
                        <li>
                            <a href="#"
                                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Settings</a>
                        </li>-->
                        <li>
                            <a href="/change_password"
                                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Change Password</a>
                        </li> 
                        <li>
                            <a @click="clickLogout"
                                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Logout</a>
                        </li>
                    </ul>
                </div>
                <button data-collapse-toggle="navbar-user" type="button"
                    class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    aria-controls="navbar-user" aria-expanded="false">
                    <span class="sr-only">Open main menu</span>
                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
            </div>
            <!-- <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-user">
                <ul
                    class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-transparent md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                    
                   
                    <li>
                        <a href="#"
                            class="block py-2 px-3 text-gray-900 rounded md:bg-transparent  md:hover:text-yellow-protostars md:p-0 md:dark:text-blue-500"
                            aria-current="page">Wishlist</a>
                    </li>
                    <li>
                        <a href="#"
                            class="block py-2 px-3 text-gray-900 rounded  md:hover:bg-transparent md:hover:text-yellow-protostars md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Profile</a>
                    </li>
                    <li>
                        <a href="#"
                            class="block py-2 px-3 text-gray-900 rounded  md:hover:bg-transparent md:hover:text-yellow-protostars md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Logout</a>
                    </li>
                </ul>
            </div> -->
        </div>
    </nav>
</template>

<script>

export default {
    name: 'NavbarProtostars',
    components: {

    },
    props:['isDark'],
    data() {
        return {
            user_name: null,
            user_chars: null,
            user_email: null,

        }
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem("user"))
        this.user_name = user.first_name + ' ' + user.last_name
        this.user_chars = user.first_name.charAt(0) + user.last_name.charAt(0)
        this.user_email = user.email

    },
    methods: {
        clickLogout() {
            localStorage.clear()
            this.$router.go('/protostars/login')
        },
        clickToggleDark(){
            console.log(this.isDark)
            this.$emit('updateIsDark',)
        }
    }
}
</script>

<style></style>
