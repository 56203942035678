<template>
    <div class="h-full">
        <!-- <navbar-protostars></navbar-protostars> -->

        <div class="row h-full mt-5">
            <div class="2xl:w-10/12 xl:w-9/12 lg:w-9/12 md:w-9/12 w-full  px-1 " ref="canvasContainerRef">
                <div class="h-full">
                    <h2 class="text-3xl leading-normal font-bold text-[#293042] mx-3">Canvas</h2>
                    <p class="text-base leading-normal font-normal text-[#696682] mx-3">The value proposition canvas will
                        help you design a SaaS product that your Customers want. This is a
                        tool to intentionally visualise how you design, build and test value for Customers. Protostars.ai
                        will use this as a means to define your technical scope of works. </p>
                    <div class="w-full " ref="canvas1"></div>

                    <!-- <b-card no-body class="my-1" v-if="clicked_card"
                        style="border-radius: 8px; border: 2px solid #1e1e1e; padding: : 15px; width: 50%; margin: auto">
                        <b-card-header class="p-0" style="display: inline; background: transparent; border: none;">
                            <b-button
                                v-if="clicked_card_type == 'job' || clicked_card_type == 'gain' || clicked_card_type == 'pain'"
                                class="p-0 float-right" variant="link"
                                @click="clickDeleteCard(clicked_card_type, clicked_card_index)"> <img
                                    src="@/assets/images/delete-icon.png" width="30" /> </b-button>
                        </b-card-header>
                        <b-card-body class="pt-1">
                            <b-card-text class="mt-2 mb-0"> You clicked on the {{ clicked_card_type }}: {{ clicked_card
                            }}</b-card-text>
                            <ul class="m-0">
                                <li v-for="(item, index) in clicked_card_items " :key="index">
                                    {{ item }}

                                </li>
                            </ul>
                        </b-card-body>
                    </b-card> -->

                    <div class="card my-1 rounded-lg border-2 border-[#1e1e1e] p-4 w-1/2 m-auto" v-if="clicked_card">
                        <div class="w-full flex justify-end">
                            <button
                                v-if="clicked_card_type == 'job' || clicked_card_type == 'gain' || clicked_card_type == 'pain'"
                                class="btn btn-link p-0 float-right"
                                @click="clickDeleteCard(clicked_card_type, clicked_card_index)"><img
                                    src="@/assets/images/delete-icon.png" width="30" />

                            </button>
                        </div>
                        <div class="p-2">
                            <p class="mt-2 mb-0"> You clicked on the {{ clicked_card_type }}: {{ clicked_card }}</p>
                            <ul class="m-0">

                                <li v-for="(item, index) in clicked_card_items " :key="index">
                                    {{ item }}

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="2xl:w-2/12 xl:2-3/12 lg:w-3/12 md:w-3/12  w-full px-0 h-full bg-white
                    flex items-center fixed rounded-l-[40px] top-0 right-0 left-auto border border-solid border-gray-150">

                <div class="w-full">
                    <button class="btn btn-protostars btn-success block w-full my-2 mx-auto"
                        :disabled="customer_jobs.length >= 5" @click="addJob">Add Job</button>
                    <button class="btn btn-protostars btn-primary block w-full my-2 mx-auto" :disabled="gains.length >= 5"
                        @click="addGain">Add Gain</button>
                    <button class="btn btn-protostars btn-warning block w-full my-2 mx-auto" :disabled="pains.length >= 5"
                        @click="addPain">Add Pain</button>
                </div>


                <!-- <b-col xl="2" lg="3" md="3" class="d-flex align-items-center position-fixed h-100" 
                style="background-color: #fff; border-radius: 40px 0 0 40px; border-left: 2px solid #eee;
                top:0; right:0">
                <div class="w-100">
                    <b-button variant="success" class="d-block w-100 my-2" :disabled="customer_jobs.length >= 5"
                        @click="addJob">Add Job</b-button>
                    <b-button variant="primary" class="d-block w-100 my-2" :disabled="gains.length >= 5"
                        @click="addGain">Add Gain</b-button>
                    <b-button variant="warning" class="d-block w-100 my-2" :disabled="pains.length >= 5"
                        @click="addPain">Add Pain</b-button>
                </div>
            </b-col> -->
            </div>

        </div>
        <!-- <canvas ref="canvas1" width="500" height="800"></canvas> -->
        <!---->
        <!-- <b-row class="mx-0 h-100">
            <b-col xl="10" lg="9" md="9" class="px-0" ref="canvasContainerRef">
                <div class="h-100  ">
                    <h2 class="mx-3">Canvas</h2>
                    <p class="mx-3">The value proposition canvas will help you design a SaaS product that your Customers
                        want. This is a
                        tool to intentionally visualise how you design, build and test value for Customers. Protostars.ai
                        will use this as a means to define your technical scope of works. </p>
                    
                </div>

                <div>

                </div>
            </b-col>
           
        </b-row> -->


    </div>
</template>

<script>
// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';

import $ from 'jquery'
import p5 from "p5";
let p5Instance;
export default {
    name: "CanvasView",
    components: {  },
    data() {
        let temp = this;
        return {
            canvas_width: 800,
            canvas_height: 600,

            right_margin: 50,
            left_margin: 50,
            space_between_cards: this.canvas_width * 0.045,
            diameter: this.canvas_height / 2,
            note_rect_size: (this.diameter * 0.3),//(this.diameter * 0.16),
            font_size: 8,//this.canvas_width * 0.0175,

            customer_jobs: [
                { id: 1, name: 'Plan class and reading schedule on a day-to-day basis ' },
                { id: 2, name: 'Communicate with faculty and peers' },
                { id: 3, name: 'Submit assignments on time' }],
            //, 'job 6', 'job 7', 'job 8']
            // 'job 9', 'job 10']
            // , 'job 11', 'job 12',
            // 'job 13', 'job 14', 'job 15', 'job 16',
            // 'job 17', 'job 18', 'job 19', 'job 20',]
            // jobs_positions: [],

            gains: [{ id: 1, name: 'Easy-to-navigate safe learning environment' },
            { id: 2, name: 'Have a centralized source of information' },
            { id: 3, name: 'Organization' },
                // { id: 4, name: 'gain 4' },
                // { id: 5, name: 'gain 5' }
            ],
            // gains_positions: [],

            pains: [{ id: 1, name: 'No direct communication' },
            { id: 2, name: 'a lot of information needs to be consumed in a short time ' },
            { id: 3, name: 'lack of integration across courses and schools' },
                // { id: 4, name: 'pain 4' },
                // { id: 5, name: 'pain 5' }
            ],
            // pains_positions: [],

            gain_creators: [
                {
                    id: 1, name: 'Automated Invoice URL send via email',
                    gains: [{ id: 3, name: 'Organization' }],
                    // pains: [{ id: 4, name: 'pain 4' }],
                    relations: [{ id: 3, name: 'Organization', type: 'gain' },
                        // { id: 4, name: 'pain 4', type: 'pain' }
                    ]
                },
                // { id: 2, name: 'gain creator 2' },
                // {
                //     id: 3, name: 'gain creator 3',
                //     gains: [{ id: 3, name: 'gain 3' }],
                //     pains: [{ id: 4, name: 'pain 4' }],
                //     relations: [{ id: 3, name: 'gain 3', type: 'gain' },
                //     { id: 4, name: 'pain 4', type: 'pain' }]
                // },
                // {
                //     id: 4, name: 'gain creator 4',
                //     gains: [{ id: 4, name: 'gain 4' },
                //     { id: 5, name: 'gain 5' }],
                //     relations: [{ id: 5, name: 'gain 5', type: 'gain' },
                //     { id: 4, name: 'gain 4', type: 'gain' }]
                // },
                // { id: 5, name: 'gain creator 5' }
            ],
            gain_creators_vertex: [],
            gain_creators_positions: [],

            pain_relievers: [
                {
                    id: 1, name: 'Enable direct communication',
                    pains: [{id: 1, name: 'No direct communication' }],
                    relations: [{ id: 1, name: 'No direct communication', type: 'pain' },
                    // { id: 4, name: 'pain 4', type: 'pain' }
                ]
                },
                // { id: 2, name: 'pain reliever 2' },
                // { id: 3, name: 'pain reliever 3' },
                // {
                //     id: 4, name: 'pain reliever 4',
                //     pains: [{ id: 3, name: 'pain 3' }, { id: 5, name: 'pain 5' }],
                //     relations: [{ id: 3, name: 'pain 3', type: 'pain' },
                //     { id: 5, name: 'pain 5', type: 'pain' }]
                // },
                // { id: 5, name: 'pain reliever 5' },
            ],
            pain_relievers_vertex: [],
            pain_relievers_positions: [],

            relations_cards_id: [],

            product_services: [
                {
                    id: 1, name: 'Messaging/inbox functionality',
                    jobs: [{ id: 2, name: 'Communicate with faculty and peers'  }],
                    relations: [{  id: 2, name: 'Communicate with faculty and peers' , type: 'job' },
                    // { id: 4, name: 'job 4', type: 'job' }
                ]
                },
                // { id: 2, name: 'Service 2' },
                // { id: 3, name: 'Service 3' },
                // {
                //     id: 4, name: 'Service 4',
                //     jobs: [{ id: 4, name: 'job 4' }, { id: 5, name: 'job 5' }],
                //     relations: [{ id: 4, name: 'job 4', type: 'job' },
                //     { id: 5, name: 'job 5', type: 'job' }]
                // },
                // { id: 5, name: 'Service 5' },
            ],
            product_services_positions: [],

            cards_positions: [],

            clicked_card: null,
            clicked_card_type: null,
            clicked_card_index: null,
            clicked_card_items: [],

            card_types_key: [
                { name: "job", key: "job", is_deletable: true },
                { name: "gain", key: "gain", is_deletable: true },
                { name: "pain", key: "pain", is_deletable: true },
                { name: "product/service", key: "product", is_deletable: false },
                { name: "gain creator", key: "gain_creator", is_deletable: false },
                { name: "pain reliever", key: "pain_reliever", is_deletable: false },
            ]



        }
    },
    mounted() {
        window.addEventListener("resize", this.resizeScreen);

        console.log(this.$router)

        // $('body').addClass('background-body')

        // const sketch = function (p5) {
        //     p5.setup(() => {
        //         p5.createCanvas(400, 400);
        //     });

        //     p5.draw(() => {
        //         p5.background(100);
        //         p5.fill(0);
        //         p5.text("Hello, world!", 200, 200);
        //     });
        // }
        //  new p5(sketch, "canvas");
        // // p5Instance.background(255);
        // // p5Instance.fill(0);
        // // p5Instance.text("Hello, world!", 200, 200);

        this.setupDimensions()
        this.drawCanvas()


    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        setupDimensions() {
            console.log(this.$refs.canvasContainerRef)
            console.log(this.$refs.canvasContainerRef.offsetWidth)
            console.log(this.$refs.canvasContainerRef.offsetHeight)

            this.canvas_width = this.$refs.canvasContainerRef.offsetWidth
            console.log(this.canvas_width * 0.75, this.$refs.canvasContainerRef.offsetHeight)
            this.canvas_height = this.canvas_width * 0.75
            // this.canvas_height = this.canvas_height - 100


            // if (this.canvas_width * 0.75 > this.$refs.canvasContainerRef.offsetHeight) {
            //     this.canvas_height = this.$refs.canvasContainerRef.offsetHeight - 100
            //     // this.canvas_width = this.canvas_height / 0.75
            // }

            if (this.canvas_width < 800 || this.canvas_height < 600) {
                this.canvas_width = 800
                this.canvas_height = 600
                this.right_margin = 50
                this.left_margin = 50
            } else {
                this.right_margin = 80
                this.left_margin = 75
            }
            // ? this.$refs.canvasContainerRef.offsetHeight : this.canvas_width * 0.75  //this.$refs.canvasContainerRef.offsetHeight


            this.space_between_cards = this.canvas_width * 0.045
            this.diameter = this.canvas_height / 2
            this.note_rect_size = (this.diameter * 0.16)
            // if (this.canvas_width > 1800) {
            //     this.font_size = 28
            // } else {
            this.font_size = 12//(this.canvas_width * .015)
            // }
            console.log("font size", this.font_size)
        },
        resizeScreen() {
            console.log("in resize screen", window.innerWidth, window.innerHeight)
            console.log(p5Instance)
            this.setupDimensions()
            p5Instance.setup()
            p5Instance.redraw()


        },
        drawCanvas() {
            let cnv;
            const s = (sketch) => {
                let temp = this
                sketch.setup = () => {
                    console.log("dimensions: ", this.canvas_width, this.canvas_height)
                    cnv = sketch.createCanvas(this.canvas_width, (this.canvas_height / 2) + 100);
                    cnv.mouseClicked(mouseClicked);
                    sketch.angleMode(sketch.DEGREES);
                    sketch.noLoop()
                };

                sketch.draw = () => {
                    console.log("dimensions: ", this.canvas_width, this.canvas_height)

                    sketch.background("#0000");

                    sketch.textFont("'Inter', sans-serif");

                    sketch.strokeWeight(3)


                    temp.cards_positions = []
                    temp.relations_cards_id = []

                    // draw customer profile 
                    drawCustomerProfile()

                    //draw value map
                    drawValueMap()

                    drawSimpleArrow(sketch.createVector(temp.diameter + temp.left_margin, (sketch.height / 2)),
                        sketch.createVector(sketch.width / 2, sketch.height / 2), "")
                    drawSimpleArrow(
                        sketch.createVector(temp.canvas_width - temp.right_margin - 12.5 - temp.diameter,
                            sketch.height / 2),
                        sketch.createVector(sketch.width / 2, sketch.height / 2), "")

                    // draw customer jobs
                    drawCustomerJobs()

                    // draw customer gains
                    drawGains()

                    // draw customer pains
                    drawPains()

                    // draw gain creators
                    drawGainCreators()

                    // draw pain relievers
                    drawPainRelievers()

                    // draw product & services
                    drawProductAndServices()

                    // 
                    // drawRelationBetweenGains()
                    // drawRelationBetweenPains()
                    // drawRelationBetweenJobs()
                    drawRelationsBetweenCustomerProfileAndValueMAp()

                    // console.log("cards length", cards_positions.length)
                    for (let item in temp.cards_positions) {
                        // console.log(temp.cards_positions[item])
                    }
                };
                // sketch.mouseClicked = () => {
                //     console.log("click mouse    --- ", sketch.mouseX, sketch.mouseY, sketch.width / 2, sketch.height / 2)
                //     mouseClicked()
                // };

                function drawCustomerProfile() {
                    let angles = [120, 120, 120]
                    let lastAngle = 60;
                    for (let i = 0; i < angles.length; i++) {
                        sketch.noFill()
                        sketch.stroke('#293042')
                        sketch.arc(
                            temp.canvas_width - temp.right_margin - temp.diameter / 2,
                            sketch.height / 2,
                            temp.diameter + 25,
                            temp.diameter + 25,
                            lastAngle,
                            lastAngle + (angles[i]),
                            sketch.PIE
                        );
                        // console.log("angles", lastAngle,
                        // lastAngle + (angles[i]))

                        lastAngle += (angles[i]);
                        // // console.log(lastAngle)
                    }

                    // draw text (Jobs, Gains, Pains)
                    sketch.fill('#000')
                    sketch.noStroke()
                    sketch.textSize(temp.font_size)
                    sketch.push()
                    sketch.translate(sketch.width - temp.right_margin - sketch.textAscent(), sketch.height / 2)
                    sketch.rotate((90));
                    sketch.textAlign(sketch.CENTER);
                    sketch.text("Customer Jobs", 0, 0);
                    sketch.translate(0, 0)
                    sketch.pop()

                    sketch.textAlign(sketch.CENTER);
                    sketch.text("Gains", sketch.width - temp.right_margin - sketch.textAscent() - temp.diameter / 2, sketch.height / 2 - sketch.textAscent());

                    sketch.textAlign(sketch.CENTER);
                    sketch.text("Pains", sketch.width - temp.right_margin - sketch.textAscent() - temp.diameter / 2, sketch.height / 2 + sketch.textAscent());
                }
                function drawValueMap() {

                    temp.pain_relievers_vertex = []
                    temp.gain_creators_vertex = []

                    sketch.strokeWeight(3)
                    sketch.stroke('#293042')
                    sketch.noFill()

                    sketch.rect(temp.left_margin, (sketch.height / 2) - (temp.diameter / 2), temp.diameter)

                    sketch.line((temp.diameter / 2) + temp.left_margin, sketch.height / 2, temp.left_margin,
                        (sketch.height / 2) - (temp.diameter / 2))

                    sketch.line((temp.diameter / 2) + temp.left_margin, sketch.height / 2, temp.left_margin,
                        (sketch.height / 2) + temp.diameter / 2)

                    sketch.line((temp.diameter / 2) + temp.left_margin, sketch.height / 2,
                        temp.diameter + temp.left_margin,
                        (sketch.height / 2))


                    temp.pain_relievers_vertex.push({
                        x: (temp.diameter / 2) + temp.left_margin,
                        y: sketch.height / 2
                    })
                    temp.pain_relievers_vertex.push({
                        x: temp.diameter + temp.left_margin,
                        y: sketch.height / 2
                    })
                    temp.pain_relievers_vertex.push({
                        x: temp.diameter + temp.left_margin,
                        y: (sketch.height / 2) + temp.diameter / 2
                    })
                    temp.pain_relievers_vertex.push({
                        x: temp.left_margin,
                        y: (sketch.height / 2) + temp.diameter / 2
                    })

                    temp.gain_creators_vertex.push({
                        x: (temp.diameter / 2) + temp.left_margin,
                        y: sketch.height / 2
                    })
                    temp.gain_creators_vertex.push({
                        x: temp.diameter + temp.left_margin,
                        y: sketch.height / 2
                    })
                    temp.gain_creators_vertex.push({
                        x: temp.diameter + temp.left_margin,
                        y: (sketch.height / 2) - temp.diameter / 2
                    })
                    temp.gain_creators_vertex.push({
                        x: temp.left_margin,
                        y: (sketch.height / 2) - temp.diameter / 2
                    })

                    sketch.fill('#000')
                    sketch.noStroke()
                    sketch.textSize(temp.font_size)

                    sketch.textAlign(sketch.CENTER);
                    sketch.text("Gain Creators", temp.left_margin + temp.diameter / 2,
                        sketch.height / 2 - temp.diameter / 2 + sketch.textAscent());

                    sketch.textSize(temp.font_size)
                    sketch.text("Pain Relievers", temp.left_margin + temp.diameter / 2,
                        sketch.height / 2 + temp.diameter / 2 - sketch.textAscent());

                    sketch.push()
                    sketch.textSize(temp.font_size)
                    sketch.translate(temp.left_margin + sketch.textAscent(), sketch.height / 2)
                    sketch.rotate((270));
                    sketch.textAlign(sketch.CENTER);
                    sketch.text("Product & Services", 0, 0);
                    sketch.translate(0, 0)
                    sketch.pop()

                }

                function drawCustomerJobs() {
                    // temp.jobs_positions = []

                    sketch.stroke('#00FFAD')
                    sketch.strokeWeight(1)
                    sketch.fill('#00FFAD')

                    let start_angle_diff = 0, angle_step = 0
                    if (temp.customer_jobs.length == 4) {
                        start_angle_diff = 5
                        angle_step = 10
                    } else if (temp.customer_jobs.length == 3 || temp.customer_jobs.length == 2) {
                        start_angle_diff = 15
                        angle_step = 30
                    }
                    else if (temp.customer_jobs.length == 1) {
                        start_angle_diff = 45
                        angle_step = 60
                    }

                    for (var i = 315 + start_angle_diff, j = 0; j < temp.customer_jobs.length; j++, i = i + 30 + angle_step) {
                        var xi = (temp.canvas_width - temp.right_margin - temp.diameter / 2) + sketch.cos(i)
                            * ((temp.diameter / 2) - temp.space_between_cards);
                        var yi = sketch.height / 2 + sketch.sin(i) * ((temp.diameter / 2) - temp.space_between_cards);

                        if (temp.customer_jobs.length == 1) {
                            xi = (temp.canvas_width - temp.right_margin - temp.diameter / 2) + sketch.cos(i)
                                * ((temp.diameter / 3) - temp.space_between_cards / 2);
                            yi = sketch.height / 2 + sketch.sin(i) * ((temp.diameter / 3) - temp.space_between_cards);
                        }

                        let isInJobs = isPointInArc(xi, yi, temp.canvas_width - temp.right_margin - temp.diameter / 2,
                            sketch.height / 2,
                            temp.diameter / 2,
                            300, 420)

                        if (!isInJobs) {
                            // // console.log("in if",i,j,r)
                            i = 360
                            xi = (temp.canvas_width - temp.right_margin - temp.diameter / 2) + sketch.cos(i) * ((temp.diameter / 4) - temp.space_between_cards);
                            yi = sketch.height / 2 + sketch.sin(i) * ((temp.diameter / 4) - temp.space_between_cards);
                            // // console.log("in in if",i,j,r)

                        }

                        sketch.push()
                        sketch.rectMode(sketch.CENTER);
                        sketch.square(xi, yi, temp.note_rect_size, 5)

                        sketch.noStroke()
                        sketch.fill('#000')
                        sketch.textAlign(sketch.CENTER, sketch.CENTER);
                        sketch.textSize(temp.font_size)
                        // let job_text = temp.customer_jobs[j].name.length >= 15 ? temp.customer_jobs[j].name.substring(0, 12) + ' ...' : temp.customer_jobs[j].name
                        let job_text = temp.customer_jobs[j].name.length >= 30 ? temp.customer_jobs[j].name.substring(0, 27) + ' ...' : temp.customer_jobs[j].name
                        sketch.text(job_text, xi, yi, temp.note_rect_size, temp.note_rect_size)
                        sketch.pop()

                        // temp.jobs_positions.push({
                        //     id: temp.customer_jobs[j].id,
                        //     name: temp.customer_jobs[j].name,
                        //     x: xi,
                        //     y: yi
                        // })

                        temp.cards_positions.push({
                            type: "job",
                            id: temp.customer_jobs[j].id,
                            name: temp.customer_jobs[j].name,
                            index: j,
                            x: xi,
                            y: yi
                        })

                    }
                }
                function drawGains() {
                    // console.log('in draw gains')
                    // temp.gains_positions = []

                    sketch.push()
                    sketch.stroke('#61FBFE')
                    sketch.fill('#61FBFE')
                    sketch.rectMode(sketch.CENTER)

                    let start_angle_diff = 0, angle_step = 0
                    if (temp.gains.length == 4) {
                        start_angle_diff = 0
                        angle_step = 10
                    } else if (temp.gains.length == 3 || temp.gains.length == 2) {
                        start_angle_diff = 15
                        angle_step = 30
                    }
                    else if (temp.gains.length == 1) {
                        start_angle_diff = 45
                        angle_step = 60
                    }

                    for (var i = 195 + start_angle_diff, j = 0; j < temp.gains.length; j++, i = i + 30 + angle_step) {
                        // fill(i%256,i%256,i%256)
                        var xi = (temp.canvas_width - temp.right_margin - temp.diameter / 2)
                            + sketch.cos(i) * ((temp.diameter / 2) - temp.space_between_cards);
                        var yi = sketch.height / 2 + sketch.sin(i) * ((temp.diameter / 2) - temp.space_between_cards / 1.5);

                        if (temp.gains.length == 1) {
                            xi = (temp.canvas_width - temp.right_margin - temp.diameter / 2)
                                + sketch.cos(i) * ((temp.diameter / 3) - temp.space_between_cards / 2);
                            yi = sketch.height / 2 + sketch.sin(i) * ((temp.diameter / 3) - temp.space_between_cards / 2);
                        }

                        let isInGains = isPointInArc(xi, yi, temp.canvas_width - temp.right_margin - temp.diameter / 2,
                            sketch.height / 2,
                            temp.diameter / 2,
                            180, 300)
                        // console.log('in gain', isInGains)
                        // // console.log("before if",i,j,r)
                        if (!isInGains) {
                            // // console.log("in if",i,j,r)
                            i = 250
                            xi = (temp.canvas_width - temp.right_margin - temp.diameter / 2) + sketch.cos(i) * ((temp.diameter / 4) - temp.space_between_cards);
                            yi = sketch.height / 2 + sketch.sin(i) * ((temp.diameter / 4) - temp.space_between_cards / 2);
                            // // console.log("in in if",i,j,r)

                        }


                        // console.log("==>", i, xi, yi)

                        sketch.stroke('#61FBFE')
                        sketch.fill('#61FBFE')
                        sketch.square(xi, yi, temp.note_rect_size, 5)
                        sketch.push()
                        sketch.noStroke()
                        sketch.fill('#000')
                        sketch.textAlign(sketch.CENTER, sketch.CENTER);
                        // textWrap(WORD)
                        sketch.textSize(temp.font_size)
                        // let gain_text = temp.gains[j].name.length >= 15 ? temp.gains[j].name.substring(0, 12) + ' ...' : temp.gains[j].name
                        let gain_text = temp.gains[j].name.length >= 30 ? temp.gains[j].name.substring(0, 27) + ' ...' : temp.gains[j].name


                        sketch.text(gain_text, xi, yi, temp.note_rect_size, temp.note_rect_size)
                        sketch.pop()

                        // temp.gains_positions.push({
                        //     id: temp.gains[j].id,
                        //     name: temp.gains[j].name,
                        //     x: xi,
                        //     y: yi
                        // })

                        temp.cards_positions.push({
                            type: "gain",
                            id: temp.gains[j].id,
                            name: temp.gains[j].name,
                            index: j,
                            x: xi,
                            y: yi
                        })
                    }
                    sketch.pop()
                }
                function drawPains() {
                    // console.log('in draw pains')
                    // temp.pains_positions = []
                    sketch.stroke('#F6C60A')
                    sketch.fill('#F6C60A')
                    sketch.push()
                    sketch.rectMode(sketch.CENTER)

                    let start_angle_diff = 0, angle_step = 0
                    if (temp.pains.length == 4) {
                        start_angle_diff = 0
                        angle_step = 15
                    } else if (temp.pains.length == 3 || temp.pains.length == 2) {
                        start_angle_diff = 15
                        angle_step = 30
                    }
                    else if (temp.pains.length == 1) {
                        start_angle_diff = 45
                        angle_step = 60
                    }

                    for (var i = 75 + start_angle_diff, j = 0; j < temp.pains.length; j++, i = i + 30 + angle_step) {
                        // fill(i%256,i%256,i%256)
                        var xi = (temp.canvas_width - temp.right_margin - temp.diameter / 2)
                            + sketch.cos(i) * ((temp.diameter / 2) - temp.space_between_cards);
                        var yi = sketch.height / 2 + sketch.sin(i) * ((temp.diameter / 2) - temp.space_between_cards / 2);

                        if (temp.pains.length == 1) {
                            xi = (temp.canvas_width - temp.right_margin - temp.diameter / 2)
                                + sketch.cos(i) * ((temp.diameter / 3) - temp.space_between_cards);
                            yi = sketch.height / 2 + sketch.sin(i) * ((temp.diameter / 3) - temp.space_between_cards / 2);
                        }

                        let isInPains = isPointInArc(xi, yi, temp.canvas_width - temp.right_margin - temp.diameter / 2,
                            sketch.height / 2,
                            temp.diameter / 2, 180 + 240, 180 + 360)
                        //180 + 240, 180 + 360)
                        // console.log('in pain', isInGains)
                        // // console.log("before if",i,j,r)
                        if (!isInPains) {
                            // // console.log("in if",i,j,r)
                            i = 120
                            xi = (temp.canvas_width - temp.right_margin - temp.diameter / 2) + sketch.cos(i) * ((temp.diameter / 4) - temp.space_between_cards);
                            yi = sketch.height / 2 + sketch.sin(i) * ((temp.diameter / 4) - temp.space_between_cards / 2);
                            // // console.log("in in if",i,j,r)

                        }


                        // console.log("==>", i, xi, yi)
                        sketch.push()
                        sketch.rectMode(sketch.CENTER)
                        sketch.square(xi, yi, temp.note_rect_size, 5)

                        sketch.noStroke()
                        sketch.fill('#000')
                        sketch.textAlign(sketch.CENTER, sketch.CENTER);
                        // textWrap(WORD);
                        sketch.textSize(temp.font_size)
                        // let pain_text = temp.pains[j].name.length >= 15 ? temp.pains[j].name.substring(0, 12) + ' ...' : temp.pains[j].name
                        let pain_text = temp.pains[j].name.length >= 30 ? temp.pains[j].name.substring(0, 27) + ' ...' : temp.pains[j].name


                        sketch.text(pain_text, xi, yi, temp.note_rect_size, temp.note_rect_size)

                        sketch.pop()

                        // temp.pains_positions.push({
                        //     id: temp.pains[j].id,
                        //     name: temp.pains[j].name,
                        //     x: xi,
                        //     y: yi
                        // })

                        temp.cards_positions.push({
                            type: "pain",
                            id: temp.pains[j].id,
                            name: temp.pains[j].name,
                            index: j,
                            x: xi,
                            y: yi
                        })

                        // rect(xi, yi, note_rect_size, note_rect_size);

                        //      let startX = round( width/2 + 100 * cos(i),2),
                        //         startY = round(height/2 + 100 * sin(i),2)
                        //      push()
                        //      rectMode(CENTER)
                        //      rect(startX, startY, 20, 20);
                        //     pop()
                    }
                    sketch.pop()
                }

                function drawGainCreators() {
                    // console.log("in draw gain creators")

                    temp.gain_creators_positions = []

                    sketch.stroke('#61FBFE')
                    sketch.fill('#61FBFE')
                    sketch.push()
                    sketch.rectMode(sketch.CENTER)
                    for (let i = 0, c = 0, j = 0; i < temp.gain_creators.length; i++) {
                        // console.log(gain_creators[i])

                        let x = temp.diameter + temp.left_margin - temp.note_rect_size
                            - (c) * (temp.note_rect_size + temp.space_between_cards - 15) - (c * 5)
                        let y = sketch.height / 2 - temp.note_rect_size / 1.25
                            - (j) * (temp.note_rect_size + 8) - 6 * j

                        //   push()
                        // fill("#f00")
                        // circle(x,y, 20)
                        //   // rectMode(CENTER)
                        //   // rect(x, y, note_rect_size+10, note_rect_size+10, 5)
                        //   pop()

                        // console.log("poly", pointInPoly(gain_creators_vertex, { x: x, y: y }))
                        let inPoly = pointInPoly(temp.gain_creators_vertex, { x: x, y: y })
                        if (!inPoly) {
                            j++;
                            c = 0

                            x = temp.diameter + temp.left_margin - temp.note_rect_size
                                - (c) * (temp.note_rect_size + temp.space_between_cards - 15) - (c * 5)
                            y = sketch.height / 2 - temp.note_rect_size / 1.25
                                - (j) * (temp.note_rect_size + 8) - 6 * j
                        }

                        sketch.push()
                        sketch.rect(x, y, temp.note_rect_size, temp.note_rect_size, 5)

                        sketch.noStroke()
                        sketch.fill('#000')
                        sketch.textAlign(sketch.CENTER, sketch.CENTER);
                        // textWrap(WORD);
                        sketch.textSize(temp.font_size)
                        let gain_text = temp.gain_creators[temp.gain_creators.length - i - 1].name
                        // gain_text = gain_text.length >= 15 ? gain_text.substring(0, 12) + ' ...' : gain_text
                        gain_text = gain_text.length >= 30 ? gain_text.substring(0, 27) + ' ...' : gain_text

                        // temp.gain_creators[temp.gain_creators.length - i - 1].name.length >= 15 ? temp.gain_creators[temp.gain_creators.length - i - 1].name.substring(0, 12) + ' ...' : temp.gain_creators[temp.gain_creators.length - i - 1].name

                        sketch.text(gain_text,
                            x, y, temp.note_rect_size, temp.note_rect_size)
                        sketch.pop()

                        c++;

                        temp.gain_creators_positions.push(
                            {
                                id: temp.gain_creators[temp.gain_creators.length - i - 1].id,
                                name: temp.gain_creators[temp.gain_creators.length - i - 1].name,
                                x: x,
                                y: y
                            }
                        )

                        temp.cards_positions.push({
                            type: "gain_creator",
                            id: temp.gain_creators[temp.gain_creators.length - i - 1].id,
                            name: temp.gain_creators[temp.gain_creators.length - i - 1].name,
                            index: temp.gain_creators.length - i - 1,
                            x: x,
                            y: y
                        })

                        // circle(x,y, 20)

                    }
                    sketch.pop()
                }
                function drawPainRelievers() {
                    // console.log("in draw pain relievers")

                    temp.pain_relievers_positions = []

                    sketch.stroke('#F6C60A')
                    sketch.fill('#F6C60A')
                    sketch.push()
                    sketch.rectMode(sketch.CENTER)
                    for (let i = 0, c = 0, j = 0; i < temp.pain_relievers.length; i++) {
                        // console.log(pain_relievers[i])

                        let x = temp.diameter + temp.left_margin - temp.note_rect_size
                            - (c) * (temp.note_rect_size + temp.space_between_cards - 15) - (c * 5)
                        let y = sketch.height / 2 + temp.note_rect_size / 1.25
                            + (j) * (temp.note_rect_size + 8) + 6 * j

                        // push()
                        // rectMode(CENTER)
                        // pop()

                        // fill('#f00')
                        // circle(x, y, 10)
                        // console.log("poly", pointInPoly(pain_relievers_vertex, { x: x, y: y }))
                        let inPoly = pointInPoly(temp.pain_relievers_vertex, { x: x, y: y })
                        if (!inPoly) {
                            j++;
                            c = 0

                            x = temp.diameter + temp.left_margin - temp.note_rect_size
                                - (c) * (temp.note_rect_size + temp.space_between_cards - 15) - (c * 5)
                            y = sketch.height / 2 + temp.note_rect_size / 1.25
                                + (j) * (temp.note_rect_size + 8) + 6 * j
                        }
                        sketch.push()
                        sketch.rect(x, y, temp.note_rect_size, temp.note_rect_size, 5)

                        sketch.noStroke()
                        sketch.fill('#000')
                        sketch.textAlign(sketch.CENTER, sketch.CENTER);
                        // textWrap(WORD);
                        sketch.textSize(temp.font_size)
                        let pain_text = temp.pain_relievers[temp.pain_relievers.length - i - 1].name
                        pain_text = pain_text.length >= 20 ? pain_text.substring(0, 15) + ' ...' : pain_text

                        //   .length >= 20 ? pain_relievers[pain_relievers.length - i - 1].name.substring(0, 15) + ' ...' : pain_relievers[pain_relievers.length - i - 1].name


                        sketch.text(pain_text,
                            x, y, temp.note_rect_size, temp.note_rect_size)
                        sketch.pop()
                        c++

                        temp.pain_relievers_positions.push(
                            {
                                id: temp.pain_relievers[temp.pain_relievers.length - i - 1].id,
                                name: temp.pain_relievers[temp.pain_relievers.length - i - 1].name,
                                x: x,
                                y: y
                            }
                        )

                        temp.cards_positions.push({
                            type: "pain_reliever",
                            id: temp.pain_relievers[temp.pain_relievers.length - i - 1].id,
                            name: temp.pain_relievers[temp.pain_relievers.length - i - 1].name,
                            index: temp.pain_relievers.length - i - 1,
                            x: x,
                            y: y
                        })
                    }
                    sketch.pop()
                    // console.log(pain_relievers_positions)
                }
                function drawProductAndServices() {
                    // console.log("in draw product and services")
                    temp.product_services_positions = []
                    sketch.stroke('#00FFAD')
                    sketch.fill('#00FFAD')


                    for (let i = 0, j = 0, r = 0; i < temp.product_services.length; i++, r++) {
                        sketch.stroke('#00FFAD')
                        sketch.strokeWeight(1)
                        // fill('#30bb30')
                        sketch.fill('#00FFAD')
                        sketch.push()
                        // console.log("==", i, j, r)
                        let x = (temp.diameter / 2) + temp.left_margin - (temp.note_rect_size * (j + 1)) - ((temp.space_between_cards / 3) * j),
                            y = sketch.height / 2 - ((r) * temp.note_rect_size) - (5 * r)

                        if (temp.product_services.length == 1) {
                            x = (temp.diameter / 2) + temp.left_margin - (temp.note_rect_size * (j + 2)) - ((temp.space_between_cards / 3) * j)
                        } else if (temp.product_services.length == 2) {
                            x = (temp.diameter / 2) + temp.left_margin - (temp.note_rect_size * (j + 2)) - ((temp.space_between_cards / 3) * j)
                            y = sketch.height / 2 - ((r - 0.5) * temp.note_rect_size) - (8 * r)
                        }
                        // sketch.push()
                        // fill("#f00")
                        // circle(x,y,10)
                        // sketch.pop()

                        let isInServices = isPointInTriangle(x, y,
                            (temp.diameter / 2) + temp.left_margin, sketch.height / 2,
                            temp.left_margin, (sketch.height / 2) - (temp.diameter / 2),
                            temp.left_margin, (sketch.height / 2) + (temp.diameter / 2))
                        // push()
                        // stroke('#f00')
                        // circle(x,y,10)
                        // circle((diameter/2)+left_margin,
                        //                        canvas_height / 2,20)
                        // pop()
                        // console.log("before if ppp", isInServices, i, j, r)
                        if (!isInServices) {
                            // // console.log("in if",i,j,r)
                            j++;
                            r = 0 * j;  // 5==>-1.5 * j, 4==> -1*j, 3==> -0.5*j
                            r = -1.5 * j
                            if (temp.product_services.length == 4) {
                                r = -1 * j
                            } else if (temp.product_services.length == 3) {
                                r = -0.5 * j
                            } else if (temp.product_services.length == 2) {
                                r = 0 * j
                            }
                            x = (temp.diameter / 2) + temp.left_margin - (temp.note_rect_size * (j + 1)) - ((temp.space_between_cards / 3) * j)
                            y = sketch.height / 2 - ((r) * temp.note_rect_size) - (5 * r)

                            // // console.log("in in if",i,j,r)

                        }
                        // // console.log("after if",i,j,r)
                        sketch.noStroke()
                        sketch.rectMode(sketch.CENTER)
                        sketch.rect(x, y, temp.note_rect_size, temp.note_rect_size, 5)
                        sketch.fill('#000')
                        sketch.textAlign(sketch.CENTER, sketch.CENTER);
                        sketch.textSize(temp.font_size)
                        sketch.textSize(temp.font_size)
                        let prod_text = temp.product_services[i].name
                        // prod_text = prod_text.length >= 20 ? prod_text.substring(0, 15) + ' ...' : prod_text
                        prod_text = prod_text.length >= 30 ? prod_text.substring(0, 27) + ' ...' : prod_text

                        //product_services[i].name.length >= 20 ? product_services[i].name.substring(0, 15) + ' ...' : product_services[i].name



                        sketch.text(prod_text,
                            x, y, temp.note_rect_size, temp.note_rect_size)

                        temp.product_services_positions.push({
                            id: temp.product_services[i].id,
                            name: temp.product_services[i].name,
                            x: x,
                            y: y
                        })

                        temp.cards_positions.push({
                            type: "product",
                            id: temp.product_services[i].id,
                            name: temp.product_services[i].name,
                            index: i,
                            x: x,
                            y: y
                        })
                        sketch.pop()
                    }

                }


                function drawRelationBetweenGains() {
                    // console.log('in draw relation between gains')

                    for (let i = 0; i < temp.gain_creators.length; i++) {
                        let gain_creator_obj = temp.gain_creators[i]

                        let gain_creator_position_index =
                            getIndexOfItemInArray(gain_creator_obj,
                                "gain_creator",
                                temp.cards_positions)
                        let gain_creator_position_obj =
                            temp.cards_positions[gain_creator_position_index]

                        // console.log(i, gain_creators[i].gains)
                        let relations = []

                        if (gain_creator_obj.gains != null && gain_creator_obj.gains != undefined) {
                            // console.log("    --", gain_creator_obj.gains)
                            for (let j = 0; j < gain_creator_obj.gains.length; j++) {
                                let card_index = getIndexOfItemInArray(gain_creator_obj.gains[j],
                                    "gain",
                                    temp.cards_positions)
                                // console.log("     -----", card_index)
                                if (card_index != -1) {
                                    let card_obj = temp.cards_positions[card_index]
                                    // console.log(card_obj)
                                    let arrow_start = sketch.createVector(gain_creator_position_obj.x + temp.note_rect_size / 2,
                                        gain_creator_position_obj.y)
                                    let arrow_end = sketch.createVector(card_obj.x - temp.note_rect_size / 2,
                                        card_obj.y)


                                    // drawArrow(arrow_start, arrow_end, '#2A8384')//'#3BB0B2')
                                    drawCurveArrow(arrow_start, arrow_end, -90, '#2A8384')
                                    relations.push(card_index)

                                    temp.relations_cards_id.push({
                                        from: gain_creator_position_index,
                                        to: card_index
                                    })

                                }

                            }

                            temp.cards_positions[gain_creator_position_index].hasRelations = true
                            temp.cards_positions[gain_creator_position_index].relations_id = relations

                        }

                        if (gain_creator_obj.pains != null && gain_creator_obj.pains != undefined) {
                            // console.log("    --", gain_creator_obj.pains)

                            for (let j = 0; j < gain_creator_obj.pains.length; j++) {
                                let card_index = getIndexOfItemInArray(gain_creator_obj.pains[j],
                                    "pain",
                                    temp.cards_positions)
                                // console.log("     -----", card_index)
                                if (card_index != -1) {
                                    let card_obj = temp.cards_positions[card_index]
                                    // console.log(card_obj)
                                    let arrow_start =
                                        sketch.createVector(gain_creator_position_obj.x +
                                            temp.note_rect_size / 2,
                                            gain_creator_position_obj.y)
                                    let arrow_end = sketch.createVector(card_obj.x - temp.note_rect_size / 2,
                                        card_obj.y)

                                    drawArrow(arrow_start, arrow_end, '#2A8384')
                                    // drawCurveArrow(arrow_start,arrow_end, -150, "#2A8384")

                                    relations.push(card_index)
                                    temp.relations_cards_id.push({
                                        from: gain_creator_position_index,
                                        to: card_index
                                    })

                                }

                            }

                            temp.cards_positions[gain_creator_position_index].hasRelations = true
                            temp.cards_positions[gain_creator_position_index].relations_id = relations

                        }
                    }
                }
                function drawRelationBetweenPains() {
                    // console.log('in draw relation between pains')

                    for (let i = 0; i < temp.pain_relievers.length; i++) {
                        let pain_reliever_obj = temp.pain_relievers[i]

                        let pain_reliever_position_index =
                            getIndexOfItemInArray(pain_reliever_obj,
                                "pain_reliever",
                                temp.cards_positions)
                        let pain_reliever_position_obj =
                            temp.cards_positions[pain_reliever_position_index]

                        // console.log(i, pain_relievers[i].pains)
                        let relations = []

                        if (pain_reliever_obj.pains != null && pain_reliever_obj.pains != undefined) {
                            // console.log("    --", pain_reliever_obj.pains)
                            for (let j = 0; j < pain_reliever_obj.pains.length; j++) {
                                let card_index = getIndexOfItemInArray(pain_reliever_obj.pains[j],
                                    "pain",
                                    temp.cards_positions)
                                // console.log("     -----", card_index)
                                if (card_index != -1) {
                                    let card_obj = temp.cards_positions[card_index]
                                    // console.log(card_obj)
                                    let arrow_start = sketch.createVector(pain_reliever_position_obj.x + temp.note_rect_size / 2,
                                        pain_reliever_position_obj.y)
                                    let arrow_end = sketch.createVector(card_obj.x - temp.note_rect_size / 2,
                                        card_obj.y)


                                    // drawArrow(arrow_start, arrow_end, '#7A6202')
                                    drawCurveArrow(arrow_start, arrow_end, 150, '#7A6202')

                                    relations.push(card_index)

                                    temp.relations_cards_id.push({
                                        from: pain_reliever_position_index,
                                        to: card_index
                                    })

                                }

                            }

                            temp.cards_positions[pain_reliever_position_index].hasRelations = true
                            temp.cards_positions[pain_reliever_position_index].relations_id = relations

                        }



                    }
                }
                function drawRelationBetweenJobs() {
                    // console.log("---------------------------------------")
                    //product_services
                    for (let i = 0; i < temp.product_services.length; i++) {
                        let product_service_obj = temp.product_services[i]

                        let product_service_position_index =
                            getIndexOfItemInArray(product_service_obj,
                                "product",
                                temp.cards_positions)
                        // console.log("iiii",product_service_position_index)
                        // console.log(product_service_obj)
                        // console.log(cards_positions)
                        let product_service_position_obj =
                            temp.cards_positions[product_service_position_index]

                        // console.log("   ",i, product_services[i].jobs)
                        let relations = []

                        if (product_service_obj.jobs != null && product_service_obj.jobs != undefined) {
                            // console.log("    --", product_service_obj.jobs)
                            for (let j = 0; j < product_service_obj.jobs.length; j++) {
                                let card_index = getIndexOfItemInArray(product_service_obj.jobs[j],
                                    "job",
                                    temp.cards_positions)
                                // console.log("     -----", card_index)
                                if (card_index != -1) {
                                    let card_obj = temp.cards_positions[card_index]
                                    // console.log(product_service_position_obj)
                                    // console.log(card_obj)
                                    let arrow_start = sketch.createVector(product_service_position_obj.x + temp.note_rect_size / 2,
                                        product_service_position_obj.y)
                                    let arrow_end = sketch.createVector(card_obj.x - temp.note_rect_size / 2,
                                        card_obj.y)


                                    // drawArrow(arrow_start, arrow_end, '#01AF78')//'#3BB0B2')
                                    drawCurveArrow(arrow_start, arrow_end, 30, '#01AF78')//'#3BB0B2')
                                    relations.push(card_index)

                                    temp.relations_cards_id.push({
                                        from: product_service_position_index,
                                        to: card_index
                                    })

                                }

                            }

                            temp.cards_positions[product_service_position_index].hasRelations = true
                            temp.cards_positions[product_service_position_index].relations_id = relations

                        }

                    }


                }

                function drawRelationsBetweenCustomerProfileAndValueMAp() {
                    //gain creators
                    for (let i = 0; i < temp.gain_creators.length; i++) {
                        let gain_creator_obj = temp.gain_creators[i]

                        let gain_creator_position_index =
                            getIndexOfItemInArray(gain_creator_obj,
                                "gain_creator",
                                temp.cards_positions)
                        let gain_creator_position_obj =
                            temp.cards_positions[gain_creator_position_index]

                        // console.log(i, gain_creators[i].gains)
                        let relations = []

                        if (gain_creator_obj.relations != null && gain_creator_obj.relations != undefined) {
                            // console.log("    --", gain_creator_obj.gains)
                            for (let j = 0; j < gain_creator_obj.relations.length; j++) {
                                let card_index = getIndexOfItemInArray(gain_creator_obj.relations[j],
                                    gain_creator_obj.relations[j].type,
                                    temp.cards_positions)
                                // console.log("     -----", card_index)
                                if (card_index != -1) {
                                    let card_obj = temp.cards_positions[card_index]
                                    // console.log(card_obj)
                                    let arrow_start = sketch.createVector(gain_creator_position_obj.x + temp.note_rect_size / 2,
                                        gain_creator_position_obj.y)
                                    let arrow_end = sketch.createVector(card_obj.x - temp.note_rect_size / 2,
                                        card_obj.y)


                                    // drawArrow(arrow_start, arrow_end, '#2A8384')//'#3BB0B2')
                                    drawCurveArrow(arrow_start, arrow_end, -90, '#2A8384')
                                    relations.push(card_index)

                                    temp.relations_cards_id.push({
                                        from: gain_creator_position_index,
                                        to: card_index
                                    })

                                }

                            }

                            temp.cards_positions[gain_creator_position_index].hasRelations = true
                            temp.cards_positions[gain_creator_position_index].relations_id = relations

                        }
                    }

                    //product_services
                    for (let i = 0; i < temp.product_services.length; i++) {
                        let product_service_obj = temp.product_services[i]

                        let product_service_position_index =
                            getIndexOfItemInArray(product_service_obj,
                                "product",
                                temp.cards_positions)
                        // console.log("iiii",product_service_position_index)
                        // console.log(product_service_obj)
                        // console.log(cards_positions)
                        let product_service_position_obj =
                            temp.cards_positions[product_service_position_index]

                        // console.log("   ",i, product_services[i].jobs)
                        let relations = []

                        if (product_service_obj.relations != null && product_service_obj.relations != undefined) {
                            // console.log("    --", product_service_obj.relations)
                            for (let j = 0; j < product_service_obj.relations.length; j++) {
                                let card_index = getIndexOfItemInArray(product_service_obj.relations[j],
                                    product_service_obj.relations[j].type,
                                    temp.cards_positions)
                                // console.log("     -----", card_index)
                                if (card_index != -1) {
                                    let card_obj = temp.cards_positions[card_index]
                                    // console.log(product_service_position_obj)
                                    // console.log(card_obj)
                                    let arrow_start = sketch.createVector(product_service_position_obj.x + temp.note_rect_size / 2,
                                        product_service_position_obj.y)
                                    let arrow_end = sketch.createVector(card_obj.x - temp.note_rect_size / 2,
                                        card_obj.y)


                                    // drawArrow(arrow_start, arrow_end, '#01AF78')//'#3BB0B2')
                                    drawCurveArrow(arrow_start, arrow_end, 30, '#01AF78')//'#3BB0B2')
                                    relations.push(card_index)

                                    temp.relations_cards_id.push({
                                        from: product_service_position_index,
                                        to: card_index
                                    })

                                }

                            }

                            temp.cards_positions[product_service_position_index].hasRelations = true
                            temp.cards_positions[product_service_position_index].relations_id = relations

                        }

                    }

                    // pain relievers
                    for (let i = 0; i < temp.pain_relievers.length; i++) {
                        let pain_reliever_obj = temp.pain_relievers[i]

                        let pain_reliever_position_index =
                            getIndexOfItemInArray(pain_reliever_obj,
                                "pain_reliever",
                                temp.cards_positions)
                        let pain_reliever_position_obj =
                            temp.cards_positions[pain_reliever_position_index]

                        // console.log(i, pain_relievers[i].relations)
                        let relations = []

                        if (pain_reliever_obj.relations != null && pain_reliever_obj.relations != undefined) {
                            // console.log("    --", pain_reliever_obj.relations)
                            for (let j = 0; j < pain_reliever_obj.relations.length; j++) {
                                let card_index = getIndexOfItemInArray(pain_reliever_obj.relations[j],
                                    pain_reliever_obj.relations[j].type,
                                    temp.cards_positions)
                                // console.log("     -----", card_index)
                                if (card_index != -1) {
                                    let card_obj = temp.cards_positions[card_index]
                                    // console.log(card_obj)
                                    let arrow_start = sketch.createVector(pain_reliever_position_obj.x + temp.note_rect_size / 2,
                                        pain_reliever_position_obj.y)
                                    let arrow_end = sketch.createVector(card_obj.x - temp.note_rect_size / 2,
                                        card_obj.y)


                                    // drawArrow(arrow_start, arrow_end, '#7A6202')
                                    drawCurveArrow(arrow_start, arrow_end, 150, '#7A6202')

                                    relations.push(card_index)

                                    temp.relations_cards_id.push({
                                        from: pain_reliever_position_index,
                                        to: card_index
                                    })

                                }

                            }

                            temp.cards_positions[pain_reliever_position_index].hasRelations = true
                            temp.cards_positions[pain_reliever_position_index].relations_id = relations

                        }



                    }
                }


                function drawSimpleArrow(base, vec) {
                    let startX = base.x, startY = base.y
                    let endX = vec.x, endY = vec.y

                    sketch.strokeWeight(3)
                    sketch.stroke('#293042')
                    sketch.fill("#293042")
                    sketch.push()
                    sketch.line(startX, startY, endX, endY);
                    sketch.pop()


                    // Calculate the angle of the line.
                    var angle = sketch.atan2(endY - startY, endX - startX);

                    // Draw the arrow head.
                    sketch.push();
                    sketch.translate(endX, endY);
                    sketch.rotate(angle - 270);
                    sketch.noFill()
                    sketch.line(0, 0, -8, 10)
                    sketch.line(0, 0, 8, 10)
                    sketch.translate(0, 0);
                    // triangle(-5, 10, 5, 10, 0, 0);
                    sketch.pop();
                }
                function drawArrow(base, vec, arrow_color) {
                    let startX = base.x, startY = base.y
                    let endX = vec.x, endY = vec.y

                    sketch.strokeWeight(2)
                    sketch.stroke(arrow_color)
                    sketch.fill(arrow_color)
                    sketch.push()
                    cnv.drawingContext.setLineDash([5, 5])
                    // curve(startX, startY, (startX + endX) / 2, (startY + endY) / 2, endX, endY, startX, startY);

                    sketch.line(startX, startY, endX, endY);
                    sketch.pop()


                    // Calculate the angle of the line.
                    var angle = sketch.atan2(endY - startY, endX - startX);

                    // Draw the arrow head.
                    sketch.push();
                    sketch.translate(endX, endY);
                    sketch.rotate(angle - 270);
                    sketch.triangle(-5, 10, 5, 10, 0, 0);
                    sketch.translate(0, 0);
                    sketch.pop();
                }
                function drawCurveArrow(arrow_start, arrow_end, curve_angle, arrow_color) {


                    //stroke("#01AF78")
                    sketch.strokeWeight(2)
                    sketch.stroke(arrow_color)
                    sketch.push()
                    sketch.beginShape();
                    cnv.drawingContext.setLineDash([5, 5])
                    sketch.noFill()
                    // Specify the first anchor point
                    // using vertex()
                    sketch.vertex(arrow_start.x, arrow_start.y);

                    // Use the quadraticVertex() function
                    // to define the rest of the points
                    sketch.quadraticVertex((arrow_end.x + arrow_start.x) / 2 + curve_angle / 2,
                        (arrow_end.y + arrow_start.y) / 2 + curve_angle,
                        arrow_end.x - 15, arrow_end.y);
                    sketch.quadraticVertex(arrow_end.x - 15, arrow_end.y, arrow_end.x, arrow_end.y);
                    // quadraticVertex(p4.x, p4.y, p5.x, p5.y);
                    sketch.endShape();
                    sketch.pop()

                    sketch.push();
                    sketch.fill(arrow_color)
                    var angle = sketch.atan2(arrow_end.y - arrow_start.y,
                        arrow_end.x - arrow_start.x);
                    sketch.translate(arrow_end.x, arrow_end.y);
                    sketch.rotate(angle - 270);
                    sketch.triangle(-5, 10, 5, 10, 0, 0);
                    sketch.translate(0, 0);
                    sketch.pop();
                }

                //
                function mouseClicked() {
                    console.log("clicked")
                    sketch.setup()
                    sketch.redraw()

                    temp.clicked_card = null
                    temp.clicked_card_type = null
                    temp.clicked_card_items = []

                    let clicked_text = ""
                    for (let i = 0; i < temp.cards_positions.length; i++) {
                        let card_obj = temp.cards_positions[i]

                        let isInCard = isClickedOnRect(sketch.mouseX, sketch.mouseY,
                            card_obj.x, card_obj.y,
                            temp.note_rect_size, temp.note_rect_size)
                        if (isInCard) {
                            // // console.log(gain_obj.x, gain_obj.y)

                            sketch.strokeWeight(3)
                            sketch.stroke("#E91E63C9")
                            sketch.fill("rgba(221,23,23,0)")
                            sketch.push()
                            sketch.rectMode(sketch.CENTER)
                            sketch.rect(card_obj.x, card_obj.y,
                                temp.note_rect_size + 4, temp.note_rect_size + 4, 10)
                            sketch.pop()
                            // clicked_text = "clicked " + temp.cards_positions[i].type +
                            //     ": " + temp.cards_positions[i].name + "<br/>"
                            // clicked_text += "<ul style='margin: 5px 0'>"
                            temp.clicked_card = temp.cards_positions[i].name
                            temp.clicked_card_index = temp.cards_positions[i].index
                            temp.clicked_card_type = temp.getClickedCardType(temp.cards_positions[i].type)
                            //temp.cards_positions[i].type

                            console.log(temp.card_types_key)
                            console.log(temp.card_types_key.length)
                            console.log(JSON.parse(JSON.stringify(temp.cards_positions[i])))
                            console.log(temp.cards_positions[i])
                            for (let i = 0; i < temp.card_types_key.length; i++) {
                                // console.log(temp.card_types_key[i])
                                if (temp.card_types_key[i].key == temp.clicked_card_type) {
                                    console.log(i, "--->", temp.card_types_key[i])
                                }
                            }




                            // alert("--clicked on "+card_obj.type+": "+card_obj.name)
                            // console.log(card_obj)
                            // console.log(card_obj.hasRelations == true)
                            if (card_obj.hasRelations == true) {
                                for (let k = 0; k < card_obj.relations_id.length; k++) {
                                    // // console.log()
                                    let card_item = temp.cards_positions[card_obj.relations_id[k]]
                                    sketch.strokeWeight(3)
                                    sketch.stroke("#E91E63C9")
                                    // stroke("#f00")
                                    sketch.fill("rgba(221,23,23,0)")

                                    // drawingContext.shadowOffsetX = 0;
                                    // drawingContext.shadowOffsetY = 0;
                                    // drawingContext.shadowBlur = 8;
                                    // drawingContext.shadowColor = '#B9003F';
                                    // background(200);
                                    // ellipse(width / 2, height / 2, 50, 50);

                                    // clicked_text += "<li>" + temp.cards_positions[card_obj.relations_id[k]].name + "</li>"
                                    temp.clicked_card_items.push(temp.cards_positions[card_obj.relations_id[k]].name)

                                    sketch.push()
                                    sketch.rectMode(sketch.CENTER)
                                    sketch.rect(card_item.x, card_item.y,
                                        temp.note_rect_size + 4, temp.note_rect_size + 4, 10)
                                    sketch.pop()
                                }
                            }
                            // relations_id
                            let newArray = temp.relations_cards_id.filter(function (el) {
                                return el.to == i;
                            }
                            );
                            // console.log("newArray");

                            // console.log(newArray);
                            for (let i = 0; i < newArray.length; i++) {
                                //cards_positions
                                let from_card = temp.cards_positions[newArray[i].from]
                                // let to_card = cards_positions[newArray[i].to]
                                sketch.strokeWeight(3)
                                sketch.stroke("#E91E63C9")
                                // stroke("#f00")
                                sketch.fill("rgba(221,23,23,0)")

                                // clicked_text += "<li>" + temp.cards_positions[newArray[i].from].name + "</li>"
                                temp.clicked_card_items.push(temp.cards_positions[newArray[i].from].name)

                                sketch.push()
                                sketch.rectMode(sketch.CENTER)
                                sketch.rect(from_card.x, from_card.y,
                                    temp.note_rect_size + 4, temp.note_rect_size + 4, 10)
                                sketch.pop()
                                // rect(to_card.x, to_card.y,
                                //       note_rect_size + 4, note_rect_size + 4, 10)

                            }
                            // clicked_text += "</ul>"

                            // div.html(clicked_text);
                            // div.style("display", "block")

                            return
                        }
                    }

                    // console.log("click mouse ", sketch.mouseX, sketch.mouseY, sketch.width / 2, sketch.height / 2)
                }
                // function mouseClicked() {
                //     // console.log("click mouse ", mouseX, mouseY, width / 2, height / 2)

                //     div.html("");
                //     div.style("display", "none")

                //     redraw()

                //     let clicked_text = ""

                // }
                ////////
                function isPointInArc(x, y, centerX, centerY, radius, startAngle, endAngle) {

                    // console.log("in is point in arc", x, y, centerX, centerY, radius,
                    // startAngle, endAngle)
                    // console.log((x, y, centerX, centerY, radius, startAngle, endAngle))
                    // Calculate the distance from the point to the center of the arc
                    const distance = sketch.dist(x, y, centerX, centerY);
                    // circle(x,y,20)
                    // circle(centerX, centerY, 10)
                    // console.log("distance", distance, radius)

                    // Check if the distance is less than or equal to the radius
                    if (distance <= radius * 2) {

                        // Calculate the angle of the point relative to the center of the arc
                        let angle = sketch.atan2(y - centerY, x - centerX);
                        // console.log(angle, angle + 180)
                        angle += 360
                        // console.log(angle, angle + 180)

                        // Check if the angle is between the start angle and end angle
                        if (angle >= startAngle && angle <= endAngle) {
                            return true;
                        }
                    }

                    return false;
                }
                function isPointInTriangle(point_x, point_y, x1, y1, x2, y2, x3, y3) {
                    let point = [point_x, point_y];
                    let area = getArea([x1, y1], [x2, y2], [x3, y3]);
                    let areaA = getArea([x1, y1], [x2, y2], point);
                    let areaB = getArea(point, [x2, y2], [x3, y3]);
                    let areaC = getArea([x1, y1], point, [x3, y3]);
                    return sketch.abs(areaA + areaB + areaC - area) < 0.001;
                }

                function pointInPoly(verts, pt) {
                    let c = false;
                    for (let i = 0, j = verts.length - 1; i < verts.length; j = i++) {
                        if (((verts[i].y > pt.y) != (verts[j].y > pt.y)) &&
                            (pt.x < (verts[j].x - verts[i].x) *
                                (pt.y - verts[i].y) / (verts[j].y - verts[i].y) + verts[i].x))
                            c = !c;
                    }
                    return c;
                }


                function getArea(a, b, c) {
                    return sketch.abs((a[0] * (b[1] - c[1]) + b[0] * (c[1] - a[1]) + c[0] * (a[1] - b[1])) / 2);
                }
                function isClickedOnRect(clickedX, clickedY, rectX, rectY,
                    rectWidth, rectHeight) {
                    // console.log('in isClickedOnRect')

                    if (clickedX >= rectX - (rectWidth / 2) &&
                        clickedX <= rectX + (rectWidth / 2) &&
                        clickedY >= rectY - (rectHeight / 2) &&
                        clickedY <= rectY + (rectHeight / 2)) {

                        return true

                    }
                    return false
                }

                function getIndexOfItemInArray(obj, obj_type, array) {
                    // // console.log("in get index")
                    // // console.log(array)
                    // // console.log(obj)
                    for (let i = 0; i < array.length; i++) {
                        if (array[i].id == obj.id && array[i].type == obj_type) {
                            return i
                        }
                    }
                    return -1
                }

            };

            p5Instance = new p5(s, this.$refs.canvas1);
        },

        clickDeleteCard(card_type, card_index) {
            console.log('click delete card', card_type, card_index)
            if (card_type == 'job') {
                this.customer_jobs.splice(card_index, 1)
                // this.product_services.splice(card_index, 1)
            } else if (card_type == 'gain') {
                this.gains.splice(card_index, 1)
                // this.gain_creators.splice(card_index, 1)
            } else if (card_type == 'pain') {
                this.pains.splice(card_index, 1)
                // this.pain_relievers.splice(card_index, 1)
            }

            console.log(this.customer_jobs)


            this.clicked_card = null
            this.clicked_card_type = null
            this.clicked_card_items = []

            p5Instance.setup()
            p5Instance.redraw()
        },
        addJob() {
            let temp = this.customer_jobs.length + Math.floor(Math.random() * 50)
            this.customer_jobs.push({ id: temp, name: "Job " + temp })

            p5Instance.setup()
            p5Instance.redraw()
        },
        addGain() {
            let temp = this.gains.length + Math.floor(Math.random() * 50)
            this.gains.push({ id: temp, name: "Gain " + temp })

            p5Instance.setup()
            p5Instance.redraw()
        },
        addPain() {
            let temp = this.pains.length + Math.floor(Math.random() * 50)
            this.pains.push({ id: temp, name: "Pain " + temp })

            p5Instance.setup()
            p5Instance.redraw()
        },
        getClickedCardType(type) {
            for (let i = 0; this.card_types_key.length; i++) {
                if (this.card_types_key[i].key == type) {
                    return this.card_types_key[i].name
                }
            }
        }
    }
};
</script>

<style >
@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);

body.background-body {
    height: 100vh;
    background-image: url('@/assets/images/outer-space-background.png');

}

#app {
    height: 100%;
}
/* 
h2 {
    color: #293042;
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

p {
    color: #696682;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal
} */
</style>