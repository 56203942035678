<template>
    <div class=" ">
        <!-- <navbar-protostars></navbar-protostars> -->

        <!---->

        <div id="accordion-nested-parent" data-accordion="collapse" class="mt-4 mb-8 mx-8">
            <h2 id="accordion-collapse-heading-1" class="font-Poppins text-2xl">
                <button type="button"
                    class="flex items-center justify-between w-full p-5 my-2 font-semibold bg-white rtl:text-right !text-black-protostars border  border-gray-200 rounded-lg   hover:bg-white dark:hover:bg-gray-800 gap-3"
                    data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                    aria-controls="accordion-collapse-body-1">
                    <span>Situation Analysis</span>
                    <!-- <svg data-accordion-icon class="w-3 h-3  shrink-0" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 5 5 1 1 5" />
                    </svg> -->
                    <svg data-accordion-icon="" class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"></path>
                    </svg>
                </button>
            </h2>
            <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
                <div class=" bg-transparent border-none">

                    <!-- Nested accordion -->
                    <div id="accordion-nested-collapse" data-accordion="collapse">
                        <h2 id="accordion-nested-collapse-heading-1" class="font-Poppins text-xl">
                            <button type="button"
                                class="flex items-center justify-between w-full mt-4 p-5 bg-white border-none border-t-2 border-t-gray-200 rounded-t-lg text-lg font-semibold rtl:text-right text-black-protostars-500  hover:bg-white dark:hover:bg-gray-800 gap-3"
                                data-accordion-target="#accordion-nested-collapse-body-1" aria-expanded="true"
                                aria-controls="accordion-nested-collapse-body-1">
                                <span>What’s the current situation?</span>
                                <!-- <svg data-accordion-icon class="w-6 h-6 rotate-180 shrink-0 text-blue-protostars"
                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                </svg> -->

                                <svg data-accordion-icon="" class="w-6 h-6 shrink-0  text-blue-protostars"
                                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </button>
                        </h2>
                        <div id="accordion-nested-collapse-body-1" class="hidden"
                            aria-labelledby="accordion-nested-collapse-heading-1">
                            <div class="p-5 border-t-0 border-b-2 border-b-blue-protostars bg-white rounded-b-lg   ">
                                <Vueform  add-class="vueFormContainer" :float-placeholders="false" v-model="formData" validate-on="change|step"
                                    class="w-10/12 mx-auto" ref="registerFormRef" 
                                    :remove-class="{ div: ['grid'] }">
                                    <TextareaElement name="current_situation" label="Description" rows="5" />

                                    <div class="col-span-12 mt-4">
                                        <label
                                            class="flex items-start font-medium pb-0 dfghjk form-text col-span-12 form-pb-gutter/3 form-pt-0 form-pr-0">
                                            Voice
                                        </label>
                                        <div class="w-full " v-if="!isRecording">
                                            <button
                                                class="btn btn-protostars btn-link float-right !w-auto !text-green-protostars"
                                                @click="startRecording"><font-awesome-icon icon="fa-solid fa-microphone" />
                                                Start Recording</button>
                                        </div>
                                        <div class="w-full flex justify-between" v-if="isRecording">
                                            <span
                                                class="text-center text-sm font-semibold text-black-protostars m-auto self-center"><span
                                                    class="text-red-500 mr-1"><font-awesome-icon
                                                        icon="fa-solid fa-circle" /></span>Recording</span>
                                            <button
                                                class="btn btn-protostars btn-link float-right !w-auto !text-yellow-protostars"
                                                @click="stopRecording"><font-awesome-icon icon="fa-solid fa-pause" /> Stop
                                                Recording</button>
                                        </div>

                                        <audio ref="audioPlayer" controls class="w-full"></audio>
                                    </div>

                                    <FileInputElement class="my-2" name="current_situation_video" label="Video"
                                        :accept="'video/*'" :multiple="false" />


                                </Vueform>

                                <button v-if="!is_loading"
                                    class="btn btn-protostars btn-primary block w-40 text-center mx-auto mt-4"
                                    @click="clickToDirectToFormBuilder">Save</button>
                                <button v-else
                                    class="btn btn-protostars btn-primary block w-40 text-center mx-auto mt-4"
                                    disabled>
                                    <div role="status" class="inline-block">
                                        <svg aria-hidden="true"
                                            class="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor" />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill" />
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div> Loading
                                </button>
                            </div>
                        </div>


                        <h2 id="accordion-nested-collapse-heading-2" class="font-Poppins text-xl">
                            <button type="button"
                                class="flex items-center justify-between w-full mt-4 p-5 bg-white border-none border-t-2 border-t-gray-200 rounded-t-lg text-lg font-semibold rtl:text-right text-black-protostars-500  hover:bg-white dark:hover:bg-gray-800 gap-3"
                                data-accordion-target="#accordion-nested-collapse-body-2" aria-expanded="false"
                                aria-controls="accordion-nested-collapse-body-2">
                                <span>What are you trying to achieve strategically?</span>

                                <svg data-accordion-icon="" class="w-6 h-6 shrink-0  text-blue-protostars"
                                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </button>
                        </h2>
                        <div id="accordion-nested-collapse-body-2" class="hidden"
                            aria-labelledby="accordion-nested-collapse-heading-2">
                            <div class="p-5 border-t-0 border-b-2 border-b-blue-protostars bg-white rounded-b-lg   ">
                                <Vueform  add-class="vueFormContainer" :float-placeholders="false" v-model="formData" validate-on="change|step"
                                    class="w-10/12 mx-auto" ref="registerFormRef" 
                                    :remove-class="{ div: ['grid'] }">
                                    <TextareaElement name="achieve_strategically" label="Description" rows="5" />

                                    <!-- <div class="col-span-12 mt-4">
                                        <label
                                            class="flex items-start font-medium pb-0 dfghjk form-text col-span-12 form-pb-gutter/3 form-pt-0 form-pr-0">
                                            Voice
                                        </label>
                                        <div class="w-full " v-if="!isRecording">
                                            <button class="btn btn-protostars btn-link float-right !text-green-protostars"
                                                @click="startRecording"><font-awesome-icon icon="fa-solid fa-microphone" />
                                                Start Recording</button>
                                        </div>
                                        <div class="w-full flex justify-between" v-if="isRecording">
                                            <span
                                                class="text-center text-sm font-semibold text-black-protostars m-auto self-center"><span
                                                    class="text-red-500 mr-1"><font-awesome-icon
                                                        icon="fa-solid fa-circle" /></span>Recording</span>
                                            <button class="btn btn-protostars btn-link float-right !text-yellow-protostars"
                                                @click="stopRecording"><font-awesome-icon icon="fa-solid fa-pause" /> Stop
                                                Recording</button>
                                        </div>

                                        <audio ref="audioPlayer" controls class="w-full"></audio>
                                    </div> -->
                                </Vueform>

                                <button
                                    class="btn btn-protostars btn-protostars-primary block w-40 text-center mx-auto mt-4">Save</button>
                            </div>
                        </div>

                        <h2 id="accordion-nested-collapse-heading-3" class="font-Poppins text-xl">
                            <button type="button"
                                class="flex items-center justify-between w-full mt-4 p-5 bg-white border-none border-t-2 border-t-gray-200 rounded-t-lg text-lg font-semibold rtl:text-right text-black-protostars-500  hover:bg-white dark:hover:bg-gray-800 gap-3"
                                data-accordion-target="#accordion-nested-collapse-body-3" aria-expanded="false"
                                aria-controls="accordion-nested-collapse-body-3">
                                <span>What are your key metrics for success?</span>
                                <!-- <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0 text-blue-protostars"
                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M9 5 5 1 1 5" />
                                </svg> -->

                                <svg data-accordion-icon="" class="w-6 h-6 shrink-0  text-blue-protostars"
                                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </button>
                        </h2>
                        <div id="accordion-nested-collapse-body-3" class="hidden"
                            aria-labelledby="accordion-nested-collapse-heading-3">
                            <div class="p-5 border-t-0 border-b-2 border-b-blue-protostars bg-white rounded-b-lg   ">
                                <Vueform  add-class="vueFormContainer" :float-placeholders="false" v-model="formData" validate-on="change|step"
                                    class="w-10/12 mx-auto" ref="registerFormRef" 
                                    :remove-class="{ div: ['grid'] }">
                                    <TextareaElement name="key_metrics" label="Description" rows="5" />

                                </Vueform>

                                <button
                                    class="btn btn-protostars btn-protostars-primary block w-40 text-center mx-auto mt-4">Save</button>
                            </div>
                        </div>


                        <h2 id="accordion-nested-collapse-heading-4" class="font-Poppins text-xl">
                            <button type="button"
                                class="flex items-center justify-between w-full mt-4 p-5 bg-white border-none border-t-2 border-t-gray-200 rounded-t-lg text-lg font-semibold rtl:text-right text-black-protostars-500  hover:bg-white dark:hover:bg-gray-800 gap-3"
                                data-accordion-target="#accordion-nested-collapse-body-4" aria-expanded="false"
                                aria-controls="accordion-nested-collapse-body-4">
                                <span>What would it mean to achieve that?</span>

                                <svg data-accordion-icon="" class="w-6 h-6 shrink-0  text-blue-protostars"
                                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </button>
                        </h2>
                        <div id="accordion-nested-collapse-body-4" class="hidden"
                            aria-labelledby="accordion-nested-collapse-heading-4">
                            <div class="p-5 border-t-0 border-b-2 border-b-blue-protostars bg-white rounded-b-lg   ">
                                <Vueform add-class="vueFormContainer "  :float-placeholders="false" v-model="formData" validate-on="change|step"
                                    class="w-10/12 mx-auto" ref="registerFormRef"
                                    :remove-class="{ div: ['grid'] }">
                                    <TextareaElement name="mean_achieve" label="Description" rows="5" />
                                </Vueform>

                                <button
                                    class="btn btn-protostars btn-protostars-primary block w-40 text-center mx-auto mt-4">Save</button>
                            </div>
                        </div>

                    </div>
                    <!-- End: Nested accordion -->
                </div>
            </div>


            <!-- ------------canvas-----------  -->
            <h2 id="accordion-collapse-heading-2" class="font-Poppins text-2xl">
                <a href="/value_canvas"
                    class="flex items-center justify-between w-full p-5 my-2 font-semibold bg-white rtl:text-right !text-black-protostars border  border-gray-200 rounded-lg   hover:bg-white dark:hover:bg-gray-800 gap-3">
                    <span>Value Proposition</span>
                    <!-- <svg data-accordion-icon class="w-3 h-3  shrink-0" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 5 5 1 1 5" />
                    </svg> -->
                    <font-awesome-icon icon="fa-solid fa-circle-arrow-right" />
                </a>
            </h2>
            <!-- <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
                <div class=" bg-transparent border-none">

                   Canvas
                </div>
            </div> -->
        </div>
        <!-- 
        <button @click="startRecording">Record</button>
        <button @click="stopRecording">Stop</button>
        <audio v-if="recordedAudio" :src="recordedAudioURL" controls />

        {{ recordedAudioURL }} -->


    </div>
</template>


<script>
// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';
// import AudioRecorder from 'vue-audio-recorder'
// import { initFlowbite } from 'flowbite'
import axios from 'axios';

export default {
    name: "SituationAnalysisIndex",
    components: {  },
    data() {
        let temp = this;
        return {

            formData: null,

            // stream: null,
            // recorder: null,
            // recordedAudio: null,
            // recordedAudioURL: null,


            isRecording: false,
            mediaRecorder: null,
            recordedChunks: [],
            audioURL: null,
            is_loading: false
        }
    },
    mounted() {
        // setTimeout(() => {
        //     initFlowbite();
        // }, 1500);

    },
    beforeUnmount() {
        // this.stopRecording(); // Cleanup resources before component unmounts
    },
    updated() {
    },
    watch: {
    },
    methods: {
        // async startRecording() {
        //     try {
        //         const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        //         this.stream = stream;
        //         this.recorder = new MediaRecorder(stream);
        //         this.recorder.ondataavailable = (e) => {
        //             this.recordedAudio = e.data;
        //         };
        //         this.recorder.start();
        //     } catch (error) {
        //         // Handle errors like permission denial or device compatibility issues
        //         console.error('Error starting recording:', error);
        //     }
        // },
        // stopRecording() {
        //     this.recorder.stop();
        //     this.recordedAudioURL = URL.createObjectURL(this.recordedAudio);
        //     this.stream.getTracks().forEach((track) => track.stop());
        // },

        async startRecording() {
            this.recordedChunks = []
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                this.mediaRecorder = new MediaRecorder(stream);
                this.mediaRecorder.ondataavailable = (e) => {
                    if (e.data.size > 0) {
                        this.recordedChunks.push(e.data);
                    }
                };
                this.mediaRecorder.onstop = () => {
                    const blob = new Blob(this.recordedChunks, { type: 'audio/mp3' });
                    const audioURL = URL.createObjectURL(blob);
                    this.audioURL = audioURL
                    this.$refs.audioPlayer.src = audioURL;
                };
                this.mediaRecorder.start();
                this.isRecording = true;
            } catch (err) {
                console.error('Error accessing microphone:', err);
            }
        },
        stopRecording() {
            if (this.mediaRecorder && this.isRecording) {
                this.mediaRecorder.stop();
                this.isRecording = false;
            }

            console.log(this.recordedChunks)
            console.log(new Blob(this.recordedChunks, { type: 'audio/mp3' }))
        },

        afterStart() {
            console.log('After microphone starts recording')
        },
        afterStop(blob) {
            console.log('After microphone stops recording')
            console.log(blob)
        },
        afterPause() {
            console.log('After microphone pauses recording')
        },
        afterResume() {
            console.log('After microphone resumes recording')
        },
        saveAsMp3(data, url) {
            console.log('After microphone stops recording and audio encoded to mp3')
            console.log(data)
            console.log(url)
        },


        clickToDirectToFormBuilder() {

            // 
            this.is_loading = true
            axios
                .get("forms/list", {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: this.client_name,
                        Authorization:
                            "Bearer " +
                            JSON.parse(window.localStorage.getItem("user")).access_token,
                    },
                })
                .then(async (response) => {
                    console.log(response);


                    let temp_data = {

                        "schema":
                        {
                            "question_1": {
                                "name": "question_1",
                                "type": "radiogroup",
                                "items": [
                                    {
                                        "value": "yes",
                                        "label": "Yes"
                                    },
                                    {
                                        "value": "no",
                                        "label": "No"
                                    }
                                ],
                                "label": "1. Do projects have a point of contact for security issues or incidents?",
                                "builder": {
                                    "type": "radiogroup",
                                    "label": "1. Do projects have a point of contact for security issues or incidents?"
                                }
                            },
                            "question_2": {
                                "name": "question_2",
                                "type": "radiogroup",
                                "items": [
                                    {
                                        "value": "yes",
                                        "label": "Yes"
                                    },
                                    {
                                        "value": "no",
                                        "label": "No"
                                    }
                                ],
                                "label": "2. Does your organization have an assigned security response team?",
                                "builder": {
                                    "type": "radiogroup",
                                    "label": "2. Does your organization have an assigned security response team?"
                                }
                            },
                            "question_3": {
                                "name": "question_3",
                                "type": "radiogroup",
                                "items": [
                                    {
                                        "value": "yes",
                                        "label": "Yes"
                                    },
                                    {
                                        "value": "no",
                                        "label": "No"
                                    }
                                ],
                                "label": "3. Are project teams aware of their security point(s) of contact and response team(s)?",
                                "builder": {
                                    "type": "radiogroup",
                                    "label": "3. Are project teams aware of their security point(s) of contact and response team(s)?"
                                }
                            },
                            "question_4": {
                                "name": "question_4",
                                "type": "radiogroup",
                                "items": [
                                    {
                                        "value": "yes",
                                        "label": "Yes"
                                    },
                                    {
                                        "value": "no",
                                        "label": "No"
                                    }
                                ],
                                "label": "4. Does the organization utilize a consistent process for incident reporting and handling?",
                                "builder": {
                                    "type": "radiogroup",
                                    "label": "4. Does the organization utilize a consistent process for incident reporting and handling?"
                                }
                            },
                        },
                        "theme": {}, "form": { "nesting": true },
                        "export":{ "output": "inline", "api": "options", "theme": "none" } , 
                        "builder": { "name": "MyForm" }
                    }

                    let formbuilder_data = {}
                    if(response.data.length > 0){
                        formbuilder_data = JSON.parse(response.data[0].structure_object)
                        formbuilder_data.theme = {}
                        formbuilder_data.form=  { "nesting": true }
                        formbuilder_data.export = { "output": "inline", "api": "options", "theme": "none" }
                        formbuilder_data.builder= { "name": "MyForm" }
                        sessionStorage.setItem("form_id",response.data[0].form_id)

                    }
                    else{
                        formbuilder_data = temp_data
                        sessionStorage.setItem("form_id",'abcd01234')
                    }

                    console.log("------------------")
                    console.log(formbuilder_data)
                    console.log("------------------")

                    localStorage.setItem('situation_analysis', JSON.stringify(formbuilder_data))

                    this.$router.push("/form_builder")
                    // this.$router.push("/form_builder/output")

                    this.is_loading = false
                })
                .catch((error) => {
                    console.log(error)
                    this.is_loading = false;
                    // let error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    // if (error_message == null || error_message == '') {
                    //     error_message = 'Sorry, '
                    // }
                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }
                    this.$swal({
                        // title: "Title",
                        text: error_message,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-grey-protostars",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });


            // let formbuilder_data = {
            //     "schema": {
            //         "question_1": {
            //             "name": "question_1",
            //             "type": "radiogroup",
            //             "items": [
            //                 {
            //                     "value": "yes",
            //                     "label": "Yes"
            //                 },
            //                 {
            //                     "value": "no",
            //                     "label": "No"
            //                 }
            //             ],
            //             "label": "1. Do projects have a point of contact for security issues or incidents?",
            //             "builder": {
            //                 "type": "radiogroup",
            //                 "label": "1. Do projects have a point of contact for security issues or incidents?"
            //             }
            //         },
            //         "question_2": {
            //             "name": "question_2",
            //             "type": "radiogroup",
            //             "items": [
            //                 {
            //                     "value": "yes",
            //                     "label": "Yes"
            //                 },
            //                 {
            //                     "value": "no",
            //                     "label": "No"
            //                 }
            //             ],
            //             "label": "2. Does your organization have an assigned security response team?",
            //             "builder": {
            //                 "type": "radiogroup",
            //                 "label": "2. Does your organization have an assigned security response team?"
            //             }
            //         },
            //         "question_3": {
            //             "name": "question_3",
            //             "type": "radiogroup",
            //             "items": [
            //                 {
            //                     "value": "yes",
            //                     "label": "Yes"
            //                 },
            //                 {
            //                     "value": "no",
            //                     "label": "No"
            //                 }
            //             ],
            //             "label": "3. Are project teams aware of their security point(s) of contact and response team(s)?",
            //             "builder": {
            //                 "type": "radiogroup",
            //                 "label": "3. Are project teams aware of their security point(s) of contact and response team(s)?"
            //             }
            //         },
            //         "question_4": {
            //             "name": "question_4",
            //             "type": "radiogroup",
            //             "items": [
            //                 {
            //                     "value": "yes",
            //                     "label": "Yes"
            //                 },
            //                 {
            //                     "value": "no",
            //                     "label": "No"
            //                 }
            //             ],
            //             "label": "4. Does the organization utilize a consistent process for incident reporting and handling?",
            //             "builder": {
            //                 "type": "radiogroup",
            //                 "label": "4. Does the organization utilize a consistent process for incident reporting and handling?"
            //             }
            //         },
            //         "question_5": {
            //             "name": "question_5",
            //             "type": "radiogroup",
            //             "items": [
            //                 {
            //                     "value": "yes",
            //                     "label": "Yes"
            //                 },
            //                 {
            //                     "value": "no",
            //                     "label": "No"
            //                 }
            //             ],
            //             "label": "5. Are project stakeholders aware of relevant security disclosures related to their software projects?",
            //             "builder": {
            //                 "type": "radiogroup",
            //                 "label": "5. Are project stakeholders aware of relevant security disclosures related to their software projects?"
            //             }
            //         }
            //     }
            // }


            // 

        }
    }
}
</script>
