<template>
    <div class="h-full px-4">
        <BreadcrumbComponent class="px-3 md:px-4 xl:px-5 py-1" :items="breadcrumb_items" />


        <div class="row h-full mt-4 px-3 md:px-4 xl:px-5">
            <div class="w-full  py-2 px-1 ">
                <div class="h-full">
                    <h2 class="page-title">Create New Code Audit Project</h2>
                    <p class="page-description">
                        Protostars B2B SaaS integrations work by using APIs and requires little to no programming. APIs
                        enable
                        SaaS applications to exchange information by using predetermined rules and protocols. APIs
                        authenticate
                        applications to establish trust before authorizing any data transfer.</p>

                    <CardPartial class="card-form-container-protostars mb-2 "
                        :card_class="' mx-auto lg:w-7/12 md:w-9/12 sm:w-10/12 w-11/12 p-4 mt-8'"
                        >
                        <CardHeader :header_class="'w-full mx-auto !pb-0'">
                            <h2 class="card-title">SaaS Project Details</h2>
                        </CardHeader>
                        <CardBody >

                            <Vueform add-class="vueFormContainer" :float-placeholders="false" v-model="formData"
                                validate-on="change|step" class=" w-full mx-auto" ref="auditFormRef">


                                <TextElement name="title" label="Title" placeholder="Enter title" :field-name="'Title'"
                                    rules="required" class="mb-2" />

                                <StaticElement name="welcomeH3" tag="h3" :content="'Integrations'"
                                    :attrs="{ class: ['sub-title-h3', 'mb-2', 'mt-4'] }" />


                                <TextElement name="github" placeholder="Installation ID"
                                    :field-name="'Github installation ID'" rules="required" class="mb-3">
                                    <template #addon-before>
                                        <img src="@/assets/images/protostars/github-icon.png" width="20" height="20" />
                                    </template>
                                </TextElement>
                                <ol class="col-span-12 ps-5 mt-1 space-y-1 list-decimal list-inside marker:text-primary dark:marker:text-primary-400 text-sm font-normal text-grey-protostars dark:text-grey-protostars-50">
                                    <li>Navigate to the <a href="https://github.com/apps/protostars-code-analyser" target="_blank" class="text-primary dark:text-primary-400 hover:underline">Protostars code analyzer GitHub app</a></li>
                                    <li>Click on the "Install" button</li>
                                    <li>Select the organization/account you would like to grant it access</li>
                                    <li>Select the "only select repositories" then click on the "Select repositores"
                                        dropdown, then select only the
                                        repository that you would like to analyse</li>
                                    <li>Click on the "Install & Authorize" button</li>
                                    <li>Wait a moment for Protostars to analyse your code and provide you with your
                                        "Installation ID" then paste it
                                        in the field above</li>
                                </ol>



                                <StaticElement name="other_platforms_title"
                                    :attrs="{ class: ['sub-title-h3', 'mb-0', 'mt-8'] }"
                                    content='  <div class="w-full mx-auto flex items-center  py-0 text-sm text-primary dark:text-primary-400 text-center"><span class="mx-auto">You’ll be able to integrate the next platforms soon</span></div>'>
                                </StaticElement>



                                <TextElement name="bitbucket" placeholder="Repository Link"
                                    :field-name="'Bitbucker link'" class="" :disabled="true">
                                    <template #addon-before>
                                        <img src="@/assets/images/protostars/bitbucket-icon.png" width="20"
                                            height="20" />
                                    </template>
                                </TextElement>
                                <TextElement name="swagger" placeholder="API Documentation Link"
                                    :field-name="'API documentation link'" class="" :disabled="true">
                                    <template #addon-before>
                                        <img src="@/assets/images/protostars/swagger-icon.png" width="20" height="20" />
                                    </template>
                                </TextElement>
                                <TextElement name="amazon" placeholder="Technical Infrastructure Link"
                                    :field-name="'Amazon link'" class="" :disabled="true">
                                    <template #addon-before>
                                        <img src="@/assets/images/protostars/aws-icon.png" width="20" height="20" />
                                    </template>
                                </TextElement>
                                <TextElement name="google" placeholder="Technical Infrastructure Link"
                                    :field-name="'Google link'" class="" :disabled="true">
                                    <template #addon-before>
                                        <img src="@/assets/images/protostars/google-icon.png" width="20" height="20" />
                                    </template>
                                </TextElement>


                            </Vueform>
                            <div class="w-full mx-auto mb-4 mt-8 flex justify-between">
                                <button class="btn-protostars btn-secondary-outline dark:btn-secondary"
                                    @click="clickCancel">
                                    Cancel</button>
                                <button class="btn-protostars btn-primary  " @click="clickNext">
                                    Next</button>

                            </div>
                        </CardBody>
                    </CardPartial>



                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';
// // import AudioRecorder from 'vue-audio-recorder'
// import { initFlowbite } from 'flowbite'
import axios from 'axios';

export default {
    name: "CreateAudit",
    components: {},
    data() {
        let temp = this;
        return {
            formData: null,

            is_loading: false,

            breadcrumb_items: [
                {
                    icon: 'fa-solid fa-house',
                    // text:'',
                    to: '/dashboard'
                },
                {
                    text: 'Audits',
                    to: '/audits/list'
                },
                {
                    text: 'Create Audit'
                }
            ],

        }
    },
    mounted() {
        // setTimeout(() => {
        //     initFlowbite();
        // }, 1500);

        console.log(this.$refs.auditFormRef)

    },
    beforeUnmount() {
        // this.stopRecording(); // Cleanup resources before component unmounts
    },
    updated() {
    },
    watch: {
    },
    methods: {
        clickCancel() {
            this.$router.push('/audits/list')
        },
        clickNext() {
            let audit_form = this.$refs.auditFormRef
            audit_form.validate()
            let form_errors = audit_form.formErrors
            if (form_errors.length > 0) {
                console.log("not valid")

            } else {
                console.log('validated')
                this.is_loading = true

                console.log(this.formData)

                axios
                    .post("audits/create", {
                        "title": this.formData.title,
                        "installation_id": this.formData.github,
                        client: 'PROTOSTARS',
                    }, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            client: 'PROTOSTARS',
                            Authorization:
                                "Bearer " +
                                JSON.parse(window.localStorage.getItem("user")).access_token,
                        }
                    })
                    .then((response) => {
                        console.log(response);
                        console.log(response.data);

                        console.log("success")
                        this.$router.push('/audits/create_audit/social_media/' + response.data.audit.id)
                        // window.localStorage.setItem("user", JSON.stringify(response.data));
                        // window.localStorage.setItem("user_type", "user");
                        // // this.$router.push({ name: "SituationAnalysisIndex" });

                        // this.$router.push({ path: '/situation_analysis' })
                        // .then(() => { this.$router.go(0) })


                    })
                    .catch((error) => {
                        console.log(error.response.data.error);
                        console.log(error.request);
                        console.log(error.message);
                        // this.loginErrorMessage = error.response.data.error;
                        // this.showLoginErrorMessage = true;

                        this.$swal({
                            // title: "Title",
                            text: error.response.data.error,
                            icon: "error",
                            // timer: 3000,
                            customClass: {
                                htmlContainer: "!text-2xl",
                                confirmButton: "!bg-grey-protostars",
                            },
                        }).then((r) => {
                            // console.log(r.value);
                            this.is_loading = false;
                        });
                    });
            }



            // this.$router.push('/audits/create_audit/social_media')
        },
    }
}
</script>
