<template>
    <div class="h-full px-4">
        <div class="row h-full mt-4 px-3 md:px-4 xl:px-5">
            <div class="w-full  py-2 px-1 ">
                <div class="h-full xl:">
                    <ChangePasswordCard :client_name="client_name" />

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { inject } from 'vue';
import ChangePasswordCard from '@/components/auth/ChangePasswordCard.vue';

export default {
    name: "ChangePassword",
    components: { ChangePasswordCard },
    data() {
        let temp = this;
        return {
            client_name: 'PROTOSTARS',

            isDark: inject('isDark'),
        }
    },
    mounted() {
    },
    updated() {
    },
    watch: {
    },
    methods: {
    }
}
</script>

<style></style>