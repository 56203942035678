<template>
    <div class="h-full">
        <!-- <navbar-protostars class="!border-b-2 !border-b-[#e5e7eb] !border-solid	"></navbar-protostars> -->


        <div class="w-10/12 mx-auto " v-if="myForm != null">

            <Vueform add-class="vueFormContainer"  :float-placeholders="false" v-model="formData" validate-on="change|step" class="">

                <slot name="empty">
                    <!-- <FormMessages v-if="showMessages" />
                    <FormErrors v-if="showErrors" />
                    <FormLanguages v-if="showLanguages" /> -->
                    <FormBuilderStepsStructure v-if="myForm.form.steps" :myForm="myForm" />
                    <FormBuilderTabsStructure v-else-if="myForm.form.tabs" :myForm="myForm" />
                    <FormElements>

                    </FormElements>
                </slot>

                <div class=" min-h-98 col-span-12 " v-if="!myForm.form.steps && !myForm.form.tabs">
                    <FormBuilderSchema :schema_obj="myForm.schema" />
                </div>


            </Vueform>

            <div class="w-10/12 mx-auto my-4 h-20">
                <div class="w-full flex justify-between">
                    <!-- <router-link to="/situation_analysis"
                        class="btn btn-protostars bg-yellow-protostars text-white px-4 py-2 rounded-lg ">Back</router-link> -->
                    <router-link to="/form_builder"
                        class="btn btn-protostars bg-yellow-protostars text-white px-4 py-2 rounded-lg ">Back</router-link>
                    <router-link to="/vue_flow"
                        class="btn btn-protostars btn-primary text-white px-4 py-2 rounded-lg ">Continue</router-link>
                        
                </div>
                <!-- {{ formData }} -->
            </div>

        </div>
        <!-- <hr />
        {{ fileRecords }}
        <hr />
        {{ rawFileRecords }} -->
    </div>
</template>


<script>
// import { FwbButton, FwbTooltip, FwbCard,
//     FwbAccordion,
//   FwbAccordionContent,
//   FwbAccordionHeader,
//   FwbAccordionPanel } from 'flowbite-vue'
// import { FwbButton, FwbModal,FwbTooltip } from 'flowbite-vue'
// import { initFlowbite } from 'flowbite'

import FormBuilderSchema from '@/components/form_builder/FormBuilderSchema.vue';
import FormBuilderStepsStructure from '@/components/form_builder/FormBuilderStepsStructure.vue';
import FormBuilderTabsStructure from '@/components/form_builder/FormBuilderTabsStructure.vue';

// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';

export default {
    name: "TestFormBuilderOutput",
    components: { FormBuilderSchema, FormBuilderStepsStructure, FormBuilderTabsStructure },//{ FwbButton, FwbModal,FwbTooltip },
    data() {
        let temp = this;
        return {
            myForm: null,
            isShowModal: false,
            formData: null,
            testeditor: null,

            fileRecords: null,
            rawFileRecords: null,
            fileRecords2: null,
            rawFileRecords2: null,
        }
    },
    mounted() {
        // console.log(localStorage.getItem('vueform-builder'))
        this.myForm = JSON.parse(localStorage.getItem('vueform-builder'))
        console.log(localStorage.getItem('situation_analysis'))
        // this.myForm = JSON.parse(localStorage.getItem('situation_analysis'))
        console.log(this.myForm)

        // initFlowbite()

        console.log("refs")
        console.log(this.$refs)
        console.log(this.$refs.vueformmmmmm)
        
            window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    updated() {

        console.log("refs")
        console.log(this.$refs)
        console.log(this.$refs.vueformmmmmm)
        console.log(this.formData)
    },
    methods: {

        closeModal() {
            this.isShowModal = false
        },
        showModal() {
            this.isShowModal = true
        }
    },
    // updated() {
    //     console.log(this.fileRecords)
    //     console.log(this.rawFileRecords)
    // }

}
</script>
<!-- 
<style >
.form-steps:before {
    display: none !important
}

.form-slider label+div {
    margin-top: 30px;
}

.wizard {
    display: grid;
    grid-template-columns: 100% 1fr;
    font-family: "Roboto", sans-serif;
    width: 85%;
    margin: auto;

    overflow-y: auto;
    height: calc(100% - 105px);
}


.process {
    box-model: border-box;
    /* align-self: center; */
    justify-self: center;
    padding: 15px 0 30px;
    margin: 0;
    width: 90%;
}

.process ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.process ul li.step .name {
    display: flex;
    align-items: center;
    color: #293042;

    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.process ul li.step .name span {
    /* background: #83A1B2; */
    background: white;
    border-radius: 50px;
    /* margin-left: 15px; */
    padding: 3px 12px;
    max-width: 80%;
}

.process ul li.step .name:before {
    content: "";
    width: 28px;
    height: 28px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
    background: white;
    z-index: 9999999999;
}


.process ul li.step.completed .name span {
    background: #90BE6D;
    /* color: #005c9f; */
}


.process ul li.step.completed .name:before {
    content: "✓";
    color: #293042;
    font-size: 1em;
    padding: 5px 3px;
    text-align: center;
    background: #90BE6D;
}

.process ul li.step.invalid-step .name span {
    background: #dd3d3d !important;
    /* color: #005c9f; */
}


.process ul li.step.invalid-step .name:before {
    color: white;
    font-size: 1em;
    padding: 5px 3px;
    text-align: center;
    background: #dd3d3d !important;
}

.process ul li.step.current .name span {
    /* color: #E74700; */
    /* background: #E68D56; */
    background: #61FBFE;
}


.process ul li.step.current .name:before {
    background: #61FBFE;
    box-shadow: 0 0 18px #61FBFE;
    animation: 1750ms linear infinite pulse;
}

.process ul li.step:not(:last-child):after {
    height: 80px;
    width: 10px;
    content: "";
    display: block;
    background-color: #fff;
    margin-left: 9px;
    margin-top: -20px;
    margin-bottom: -20px;
    opacity: 0.3;
}

.process ul li.step.current:not(:last-child):after {
    background-color: #61FBFE;
}

.process ul li.step.completed:not(:last-child):after {
    background-color: #90BE6D;
}


.process.done ul li.step .name {
    color: #33673B;
}

.process.done ul li.step .name:before {
    background: #33673B;
    box-shadow: none;
    content: "✓";
    color: white;
    font-size: 0.8em;
    text-align: center;
}

.process.done ul li.step:not(:last-child):after {
    background-color: #33673B;
    box-shadow: 0 0 8px #88c492;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 2px #61fbfea8;
    }

    80% {
        box-shadow: 0 0 15px #61fbfea8;
    }

    100% {
        box-shadow: 0 0 2px #61fbfea8;
    }
}


@media screen and (min-width: 2000px) {

    .process {
        padding: 30px 0;
        width: auto
    }

    .process ul li.step .name span {
        max-width: none;
    }

    .wizard {
        margin: 40px auto;
    }
}
</style> -->
