<template>
    <div>
        <highcharts class="hc" :options="pieChartOptions" ref="piechart"></highcharts>
    </div>
</template>


<script>

import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import drilldown from "highcharts/modules/drilldown";
import stockInit from "highcharts/modules/stock";
// import variablepie from "highcharts/modules/variable-pie"


exportingInit(Highcharts)
drilldown(Highcharts)
stockInit(Highcharts)

// Highcharts.setOptions({
//             colors: ['#000','#fff','#f00']
//         });
// const colors = Highcharts.getOptions().colors.map((c, i) =>
//     // Start out with a darkened base color (negative brighten), and end
//     // up with a much brighter color
//     Highcharts.color(Highcharts.getOptions().colors[3])
//         .brighten((i - 1) / 20)
//         .get()
// );

export default {
    name: 'PieChart',
    components: {
    },
    props: ["title", "subtitle","caption", "series_name", "series_data", "colors"],
    data() {
        let temp = this;
        return {

            //pie chart
            pieChartOptions: {
                chart: {
                    // plotBackgroundColor: '#f0f',
                    // backgroundColor: 'transparent',

                    type: 'pie',
                    style: {
                        fontFamily: "'Poppins', sans-serif"
                    },
                },
                caption:{
                    text: this.caption,
                    align:"left",
                    verticalAlign:"top"
                },
                title: {
                    text: this.title,
                    align: 'left',

                },
                subtitle: {
                    // text: 'Click the slices to view versions. ',
                    text: this.subtitle,
                    align: 'left'
                },
                credits: {
                    enabled: false
                },

                accessibility: {
                    // announceNewData: {
                    //     enabled: false
                    // },
                    // point: {
                    //     valueSuffix: '%'
                    // }
                },

                plotOptions: {
                    series: {
                        borderRadius: 5,
                        innerSize: '40%',
                        // dataLabels: {
                        //     enabled: true,
                        //     // format: '{point.name}: <b>{point.percentage:.1f}%</b>'
                        // },

                    },

                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<span style="font-size:13px; font-weight:400">{point.name} </span><br><b>{point.y}</b>',
                            distance: 20
                        },
                        showInLegend: true
                    }
                    // pie: {
                    //     allowPointSelect: true,
                    //     borderWidth: 2,
                    //     cursor: 'pointer',
                    //     // dataLabels: {
                    //     //     enabled: true,
                    //     //     format: '<b>{point.name}</b><br>{point.percentage}%',
                    //     //     distance: 20
                    //     // }
                    // }
                },
                lang: {
                    thousandsSep: ','
                },

                // tooltip: {
                //     headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                //     pointFormat: '<span >{point.name}</span>: <b>{point.y:.2f}</b> <br/> <b>{point.percentage:.1f}% </b> of total',
                //     formatter: function () {
                //         return convert(this.y)
                //         // return Highcharts.numberFormat(this.y, {
                //         //     thousandsSeparator: ","
                //         // });
                //     }
                // },
                tooltip: {
                    headerFormat: '<span style="font-size:15px">{point.key} </span><br>',
                    pointFormat: '<b>{point.y}</b>',
                    // formatter: function () {
                    //     console.log(this)
                    //     return this.y
                    //     // return Highcharts.numberFormat(this.y, {
                    //     //     thousandsSeparator: ","
                    //     // });
                    // }
                },



                series: [
                    {
                        name: this.series_name,
                        colorByPoint: true,
                        colors: this.colors,
                        data: this.series_data,

                        dataLabels: {
                            enabled: true,
                            format: '<span style="font-size:13px; font-weight:400">{point.name}</span><br><b>{point.y}</b>',
                            distance: 28,
                            //
                            style: {
                                textOutline: false
                            }

                            //
                        },
                    }
                ],
            }
        }
    },
    mounted() {
        console.log(this.colors)
    },
    watch: {
        series_data: {
            handler(newval, oldVal) {
                console.log(newval)
                this.pieChartOptions.series[0].data = newval

            },
            deep: true
        },
    },
    methods: {

    }


}

function convert(val) {
    // Thousands, millions, billions etc..
    let s = ["", "K", "M", "B", "T"];

    // Dividing the value by 3.
    let sNum = Math.floor(("" + val).length / 3);

    // Calculating the precised value.
    let sVal = parseFloat(
        (sNum != 0
            ? val / Math.pow(1000, sNum)
            : val
        ).toPrecision(2)
    );

    if (sVal % 1 != 0) {
        sVal = sVal.toFixed(1);
    }

    const i = Math.floor(Math.log(val) / Math.log(1000))

    let temp = `${parseFloat((val / Math.pow(1000, i)).toFixed(2))} ${s[i]}`
    // Appending the letter to precised val.
    // console.log(sVal + s[sNum], "----", temp)
    return temp;//sVal + s[sNum];
}



</script>
