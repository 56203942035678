<template>
    <li>
        <slot></slot>
    </li>
</template>

<script>
export default {
    name: "DropdownItem",
    props: {
        class: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>