<template>
    <div class="h-full px-4">

        <!-- <nav class="w-full flex" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li class="">
                    <a href="#"
                        class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                        <font-awesome-icon icon="fa-solid fa-house" />
                    </a>
                </li>
                <li>
                    <div class="flex items-center">
                        /
                        <a href="#"
                            class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">Dashboard</a>
                    </div>
                </li>
                <li aria-current="page">
                    <div class="flex items-center">
                        /
                        <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Audits</span>
                    </div>
                </li>
            </ol>
        </nav> -->

        <BreadcrumbComponent class="px-3 md:px-4 xl:px-5 py-1" :items="breadcrumb_items" />
      
      
        <div class="w-full mt-4 px-3 md:px-4 xl:px-5 py-2  ">

            <section class="tabs-container w-full ">

                <fwb-tabs v-model="activeTab" variant="underline" class="py-4">
                    <fwb-tab name="first" title="Employees">
                        <UsersList />
                    </fwb-tab>
                    <fwb-tab name="second" title="Teams">
                       <TeamsList />
                    </fwb-tab>
                </fwb-tabs>
            </section>
        </div>

     
    </div>
</template>


<script>
// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';
// // import AudioRecorder from 'vue-audio-recorder'
import { initFlowbite } from 'flowbite'
// import axios from 'axios';
import UsersList from './users/List.vue'
import TeamsList from './teams/List.vue'

// import TabContent from '@/components/partials/tabs/TabContent.vue';
// import TabsContainer from '@/components/partials/tabs/TabsContainer.vue';

import { FwbTab, FwbTabs } from 'flowbite-vue'

export default {
    name: "UserManagementIndex",
    components: {
        UsersList, TeamsList,
        // TabContent, TabsContainer,
        FwbTabs, FwbTab
    },
    data() {
        let temp = this;
        return {
            breadcrumb_items: [
                {
                    icon: 'fa-solid fa-house',
                    // text:'',
                    to: '#'
                },
                {
                    text: 'User Management'
                }
            ],


            activeTab: 'first'
        }
    },
    mounted() {
        setTimeout(() => {
            initFlowbite();
        }, 500);
    },
    beforeUnmount() {
    },
    updated() {
    },
    watch: {
    },
    methods: {
    }
}
</script>
