<template>
    <div class="h-full px-4">

        <!-- <nav class="w-full flex" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li class="">
                    <a href="#"
                        class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                        <font-awesome-icon icon="fa-solid fa-house" />
                    </a>
                </li>
                <li>
                    <div class="flex items-center">
                        /
                        <a href="#"
                            class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">Dashboard</a>
                    </div>
                </li>
                <li aria-current="page">
                    <div class="flex items-center">
                        /
                        <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Audits</span>
                    </div>
                </li>
            </ol>
        </nav> -->
        <BreadcrumbComponent class="px-3 md:px-4 xl:px-5 py-1" :items="breadcrumb_items" />


        <div class="w-full mt-4 px-3 md:px-4 xl:px-5 ">
            <CardPartial class="mb-2 " :card_class="'dark:border-2 dark:border-grey-protostars-100'"
                
               >
                <CardHeader :header_class="'border-b-2 border-b-z border-b-grey-protostars-50 lg:h-20 h-auto flex align-middle  dark:border-b-grey-protostars-100'">
                    <div class="row align-middle m-auto w-full">
                        <div class="lg:w-7/12 md:w-6/12 w-full md:flex md:align-middle md:items-center md:mb-0 mb-4">
                            <Vueform class="w-full" add-class="vueFormContainer vueFormFilter"
                                :float-placeholders="false" v-model="searchData">
                                <TextElement name="search" inputType="search" placeholder="Search">

                                    <template #addon-before>
                                        <font-awesome-icon class="addon" icon='fa-solid fa-magnifying-glass' />
                                    </template>
                                </TextElement>
                            </Vueform>
                        </div>
                        <div
                            class=" lg:w-5/12 md:w-6/12 w-full md:flex md:align-middle md:justify-end md:items-center md:mb-0 mb-2">
                            <button href="/audits/create_audit" class="btn-protostars bg-blue text-white lg:float-right"
                                @click="clickAddAudit">Create
                                Audit</button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody  :body_class="'!py-1 !px-0'">
                    <div class="relative overflow-x-auto  mt-5">
                        <table class="w-full table-protostars">
                            <thead class="">
                                <tr>
                                    <th scope="col" class="">
                                        Audit Project
                                    </th>
                                    <th scope="col" class="">
                                        Audit By
                                    </th>
                                    <th scope="col" class="">
                                        Created On
                                    </th>
                                    <th scope="col" class="text-center">
                                        Result
                                    </th>

                                </tr>
                            </thead>
                            <tbody class="" v-if="audits_list.length > 0">
                                <tr class="cursor-pointer" v-for="(audit_item, index) in audits_list" :key="index"
                                    @click="clickViewAudit(audit_item.id)">
                                    <th scope="row" class="">
                                        {{ audit_item.title }}
                                    </th>
                                    <td class="">
                                        {{ audit_item.user.name }}
                                    </td>
                                    <td class="">
                                        {{ formatDate(audit_item.result_object.created_at) }}
                                    </td>
                                    <td class="">
                                        <span class="status-span" :class="audit_item.result_object.status">
                                            {{ audit_item.result_object.status }}

                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="" v-else>
                                <tr>
                                    <td colspan="4" class="text-center">No data found</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div class="mt-6 mb-2 w-full row justify-between px-4 pb-4">
                        <div class="lg:w-1/2 w-full flex items-center lg:mt-2 mt-5">
                            <p class="dataset-info w-full lg:text-left text-center">Showing 1 to 7 of 100 entries
                            </p>
                        </div>
                        <div class="lg:w-1/2 w-full flex lg:mt-2 mt-5">
                            <div class="w-auto max-w-full inline-flex items-center lg:float-right lg:mr-0 mx-auto">
                                <vue-awesome-paginate class="paginate-container float-right float-"
                                    :total-items="audits_list.length" :items-per-page="per_page" :max-pages-shown="3"
                                    :show-ending-buttons="true" v-model="currentPage" :on-click="onClickHandler">

                                    <template #prev-button>
                                        <font-awesome-icon icon="fa-solid fa-angle-left" />
                                    </template>
                                    <template #next-button>
                                        <font-awesome-icon icon="fa-solid fa-angle-right" />
                                    </template>
                                    <template #first-page-button>
                                        <font-awesome-icon icon="fa-solid fa-angles-left" />
                                    </template>
                                    <template #last-page-button>
                                        <font-awesome-icon icon="fa-solid fa-angles-right" />
                                    </template>

                                </vue-awesome-paginate>
                            </div>
                        </div>

                    </div>
                </CardBody>
            </CardPartial>

        </div>

    </div>
</template>

<script>

// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';
// // import AudioRecorder from 'vue-audio-recorder'
// import { initFlowbite } from 'flowbite'
import axios from 'axios';
import moment from 'moment'

export default {
    name: "CreateAudit",
    components: {},
    data() {
        let temp = this;
        return {
            breadcrumb_items: [
                {
                    icon: 'fa-solid fa-house',
                    // text:'',
                    to: '/dashboard'
                },
                {
                    text: 'Audits'
                }
            ],

            searchData: { search: null },

            currentPage: 1,
            per_page:10,

            audits_list: [
                // { "title": "SaaS Project Title", "audit_by": "Sara", "created_on": "18 Apr 2024", "status": "success", "status_name": "Completed" },
                // { "title": "SaaS Project Title", "audit_by": "Mohamed", "created_on": "20 Feb 2024", "status": "warning", "status_name": "Data Protection and Privacy" },
                // { "title": "SaaS Project Title", "audit_by": "Kim", "created_on": "2 Jan 2024", "status": "danger", "status_name": "Software Code Risk Assessment" },
            ]

        }
    },
    mounted() {
        // setTimeout(() => {
        //     initFlowbite();
        // }, 1500);
        console.log(this.$route)
        console.log(this.$refs.auditFormRef)

        this.getAuditsList()

    },
    beforeUnmount() {
        // this.stopRecording(); // Cleanup resources before component unmounts
    },
    updated() {
    },
    watch: {
    },
    methods: {
        clickAddAudit() {
            this.$router.push("/audits/create_audit")
        },

        getAuditsList() {
            axios
                .get("audits/list?per_page="+this.per_page, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: 'PROTOSTARS',
                        Authorization:
                            "Bearer " +
                            JSON.parse(window.localStorage.getItem("user")).access_token,
                    },
                })
                .then(async (response) => {
                    console.log(response);

                    this.audits_list = response.data

                    // for (let i=0; i<this.audits_list.length; i++) {
                        // this.audits_list[i].status = "success"
                        // this.audits_list[i].status_name = "Completed"
                    // }

                    this.is_loading = false
                })
                .catch((error) => {
                    console.log(error)
                    this.is_loading = false;
                    // let error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    // if (error_message == null || error_message == '') {
                    //     error_message = 'Sorry, '
                    // }
                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }
                    this.$swal({
                        // title: "Title",
                        text: error_message,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-grey-protostars",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });

        },
        clickViewAudit(id){
            console.log("in click view audit", id)
            this.$router.push("/audits/audit_steps/"+id)
        },
        formatDate(date) {
            return moment(date).format("DD MMM YYYY")
        },
        onClickHandler(){
            console.log("click page", this.currentPage)

        }
    }
}
</script>
