<template>
    <LayoutAuth>
        <ResetPasswordCard :client_name="'PROTOSTARS'"
            :logo_src="isDark ? require('@/assets/images/protostars/logo-light.png') : require('@/assets/images/protostars/logo.png')"
            :base_url="'/protostars'" />
    </LayoutAuth>
</template>

<script>
import { inject } from 'vue';

import LayoutAuth from '@/components/Layout/LayoutAuth.vue';
import ResetPasswordCard from '@/components/auth/ResetPasswordCard.vue'

export default {
    name: "ResetPassword",
    components: { LayoutAuth, ResetPasswordCard },
    data() {
        let temp = this;
        return {
            client_name: 'PROTOSTARS',

            isDark: inject('isDark'),
        }
    },
    mounted() {
    },
    updated() {
    },
    watch: {
    },
    methods: {
    }
}
</script>
