<template>
    <!-- <div class="card p-4 w-full">
        <div class="row">
            <div class="w-7/12">
                <h2 class="font-Poppins font-semibold text-3xl/normal text-[#282828] mb-2">{{ value }}</h2>
                <p class="font-Poppins font-normal text-base text-[#6D6E71] mb-2">{{ title }}</p>
            </div>
            <div class="w-5/12 self-center text-right">
                <img :src="img_src" class="w-10"/>
            </div>
        </div>
    </div> -->
    <CardPartial class="" >
        <CardBody >
            <div class="flex items-center  overflow-hidden w-full justify-center ">
                <div class="w-8/12  text-gray-700">
                    <p
                        class="font-Poppins font-semibold text-4xl/normal text-black-protostars-500 dark:text-white tracking-wider ">
                        {{ value }}</p>
                    <h3
                        class="font-Poppins font-normal text-sm text-grey-protostars-400 dark:text-grey-protostars-100 mb-2 ">
                        {{ title }}</h3>
                </div>
                <div class="w-4/12 py-4  relative"> <img :src="img_src" class="w-12 h-12 object-cover float-right" /></div>
            </div>
        </CardBody>
    </CardPartial>

    <!-- <div class="w-[274px] h-[132px] bg-white rounded-lg shadow justify-center items-center gap-[26px] inline-flex">
    <div class="flex-col justify-start items-start inline-flex">
        <div class="text-zinc-800 text-[40px] font-semibold font-['Poppins']">54</div>
        <div class="w-[164px] text-neutral-500 text-sm font-normal font-['Poppins']">No. of Audits Projects</div>
    </div>
    <div class="w-12 h-12 relative"></div>
</div> -->

</template>

<script>
export default {
    name: "CardComponent",
    components: {},
    props: ['title', 'value', 'img_src'],

    data() {
        return {
        }
    }

}
</script>