<template>
    <div class="card card-auth">
        <img :src="logo_src" class="logo-img" />

        <Vueform add-class="vueFormContainer" :endpoint="false" :float-placeholders="false" v-model="formData" validate-on="change|step"
            class="" ref="resetPasswordFormRef" :remove-class="{ div: ['grid'] }">
            <StaticElement name="welcomeH3" tag="h3" :content="'Reset password'" :attrs="{ class: ['title-h3'] }" />
            <StaticElement name="welcomeText" tag="p"
                :content="'Enter your new password to log in to your account now.'" :attrs="{ class: ['text-p'] }" />


            <TextElement name="new_password" :input-type="show_password ? 'text' : 'password'" label="New Password"
                placeholder="Enter password" :field-name="'Password'" class="mb-3" :rules='["required", "min:8", "at_least_uppercase", "at_least_lowercase",
                    "at_least_number", "at_least_special"]'>
                <template #addon-after>
                    <button type="button" @click="show_password = !show_password" class="addon-button">
                        <font-awesome-icon icon="fa-solid fa-eye" v-if="!show_password" />
                        <font-awesome-icon icon="fa-solid fa-eye-slash" v-else />
                    </button>
                </template>
            </TextElement>
            <TextElement name="confirm_password" :input-type="show_confirm_password ? 'text' : 'password'"
                label="Re-enter New Password" placeholder="Reenter your password" :field-name="'confirm password'"
                :rules='["required", "same:new_password"]' class="mb-3">
                <template #addon-after>
                    <button type="button" @click="show_confirm_password = !show_confirm_password" class="addon-button">
                        <font-awesome-icon icon="fa-solid fa-eye" v-if="!show_confirm_password" />
                        <font-awesome-icon icon="fa-solid fa-eye-slash" v-else />
                    </button>
                </template>
            </TextElement>

            <HiddenElement name="client" :default="client_name" />
            <HiddenElement name="code" :default="code" />

            <ButtonElement button-label="Confirm" :submits="true" :button-class="['btn-protostars', 'btn-auth-submit']"
                full :loading="is_loading" @click="clickConfirm" />

        </Vueform>
        {{ code }}
        {{ formData }}
    </div>

</template>

<script>
import { inject } from 'vue';
import AuthService from '@/services/AuthService';

export default {
    name: "ResetPasswordCard",
    props: {
        client_name: {
            type: String,
        },
        logo_src: {
            type: String
        },
        base_url: {
            type: String
        }
    },
    data() {
        let temp = this;
        return {

            formData: null,

            is_loading: false,
            code: window.sessionStorage.getItem("code"),

            isDark: inject('isDark'),
            show_password: false,
            show_confirm_password: false,

        }
    },
    mounted() {
        this.code = window.sessionStorage.getItem("code");
    },
    updated() {
    },
    watch: {
    },
    methods: {
        clickConfirm() {
            // this.$router.push("/situation_analysis")


            let code_form = this.$refs.resetPasswordFormRef
            code_form.validate()
            let form_errors = code_form.formErrors
            if (form_errors.length > 0) {
                console.log("not valid")

            } else {
                console.log('validated')
                this.is_loading = true
                this.sendCodeForm()
            }


        },

        async sendCodeForm() {
            let response = await AuthService.sendResetPasswordRequest(this.formData, this.client_name)
            console.log("reset password code card")
            console.log(response)

            if (response.code == 200) {
                // window.sessionStorage.setItem("email", this.formData.email);
                // window.sessionStorage.setItem("user", JSON.stringify(response.response.user));
                // window.sessionStorage.setItem("user_type", "user");
                // this.$router.push({ name: "ChooseVerificationMethod" });
                // this.$router.push({ name: "ResetPassword" });

                // this.$router.push({ name: "LoginView" });

                this.$swal({
                    theme: "",
                    // title: "Title",
                    text: response.response.success,
                    icon: "success",
                    // timer: 3000,

                }).then((r) => {
                    // console.log(r.value);
                    this.$router.push({ name: "LoginView" });
                    this.is_loading = false;
                });

            } else {
                this.$swal({
                    theme: "",
                    // title: "Title",
                    text: response.response.error_message,
                    icon: "error",
                    // timer: 3000,

                }).then((r) => {
                    // console.log(r.value);
                    this.is_loading = false;
                });

            }

            this.is_loading = false

        }

    }
}
</script>
