<template>



    <div class="h-full-height  px-4 pb-10 mb-5">
        <OverlayLoading :is_loading="is_loading">

                <BreadcrumbComponent class="px-3 md:px-4 xl:px-5 py-1" :items="breadcrumb_items" />

                <div class="w-full h-full mt-4 px-3 md:px-4 xl:px-5">

                    <h2 class="page-title">Security Audit</h2>
                    <p class="page-description">Lorem ipsum dolor sit amet, consectetur
                        adipiscing
                        elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque
                        sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis
                        tellus.
                        Nullam
                        quis
                        imperdiet augue.</p>


                    <div v-if="audit_steps_code != null" id="accordion-collapse" data-accordion="collapse"
                        class="mt-4 mb-8" data-active-classes="!border-blue-400 dark:!border-blue-protostars border-2">
                        <div v-for="(item, index) in audit_steps" :key="index">
                            <h2 :id="'accordion-collapse-heading-' + (index + 1)">
                                <!-- <button type="button"
                            class="flex items-center justify-between w-full 
                            p-5 font-medium rtl:text-right text-gray-500 border 
                            border-b-0 border-gray-200 rounded-t-xl focus:ring-4 
                            focus:ring-gray-200 dark:focus:ring-gray-800 
                            dark:border-gray-700 dark:text-gray-400 
                            hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
                            data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                            aria-controls="accordion-collapse-body-1">
                            ></button> -->
                                <button type="button" class=" mt-3 flex items-center justify-between w-full 
                        p-5 font-medium rtl:text-right text-black-protostars border  
                        border-gray-200 rounded-xl focus:ring-4 
                        focus:ring-gray-200 dark:focus:ring-gray-800 
                        dark:border-gray-700 dark:text-white 
                        hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 bg-white  dark:bg-black-protostars 
                        " :data-accordion-target="'#accordion-collapse-body-' + (index + 1)"
                                    :aria-expanded="index == 3"
                                    :aria-controls="'accordion-collapse-body-' + (index + 1)">
                                    <span class="text-base/5 text-black-protostars dark:text-white">{{ item.title
                                        }}</span>
                                    <div>
                                        <span class="mr-2 text-sm/4 ">

                                            <span v-if="item.status == 'danger'" class="mr-3 text-[#FF4D4D]"> {{
                                                item.status_msg
                                            }}</span>
                                            <font-awesome-icon v-if="item.status == 'success'"
                                                icon="fa-solid fa-circle-check" size="xl" class="text-[#00E89D]" />
                                            <font-awesome-icon v-if="item.status == 'warning'"
                                                icon="fa-solid fa-circle-check" size="xl" class="text-[#FFCC05]" />
                                            <font-awesome-icon v-if="item.status == 'danger'"
                                                icon="fa-solid fa-circle-xmark" size="xl" class="text-[#FF4D4D]" />
                                        </span>
                                        <svg data-accordion-icon="" class="w-6 h-6 shrink-0 rotate-180 inline-block"
                                            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd"></path>
                                        </svg>
                                    </div>
                                </button>
                            </h2>
                            <div :id="'accordion-collapse-body-' + (index + 1)" class="hidden"
                                :aria-labelledby="'accordion-collapse-heading-' + (index + 1)">
                                <div class="p-5 relative">
                                    <template v-if="item.is_code">

                                        <!-- Nested accordion -->
                                        <div id="accordion-nested-collapse" data-accordion="collapse"
                                            :data-active-classes="'border-2 !border-primary bg-white text-primary hover:!bg-white hover:!text-primary dark:!border-primary dark:bg-black-protostars dark:text-primary dark:hover:!bg-black-protostars dark:hover:!text-primary'">
                                            <template
                                                v-for="(desc_item, desc_key, desc_index) in item.description_standard"
                                                :key="index+'_'+desc_index">
                                                <!-- {{ desc_key }} -->
                                                <template v-for="(standard_item, stand_key, stand_index) in desc_item"
                                                    :key="index+'_'+desc_index+'_'+stand_index">
                                                    <h2
                                                        :id="'accordion-nested-collapse-heading-' + desc_index + '-' + stand_index">
                                                        <!--bg-[#729EE980] dark:bg-[#729EE9]  hover:bg-primary dark:hover:bg-primary-->
                                                        <button type="button"
                                                            class="flex items-center justify-between w-full p-5 text-left rounded-xl font-medium rtl:text-right text-sm text-gray-900 border-2 mb-3 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-white hover:text-black-protostars dark:hover:text-black-protostars gap-3  "
                                                            :class="standard_item.implemented == true ? '!border-2 !border-green-protostars bg-white dark:bg-black-protostars hover:!bg-green-protostars hover:!border-green-protostars dark:border-2 dark:border-green-protostars dark:hover:border-green-protostars-500' : '!border-2 !border-danger-400   bg-white dark:bg-black-protostars hover:!bg-danger-500 hover:!border-danger-500      dark:border-2 dark:border-danger-500 dark:hover:border-danger'"
                                                            :data-accordion-target="'#accordion-nested-collapse-body-' + desc_index + '-' + stand_index"
                                                            aria-expanded="false"
                                                            :aria-controls="'accordion-nested-collapse-body-' + desc_index + '-' + stand_index">
                                                            <span>{{ stand_key }} {{ standard_item.implemented }}</span>
                                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0"
                                                                aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                                fill="none" viewBox="0 0 10 6">
                                                                <path stroke="currentColor" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2"
                                                                    d="M9 5 5 1 1 5" />
                                                            </svg>
                                                        </button>
                                                    </h2>
                                                    <div :id="'accordion-nested-collapse-body-' + desc_index + '-' + stand_index"
                                                        class="hidden"
                                                        :aria-labelledby="'accordion-nested-collapse-heading-' + desc_index + '-' + stand_index">
                                                        <div class="px-5 py-1.5 mb-2">
                                                            <template v-if="standard_item.implemented">
                                                                <p
                                                                    class="font-Poppins font-medium text-base/normal text-[#293042] dark:text-white">
                                                                    <font-awesome-icon icon="fa-solid fa-square-check"
                                                                        size="lg" style="color: #00E89D;" />
                                                                    You follow this standard in the following code
                                                                    section:
                                                                </p>

                                                                <Codemirror v-if="standard_item.implemented"
                                                                    class="mt-1"
                                                                    :ref="'codeMirror_issue_' + index + '_' + desc_index"
                                                                    :id="'codeMirror_issue_' + index + '_' + desc_index"
                                                                    v-model:value="standard_item.description"
                                                                    :options="cmOptions" border :height="300" />
                                                            </template>
                                                            <template v-else>
                                                                <p
                                                                    class="font-Poppins font-medium text-base/normal text-[#293042] dark:text-white mt-1">

                                                                    <font-awesome-icon icon="fa-solid fa-circle-xmark"
                                                                        size="lg" style="color: #FF4D4D;" />
                                                                    You don’t follow this standard. We recommend you fix
                                                                    the
                                                                    following file: <span class="text-primary"> {{
                                                                        desc_key
                                                                    }}</span>
                                                                </p>
                                                            </template>


                                                        </div>
                                                    </div>
                                                </template>
                                            </template>

                                        </div>
                                        <!-- End: Nested accordion -->


                                    </template>
                                    <template v-else>
                                        <template v-for="(desc_item, desc_index) in item.description"
                                            :key="index+'_'+desc_index">
                                            <div class="flex w-full justify-between">
                                                <h3 v-if="desc_item.title"
                                                    class="block lg:w-10/12 md:w-9/12 w-full mb-2 font-Poppins font-semibold text-base/normal text-[#293042] dark:text-white ">
                                                    {{ desc_item.title }}
                                                </h3>
                                                <div v-else>
                                                    <!-- {{desc_item.files_download}} -->
                                                    <p v-for="(file_item, index) in desc_item.files_download" :key="index"
                                                        class="mb-2 font-Poppins font-medium text-base/normal text-primary dark:text-primary hover:underline">
                                                        <a :href="file_item.path" target="_blank"  :download="file_item.title+'.json'"> {{ file_item.title }}</a>
                                                    </p>
                                                    <p 
                                                        class="mt-4 mb-2 font-Poppins font-medium text-base/normal text-primary dark:text-primary hover:underline">
                                                        <router-link :to="desc_item.link_more">Click here for more information</router-link>
                                                    </p>

                                                </div>
                                                <div class="block lg:w-2/12 md:w-3/12 w-full">
                                                    <button
                                                        class=" text-blue-700 text-base font-medium font-Poppins px-0 float-right"
                                                        :data-popover-target="'popover-add-comment-' + index + '-' + desc_index"
                                                        data-popover-placement="left" data-popover-trigger="click">
                                                        <font-awesome-icon icon="fa-solid fa-circle-plus" /> Add
                                                        Comment</button>
                                                    <div data-popover
                                                        :id="'popover-add-comment-' + index + '-' + desc_index"
                                                        role="tooltip"
                                                        class="absolute z-10 invisible inline-block w-64 p-2 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                                                        <div class="relative ">
                                                            <Vueform add-class="vueFormContainer"
                                                                :float-placeholders="false" v-model="comment_form"
                                                                :ref="'commentFormRef_' + index + '_' + desc_index">
                                                                <TextareaElement name="new_comment" :autogrow="true"
                                                                    :rows="1" placeholder="Add comment">
                                                                    <template #addon-after>
                                                                        <button type="button"
                                                                            @click="clickAddComment(index, desc_index, 1)">
                                                                            <img src='@/assets/images/send-icon.png'
                                                                                class='w-4 h-4' />
                                                                        </button>
                                                                    </template>
                                                                </TextareaElement>
                                                            </Vueform>


                                                        </div>
                                                        <div data-popper-arrow></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="flex w-full">
                                                <div class="block w-8/12">
                                                </div>
                                                <div class=" w-4/12 ml-3 pb-4 px-6 relative h-10">
                                                    <div v-if="desc_item.comments != null && desc_item.comments.length > 0"
                                                        class="absolute top-1 left-0 inline-flex  rounded-xl rounded-bl-none bg-white shadow-md px-3 py-1 w-auto cursor-pointer"
                                                        :data-popover-target="'popover-click-' + index + '-' + desc_index"
                                                        data-popover-trigger="click"
                                                        data-popover-placement="bottom-start">
                                                        <template
                                                            v-for="(comment_item, comment_index) in desc_item.comments"
                                                            :key="comment_index">
                                                            <div v-for="(user, user_index) in comment_item.users"
                                                                :key="user_index"
                                                                class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden mr-1 rounded-full dark:bg-gray-600"
                                                                :style="'background-color: ' + colors[user_index % colors.length]">
                                                                <span
                                                                    class="font-semibold text-black-protostars dark:text-black-protostars">{{
                                                                        user.name.charAt(0) }}</span>
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <div v-if="desc_item.comments != null && desc_item.comments.length > 0"
                                                        data-popover :id="'popover-click-' + index + '-' + desc_index"
                                                        role="tooltip"
                                                        class="absolute z-10 invisible inline-block w-80 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                                                        <div class="card py-4 px-6 overflow-y-auto max-h-[600px] ">
                                                            <div class="users_avatar mb-2">

                                                                <template
                                                                    v-for="(comment_item, comment_index) in desc_item.comments"
                                                                    :key="comment_index">

                                                                    <div
                                                                        :class="comment_index != desc_item.comments.length - 1 ? 'border-b-2 pb-2 mb-2' : ''">

                                                                        <div class="flex w-full mt-2 mb-1">
                                                                            <h4
                                                                                class="font-Poppins text-base/5 font-semibold text-black w-auto mr-2">
                                                                                {{
                                                                                    comment_item.user }}</h4>
                                                                            <span
                                                                                class="font-Poppins text-xs/5 font-normal text-[#979797] w-auto">{{
                                                                                    comment_item.time }}</span>
                                                                        </div>
                                                                        <p
                                                                            class="font-Poppins text-sm/normal font-medium text-[#293042] mb-1">
                                                                            {{ comment_item.content }}
                                                                        </p>
                                                                        <span
                                                                            class="font-Poppins text-xs/normal font-normal text-[#979797] w-auto mb-2">{{
                                                                                comment_item.replies.length }}
                                                                            replies</span>

                                                                        <div class="ml-5">
                                                                            <ul class="ml-2">
                                                                                <li v-for="(reply_item, reply_index) in comment_item.replies"
                                                                                    :key="reply_index"
                                                                                    class="py-1 px-4 text-sm font-medium text-gray-800 dark:text-white">
                                                                                    <div class="flex w-full mt-2 mb-1">
                                                                                        <h4
                                                                                            class="font-Poppins text-base/5 font-semibold text-black w-auto mr-2">
                                                                                            {{ reply_item.user }}</h4>
                                                                                        <span
                                                                                            class="font-Poppins text-xs/5 font-normal text-[#979797] w-auto">
                                                                                            {{ reply_item.time }}</span>
                                                                                    </div>
                                                                                    <p
                                                                                        class="font-Poppins text-sm/normal font-medium text-[#293042] mb-1">
                                                                                        {{ reply_item.content }}
                                                                                    </p>
                                                                                </li>
                                                                            </ul>

                                                                            <div class="ml-2">
                                                                                <div class="relative px-4">

                                                                                    <input type="text"
                                                                                        v-model="comment_item.new_reply"
                                                                                        class="block w-full px-4 py-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white"
                                                                                        placeholder="Add reply"
                                                                                        required />
                                                                                    <button type="button"
                                                                                        class="absolute end-6 bottom-2.5"
                                                                                        @click="clickAddReply(index, desc_index, comment_index)"><img
                                                                                            src="@/assets/images/send-icon.png"
                                                                                            class="w-4 h-4" /></button>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </template>
                                                                <div class=" mt-5">
                                                                    <!-- <label for="search"
                                                        class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label> -->
                                                                    <div class="relative ">

                                                                        <input type="text" v-model="new_comment"
                                                                            class="block w-full px-4 py-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white"
                                                                            placeholder="Add comment" required />
                                                                        <!-- <button type="submit" class="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> -->

                                                                        <button type="button"
                                                                            @click="clickAddComment(index, desc_index, 0)"
                                                                            class="absolute end-2.5 bottom-2.5"><img
                                                                                src="@/assets/images/send-icon.png"
                                                                                class="w-4 h-4" /></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>



                                        </template>

                                    </template>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-else
                        class="w-10/12 mx-auto  mb-4 min-h-[300px] !h-8/12 flex justify-center justify-items-center items-center">
                        <div class="">
                            <span
                                class="text-gray-500 dark:text-grey-protostars-200 text-2xl font-semibold  leading-10">
                                There is no active audit at the
                                moment. You can
                                visit the audit
                                history list or create a new one.
                            </span>
                        </div>
                    </div>



                </div>
        </OverlayLoading>

    </div>
</template>

<script>
import { initFlowbite } from 'flowbite'

import { audits_steps } from './auditslist.js'

import axios from 'axios';
// import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'


import Codemirror, { CmComponentRef } from "codemirror-editor-vue3";
import { Editor, EditorConfiguration } from "codemirror";
import { ref } from "vue";

// placeholder
import "codemirror/addon/display/placeholder.js";

// language
import "codemirror/mode/javascript/javascript.js";
// placeholder
import "codemirror/addon/display/placeholder.js";
// theme
import "codemirror/theme/dracula.css";
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/display/autorefresh'

import moment from 'moment'


export default {
    name: "AuditSteps",
    components: { Codemirror },//, Popover, PopoverButton, PopoverPanel },
    data() {
        let temp = this;
        return {

            audit_steps: [],

            code: 'console.log("Hello World"); \nconsole.log("Hello World2");\nconsole.log("Hello World3");',
            cmOptions: {
                //mode: "text/javascript", // Language mode
                // theme: "dracula", // Theme
                lineNumbers: true,
                // firstLineNumber: 2,
                // highlightLines: [3],
                styleActiveLine: false,
                textWrapping: true,
                autoRefresh: true,
                readOnly: true,
                // gutters: ["CodeMirror-linenumbers", "highlightedLine"]

            },

            pluginExample: "<div>test</div>\n<span>ssss</span>",


            cmComponentRef: null,


            colors: ['#FFCC05', '#61FBFE', '#00FFAD'],

            new_comment: null,

            comment_form: {
                new_comment: null
            },

            audit_id: null,



            breadcrumb_items: [
                {
                    icon: 'fa-solid fa-house',
                    // text:'',
                    to: '#'
                },
                {
                    text: 'Dashboard',
                    to: '/dashboard'
                },
                {
                    text: 'Audits',
                    to: '/audits/list'
                },
                {
                    text: 'Create Audit'
                }
            ],
            audit_steps_code: null,

            is_loading: false

        }
    },
    mounted() {

        this.audit_steps = audits_steps
        // setTimeout(() => {
        //     initFlowbite();
        // }, 1500);



        this.is_loading = true

        console.log("iiiiiiiiiiii", this.$route.params.id)
        this.audit_id = this.$route.params.id
        if (this.audit_id == '' || this.audit_id == null) {
            axios
                .get("audits/list", {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: 'PROTOSTARS',
                        Authorization:
                            "Bearer " +
                            JSON.parse(window.localStorage.getItem("user")).access_token,
                    },
                })
                .then(async (response) => {
                    console.log(response);

                    let audits_list = response.data

                    if (audits_list.length > 0) {
                        this.audit_id = audits_list[0].id

                        this.audit_steps_code = audits_list[0].result_object.content
                        console.log(JSON.parse(this.audit_steps_code))

                        this.audit_steps[3].description_standard = JSON.parse(this.audit_steps_code)
                        setTimeout(() => {
                            initFlowbite();
                        }, 1500);

                    }

                    this.is_loading = false
                })
                .catch((error) => {
                    console.log(error)
                    this.is_loading = false;
                    // let error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    // if (error_message == null || error_message == '') {
                    //     error_message = 'Sorry, '
                    // }
                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }
                    this.$swal({
                        // title: "Title",
                        text: error_message,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-grey-protostars",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });


        } else {
            this.getSingleAuditDetails()
        }

        // console.log("cmComponentRef==")
        // console.log(this.cmComponentRef)
        // console.log(this.cmComponentRef.cminstance)
        // console.log(this.cmComponentRef.cminstance._value)
        // console.log(this.cmComponentRef)

    },
    beforeUnmount() {
    },
    updated() {
    },
    watch: {
    },
    methods: {
        getSingleAuditDetails() {
            console.log("get audit details", this.audit_id)

            axios
                .get("/audits/single/" + this.audit_id, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: 'PROTOSTARS',
                        Authorization:
                            "Bearer " +
                            JSON.parse(window.localStorage.getItem("user")).access_token,
                    },
                })
                .then(async (response) => {
                    console.log(response);

                    this.audit_steps_code = response.data.result_object.content
                    console.log(JSON.parse(this.audit_steps_code))

                    this.audit_steps[3].description_standard = JSON.parse(this.audit_steps_code)
                    setTimeout(() => {
                        initFlowbite();
                    }, 1500);

                    this.is_loading = false
                })
                .catch((error) => {
                    console.log(error)
                    this.is_loading = false;
                    // let error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    // if (error_message == null || error_message == '') {
                    //     error_message = 'Sorry, '
                    // }
                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }
                    this.$swal({
                        // title: "Title",
                        text: error_message,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-grey-protostars",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });

        },
        // highlighter(code) {
        //     console.log(highlight(code, languages.js))
        //     return highlight(code, languages.js); // languages.<insert language> to return html with markup
        // },
        highlightCode(code_ref, lines_highlight, class_name) {
            console.log("highlightCode", code_ref, lines_highlight)
            console.log(Editor)
            console.log(Codemirror)
            console.log(this.$refs)

            let cmComponentRef = this.$refs[code_ref][0]

            console.log("cmComponentRef==")
            console.log(cmComponentRef)

            for (let i = 0; i < lines_highlight.length; i++) {
                cmComponentRef.cminstance.doc.markText({ line: lines_highlight[i] - 1, ch: 0 }, { line: lines_highlight[i], ch: 0 }, { className: class_name })

            }
        },
        handleCursorActivity(instance) {
            console.log("iiiiiiii")
            console.log(instance)
            // Get the current line number
            const currentLine = instance.getCursor().line;
            console.log(currentLine)

            // Add a custom class to the current line
            instance.addLineClass(currentLine, "wrap", "my-custom-class");
        },

        clickAddComment(audit_step_index, desc_index, is_vue_form) {
            console.log(this.audit_steps[audit_step_index])
            console.log(this.audit_steps[audit_step_index].description[desc_index])
            console.log(this.audit_steps[audit_step_index].description[desc_index].comments)
            this.audit_steps[audit_step_index].description[desc_index].comments.push(
                {
                    users: [{ name: 'Sara' }],
                    content: is_vue_form == 1 ? this.comment_form.new_comment : this.new_comment,
                    user: 'Sara',
                    time: moment(new Date()).format("ddd, DD MMM YYYY"),
                    replies: []
                }
            )

            console.log(this.$refs['commentFormRef_' + audit_step_index + '_' + desc_index])

            setTimeout(() => {
                initFlowbite();
            }, 1500);

            this.comment_form.new_comment = null
            this.new_comment = ''
        },
        clickAddReply(index, desc_index, comment_index) {
            console.log("in click add reply", index, desc_index, comment_index)

            this.audit_steps[index].description[desc_index].comments[comment_index].replies.push(
                {
                    content: this.audit_steps[index].description[desc_index].comments[comment_index].new_reply,
                    user: 'Sara',
                    time: moment(new Date()).format("ddd, DD MMM YYYY"),
                }
            )
            this.audit_steps[index].description[desc_index].comments[comment_index].new_reply = ''


        },


    }
}
</script>

<style>
.highlighted {
    background: red;
}

.danger {
    background: #ED6B6BA1;
}

.success {
    background: #8AFFB2;

}

/* required class */
/* .my-editor {
    background: #2d2d2d;
    color: #ccc;

    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
} */

/* optional class for removing the outline */
/* .prism-editor__textarea:focus {
    outline: none;
}
*/
/* .marked {
    background-color: #ffdd99;
} 

pre.line-numbers {
	position: relative;
	padding-left: 3.8em;
	counter-reset: linenumber;
}

pre.line-numbers > code {
	position: relative;
	white-space: inherit;
}

.line-numbers .line-numbers-rows {
	position: absolute;
	pointer-events: none;
	top: 0;
	font-size: 100%;
	left: -3.8em;
	width: 3em; 
	letter-spacing: -1px;
	border-right: 1px solid #999;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

}

	.line-numbers-rows > span {
		display: block;
		counter-increment: linenumber;
	}

		.line-numbers-rows > span:before {
			content: counter(linenumber);
			color: #999;
			display: block;
			padding-right: 0.8em;
			text-align: right;
		} */
</style>
