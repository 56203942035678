<template>
    <div class="h-full px-4">

        <BreadcrumbComponent :items="breadcrumb_items" />

        <div class="w-full mt-4 px-3 md:px-4 xl:px-5">
            <FormBuilderComponent :base_url="'/questionnaires'"/>
        </div>
    </div>
</template>

<script>
import FormBuilderComponent from '@/components/form_builder/FormBuilderComponent.vue'

export default {
    name: "QuestionnaireCreateForm",
    components: {
        FormBuilderComponent
    },
    data() {
        let temp = this;
        return {
            breadcrumb_items: [
                {
                    icon: 'fa-solid fa-house',
                    // text:'',
                    to: '/dashboard'
                },
                {
                    to: '/questionnaires',
                    text: 'Questionnaires'
                },
                {
                    text: 'Create Questionnaire'
                }
            ],

        }
    },
    mounted() {
        // setTimeout(() => {
        //     initFlowbite();
        // }, 1500);

    },
    beforeUnmount() {
        // this.stopRecording(); // Cleanup resources before component unmounts
    },
    updated() {
    },
    watch: {
    },
    methods: {
    }
}
</script>
