<template>
    <div class="flex justify-between mb-1">
        <span class="text-base font-medium text-blue-700 dark:text-white"> </span>
        <!-- <span class="text-sm font-medium text-blue-700 dark:text-white">6/83</span> -->
        <span class="bg-sky-100 text-blue-700 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-sky-100 dark:text-blue-700">{{ value_text }}</span>

    </div>
    <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-indigo-50">
    <div class="bg-blue-600 dark:bg-blue-protostars h-2.5 rounded-full" :style="'width: '+value+'%'"></div>
</div>    
</template>

<script>
export default {
  name:"ProgressBar",
  props:{
    value:{
      required: true,
      type: Number
    }, 
    value_text:{
      required: false,
      type: String
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>