<template>
    <div class="card card-auth">
        <img :src="logo_src" class="logo-img" />
        <Vueform add-class="vueFormContainer" :endpoint="false" :float-placeholders="false" v-model="formData" validate-on="change|step"
            class="" ref="codeFormRef" :remove-class="{ div: ['grid'] }">
            <StaticElement name="welcomeH3" tag="h3" :content="'Two-Factor Authentication'"
                :attrs="{ class: ['title-h3', 'mb-6'] }" />

            <TextElement name="code" input-type="text" label="Code" placeholder="Enter code" :field-name="'Code'"
                rules="required" class="mb-3" />

            <HiddenElement name="client" :default="client_name" />
            <HiddenElement name="email" :default="email" />

            <ButtonElement button-label="Verify" :submits="true" :button-class="['btn-protostars', 'btn-auth-submit']"
                full :loading="is_loading" @click="clickVerify" />

        </Vueform>

        <div class="auth-text-link" v-if="selected_method == 'email'">
            <span class="">If you haven't received it
            </span>
            <span class="font-semibold">{{ countDown }} seconds </span>
            <span class="">


                <button :disabled="countDown != 0" class="brn-protostars btn-link" @click="clickResend">
                    <SpinnerLoading v-if="is_loading_resend" /> Click here
                </button>

            </span>
        </div>



    </div>
</template>

<script>
import { inject } from 'vue';
import AuthService from '@/services/AuthService';

export default {
    name: "LoginCodeCard",
    props: {
        client_name: {
            type: String,
        },
        logo_src: {
            type: String
        },
        base_url: {
            type: String
        }
    },
    data() {
        return {
            formData: null,

            is_loading: false,
            is_loading_resend: false,

            selected_method: null,
            email: window.sessionStorage.getItem("email"),

            isDark: inject('isDark'),

            countDown: 60
        }
    },
    created() {
        this.countDownTimer()
    },
    mounted() {
        this.email = window.sessionStorage.getItem("email");
        this.selected_method = this.$route.params.method
    },
    updated() {
    },
    watch: {
    },
    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
        },

        clickVerify() {
            let code_form = this.$refs.codeFormRef
            code_form.validate()
            let form_errors = code_form.formErrors
            if (form_errors.length > 0) {
                console.log("not valid")
            } else {
                console.log('validated')
                this.is_loading = true
                this.sendCodeForm()
            }
        },

        async sendCodeForm() {
            console.log("in send code request")
            let formDataRequest = {
                code: this.formData.code,
                user_email: this.email,
                user_type: "u",
                client: this.client_name,
            }
            console.log(formDataRequest)

            let response = null
            if (this.selected_method == 'app') {
                console.log("verify by app", this.email)

                response = await AuthService.sendVerifyLoginCodeByAppRequest(formDataRequest, this.client_name)
                console.log("verify code app -  card")
                console.log(response)
            } else if (this.selected_method == 'email') {
                console.log("verify by  email", this.email)

                response = await AuthService.sendVerifyLoginCodeByEmailRequest(formDataRequest, this.client_name)
                console.log("verify code email -  card")
                console.log(response)
            }
            console.log(response)

            if (response.code == 200) {
                window.localStorage.setItem("user", window.sessionStorage.getItem("user"));
                window.localStorage.setItem("user_type", "user");
                window.localStorage.setItem("last_update", new Date().getTime());
                sessionStorage.clear()
                this.$router.go({ name: "DashboardIndex" });
            } else {
                this.$swal({
                    theme: "",
                    text: response.response.error_message,
                    icon: "error",

                }).then((r) => {
                    this.is_loading = false;
                });

            }

            this.is_loading = false

        },
        async clickResend() {
            console.log("RE Send email", this.email)
            this.is_loading_resend = true
            let formDataRequest = {
                user_email: this.email,
                user_type: "u",
                client: this.client_name,
            }
            console.log(formDataRequest)

            let response = await AuthService.sendVerifyCodeEmailRequest(formDataRequest, this.client_name)
            console.log("choose verify method card")
            console.log(response)

            if (response.code == 200) {
                this.$swal({
                    text: response.response.success,
                    type: "success",
                }).then((r) => {
                    this.countDown = 60
                    this.countDownTimer()

                });
            } else {
                this.$swal({
                    theme: "",
                    text: response.response.error_message,
                    icon: "error",
                }).then((r) => {
                    this.is_loading_resend = false;
                });

            }
            this.is_loading_resend = false
        },

    }
}
</script>
