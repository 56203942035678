<template>
    <div class="relative flex mb-50">
        <div  v-if="is_loading" 
        class="absolute bg-white dark:bg-gray-800/70 bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <div class="flex items-center">
                <!-- <span class="text-3xl mr-4">Loading</span> -->
                <svg class="animate-spin h-8 w-8 text-gray-800 dark:text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                    </circle>
                    <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                    </path>
                </svg>
            </div>
        </div>

        <slot></slot>
        
    </div>
</template>


<script>
export default {
    name: "OverlayLoading",
    props: ['is_loading'],
    data() {
        return {
        }
    },
    methods: {

    }
}
</script>