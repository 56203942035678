<template>
    <div class="card card-auth ">
        <img :src="logo_src" class="logo-img" />
        <Vueform add-class="vueFormContainer" :endpoint="false" :float-placeholders="false" v-model="formData"
            validate-on="change|step" class="" ref="loginFormRef" :remove-class="{ div: ['grid'] }">
            <StaticElement name="welcomeH3" tag="h3" :content="'Welcome'" :attrs="{ class: ['title-h3'] }" />
            <StaticElement name="welcomeText" tag="p" :content="'Please sign-in to your account to view our services'"
                :attrs="{ class: ['text-p'] }" />

            <TextElement name="email" input-type="email" label="Email" placeholder="Enter email" :field-name="'Email'"
                rules="required" class="mb-3" />


            <TextElement name="password" :input-type="show_password ? 'text' : 'password'" label="Password"
                placeholder="Enter password" :field-name="'Password'" rules="required" class="mb-3" :floating="false">
                <template #addon-after>
                    <button type="button" @click="show_password = !show_password" class="addon-button">
                        <!-- <img v-if="!isDark" src='@/assets/images/view-icon.png' />
                        <img v-else src='@/assets/images/view-icon-dark.png' /> -->
                        <font-awesome-icon icon="fa-solid fa-eye" v-if="!show_password" />
                        <font-awesome-icon icon="fa-solid fa-eye-slash" v-else />
                    </button>
                </template>
            </TextElement>

            <StaticElement :override-class="{ 'container': [''], 'tag_a': ['form-link', 'float-right'] }">
                <router-link :to="base_url + '/forget_password'" class="form-link float-right">Forget
                    Password?</router-link>
            </StaticElement>

            <ButtonElement button-label="Login" :submits="true" :button-class="['btn-protostars', 'btn-auth-submit',]"
                full :loading="is_loading" @click="clickLogin" />

        </Vueform>

        <!-- <div class="auth-divider">
            <div class=""></div>
            OR
            <div class=""></div>
        </div>
        <div class="w-10/12 mx-auto mt-4 flex flex-col gap-6">
            <LoginSocialMedia />
        </div> -->

        <div v-if="add_register_link" class="auth-text-link">
            <span class="">New on our platform?
            </span>
            <span class="">
                <router-link to="/register">
                    Create an account
                </router-link>
            </span>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/AuthService';
// import LoginSocialMedia from './LoginSocialMedia.vue';
import { inject } from 'vue';

export default {
    name: "LoginCard",
    // components: { LoginSocialMedia },
    props: {
        client_name: {
            type: String,
        },
        logo_src: {
            type: String
        },
        add_register_link: {
            type: Boolean
        },
        base_url: {
            type: String
        }
    },
    data() {
        let temp = this;
        return {

            formData: null,

            is_loading: false,

            isDark: inject('isDark'),
            show_password: false,


        }
    },
    mounted() {
        console.log("loginFormRef")
        console.log(this.$refs.loginFormRef)
    },
    updated() {
    },
    watch: {
    },
    methods: {
        clickLogin() {
            let login_form = this.$refs.loginFormRef
            login_form.validate()
            let form_errors = login_form.formErrors
            if (form_errors.length > 0) {
                console.log("not valid")

            } else {
                console.log('validated')
                this.is_loading = true
                this.sendLoginForm()
            }
        },

        async sendLoginForm() {
            console.log("in login request")
            let response = await AuthService.sendLoginRequest(this.formData, this.client_name)
            console.log("login card")
            console.log(response)

            if (response.code == 200) {
                window.sessionStorage.setItem("email", this.formData.email);
                window.sessionStorage.setItem("user", JSON.stringify(response.response.user));
                window.sessionStorage.setItem("user_type", "user");
                this.$router.push({ name: "ChooseVerificationMethod" });
            } else {
                this.$swal({
                    theme: this.isDark ? "dark" :"",
                    // title: "Title",
                    text: response.response.error_message,
                    icon: "error",
                    // timer: 3000,

                }).then((r) => {
                    // console.log(r.value);
                    this.is_loading = false;
                });

            }

            this.is_loading = false
        }

    }
}
</script>
