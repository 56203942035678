// import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router";

// import { IStaticMethods } from "preline/preline";
// Window.HSStaticMethods = IStaticMethods;

//canvas
import AddCustomerCards from '@/views/value_proposition_canvas/AddCustomerCards.vue'
import CanvasView from '@/views/value_proposition_canvas/CanvasView.vue'

// Vue.use(Router)

//form builder
import TestFormBuilder from '@/views/form_builder/TestFormBuilder.vue'
import TestFormBuilderOutput from '@/views/form_builder/TestFormBuilderOutput.vue'
import PublicForm from '@/views/PublicForm.vue'

import QuestionnaireList from '@/views/questionnaire/List.vue'
import QuestionnaireCreateForm from '@/views/questionnaire/CreateForm.vue'
import QuestionnairePreviewForm from '@/views/questionnaire/PreviewForm.vue'
import QuestionnaireVueFlow from '@/views/questionnaire/VueFlow.vue'

// VueFlow
import TestVueFlow from '@/views/vue_flow/IndexTest.vue'
import FlowBuilderTest from '@/views/vue_flow/FlowBuilderTest.vue'

//clients
import RegisterView from '@/views/clients/RegisterView.vue'

// auth
import LoginView from '@/views/auth/Login.vue'
import ChooseVerificationMethod from '@/views/auth/ChooseVerificationMethod.vue'
import LoginCode from '@/views/auth/LoginCode.vue'
import ForgetPasswordView from '@/views/auth/ForgetPassword.vue'
import ResetPasswordCode from '@/views/auth/ResetPasswordCode.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import ChangePassword from '@/views/auth/ChangePassword.vue'

import SituationAnalysisIndex from '@/views/situation_analysis/IndexView.vue'

import SuccessPublishForm from '@/views/SuccessPublish.vue'

import AuditsList from '@/views/project_audit/AuditsList.vue'
import CreateAudit from '@/views/project_audit/CreateAudit.vue'
import CreateAuditSocial from '@/views/project_audit/CreateAuditSocial.vue'
import AuditProgess from '@/views/project_audit/AuditProgess.vue'
import AuditSteps from '@/views/project_audit/AuditSteps.vue'
import AuditResultsIndex from '@/views/project_audit/AuditResultsIndex.vue'

import DashboardIndex from '@/views/dashboard_reports/DashboardIndex'

// user management
import UserManagementIndex from '@/views/user_management/IndexUser.vue'


import GlossaryListIndex from '@/views/glossary/ListIndex.vue'

import { initFlowbite } from "flowbite";

const routes = [

  {
    path: '/',
    name: 'Home',
    component: AuditResultsIndex,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)

  },

  // canvas
  // {
  //   path: '/add_customer_needs',
  //   name: 'AddCustomerCards',
  //   component: AddCustomerCards
  // },
  // {
  //   path: '/value_canvas',
  //   name: 'CanvasView',
  //   component: CanvasView
  // },
  {
    path: '/canvas',
    children: [{
      path: '/add_customer_needs',
      name: 'AddCustomerCards',
      component: AddCustomerCards,
      beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
    },
    {
      path: '/value_canvas',
      name: 'CanvasView',
      component: CanvasView,
      meta: {
        background_class: 'background-body'
      },
      beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
    },]
  },

  {
    path: '/situation_analysis',
    name: 'SituationAnalysisIndex',
    component: SituationAnalysisIndex,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
  },
  {
    path: '/dashboard',
    name: 'DashboardIndex',
    component: DashboardIndex,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
  },

  // form builder
  {
    path: '/form_builder',
    name: 'TestFormBuilder',
    component: TestFormBuilder,
    meta: {
      title: "Form Builder Test",
      description: "Page to test VueForm builder"
    },
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
  }, {
    path: '/form_builder/output',
    name: 'TestFormBuilderOutput',
    component: TestFormBuilderOutput,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
  },
  {
    path: '/form/:id',
    name: 'PublicForm',
    meta: { is_public: true, with_navbar: true },
    component: PublicForm,
  },

  //vue flow
  {
    path: '/vue_flow',
    name: 'TestVueFlow',
    component: TestVueFlow,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
  },
  {
    path: '/flow_builder',
    name: 'FlowBuilderTest',
    component: FlowBuilderTest,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
  },

  {
    path: '/success_publish',
    name: 'SuccessPublishForm',
    component: SuccessPublishForm,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
  },

  //client
  {
    path: '/register',
    name: 'RegisterView',
    component: RegisterView,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  //auth
  {
    path: '/protostars/login',
    name: 'LoginView',
    component: LoginView,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  {
    path: '/protostars/choose_verification_method',
    name: 'ChooseVerificationMethod',
    component: ChooseVerificationMethod,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  {
    path: '/protostars/verify_login/:method',
    name: 'LoginCode',
    component: LoginCode,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  {
    path: '/protostars/forget_password',
    name: 'ForgetPasswordView',
    component: ForgetPasswordView,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  {
    path: '/protostars/reset_password_code',
    name: 'ResetPasswordCode',
    component: ResetPasswordCode,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  {
    path: '/protostars/reset_password',
    name: 'ResetPassword',
    component: ResetPassword,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  {
    path: '/change_password',
    name: 'ChangePassword',
    component: ChangePassword,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
  },

  // {
  //   path: '/create_audit',
  //   name: 'CreateAudit',
  //   component: CreateAudit,
  //   beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
  // },

  {
    path: '/audits',
    // component: User,
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'list',
        component: AuditsList,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'create_audit',
        component: CreateAudit,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },
      {
        path: 'create_audit/social_media/:id',
        component: CreateAuditSocial,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },
      {
        path: 'create_audit/in_progress/:id',
        component: AuditProgess,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },
      {
        path: 'audit_steps/:id?',
        // component: AuditSteps,
        component: AuditResultsIndex,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },
    ],
  },

  {
    path: '/questionnaires',
    // name: "QuestionnaireList",
    // component: QuestionnaireList,
    // beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),


    children: [
      {
        path: '',
        name: "QuestionnaireList",
        component: QuestionnaireList,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),
      },
      {
        path: 'create_questionnaire',
        name: 'QuestionnaireCreateForm',
        component: QuestionnaireCreateForm,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },
      {
        path: 'preview_form',
        component: QuestionnairePreviewForm,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },
      {
        path: ':id/vue_flow',
        component: QuestionnaireVueFlow,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },

      {
        path: ':form_id/success_publish',
        name: 'SuccessPublishQuestionnaire',
        component: SuccessPublishForm,
        beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
      },

    ]
  },
  {
    path: '/user_management',
    component: UserManagementIndex,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),
    // component: User,
    // children: [
    //   {
    //     // UserProfile will be rendered inside User's <router-view>
    //     // when /user/:id/profile is matched
    //     path: 'list',
    //     component: AuditsList,
    //     beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
    //   },
    //   {
    //     // UserPosts will be rendered inside User's <router-view>
    //     // when /user/:id/posts is matched
    //     path: 'create_audit',
    //     component: CreateAudit,
    //     beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
    //   },
    //   {
    //     path: 'create_audit/social_media',
    //     component: CreateAuditSocial,
    //     beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
    //   },
    //   {
    //     path: 'create_audit/in_progress',
    //     component: AuditProgess,
    //     beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
    //   },
    //   {
    //     path: 'audit_steps',
    //     component: AuditSteps,
    //     beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
    //   },
    // ],
  },

  {
    path: '/glossary',
    name: "GlossaryListIndex",
    component: GlossaryListIndex,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next),

  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  },
});
// router.beforeEach((to, from, next) => {
//   //  does the page we want to go to have a meta.progress object
//   if (to.meta.progress !== undefined) {
//     let meta = to.meta.progress;
//     // parse meta tags
//     this.$Progress.parseMeta(meta);
//   }
//   //  start the progress bar
//   this.$Progress.start();
//   //  continue to next page
//   next();
// });

router.afterEach((to, from, failure) => {
  console.log("this")
  console.log(this)
  if (!failure) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      initFlowbite()
    }, 100)

  }
  // this.$Progress.finish();

});


function checkIfAuthed(to, from, next) {
  // console.log(localStorage)
  // console.log(to)
  // console.log(from)
  // console.log(next)
  let user = localStorage.getItem('user')
  let user_type = localStorage.getItem("user_type")
  console.log(user)
  // console.log(user_type)
  // console.log(user_type == 'user', to.path.includes('c-risk'))
  // console.log("========================")
  if (user != null) {
    next({ name: 'DashboardIndex' })
  } else {
    next();

  }
}

function redirectIfNotAuthed(to, from, next, user_type) {
  // console.log(localStorage)
  let user = localStorage.getItem('user');
  let type = localStorage.getItem('user_type');
  // console.log(user)
  // console.log(type, user_type)
  // console.log(to.path.includes('c-risk'))
  // console.log("----------------------")
  console.log(to)
  console.log(from)
  console.log(next)
  if (user === null) {
    next({ name: 'LoginView' })

  } else {
    console.log(to.meta != null && to.meta.background_class != null)
    console.log(to.meta)
    console.log(to.meta.background_class)
    if (to.meta && to.meta.background_class) {
      console.log(to.meta.background_class)
      document.body.classList.add(to.meta.background_class);
      next()
    } else {
      document.body.classList.remove('background-body');

      next()
    }

  }
}


export default router
