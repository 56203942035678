<template>



    <div class="h-full-height mb-5">
        <OverlayLoading :is_loading="is_loading">

                <div class="w-full h-full mt-0 px-3 md:px-1 xl:px-1">

                    <h2 class="page-title">OWASP</h2>
                    <p class="page-description">
                        Security-focused LLM technologies enhance compliance with the EU Cyber Resilience Act for
                        Software CE marking. Protostars facilitates software security and compliance gap analysis for
                        NIS2 Directive and DORA in third-party software supply chains.
                    </p>

                    <!-- {{ audit_steps }} -->

                    <!-- {{ current_step }}, {{ activeTab }} -->
                    <!-------------------------------- --------------------------------->
                    <template v-if="status == 'complete'">
                        <div class="w-full bg-gray-200 rounded-full h-1.5 mt-4 mb-1 dark:bg-gray-700">
                            <div class="bg-primary h-1.5 rounded-full dark:bg-primary-400"
                                :style="'width: ' + (current_step / total_steps) * 100 + '%'"></div>
                        </div>
                        <div class="w-full  py-0.5 " v-if="audit_steps != null">
                            <section class="tabs-steps-section-container accordion-container w-full ">
                                <flowbite-themable>
                                    <!-- {{ audit_steps }} -->

                                    <fwb-tabs v-model="activeTab" variant="underline" class="py-4 tab-content">

                                        <fwb-tab class="test m-5"
                                            v-for="(chapter_item, index) in audit_steps" :key="index + 1"
                                            :name="'tab' + (index + 1)" title="">


                                            <CardPartial class="mb-2 w-full"
                                                :card_class="'dark:border-2 dark:border-grey-protostars-100'"
                                                :header_class="'hidden'" :body_class="'!py-1 !px-0'">
                                                
                                                <CardBody :body_class="'!py-1 !px-0'">
                                                    <div class="w-full flex justify-between align-middle">
                                                        <h2 :id="'accordion-collapse-owasp-heading-' + (index + 1)"
                                                            class="step-title w-8/12">
                                                            <!-- {{ chapter_item.title }} -->
                                                            {{ chapter_item.chapter_name }}
                                                        </h2>
                                                        <div v-if="chapter_item.chapter_name == 'Authentication'" class="w-4/12 flex items-center justify-end me-5 mt-3">
                                                            <span class="issues-alert-class ">
                                                                <span><font-awesome-icon
                                                                    icon="fa-solid fa-circle-xmark" size="lg" /> </span>
                                                                {{ chapter_item.no_issues }}
                                                                issues to be resolved</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="chapter_item.chapter_name == 'Authentication'"
                                                        :id="'accordion-collapse-owasp-body-' + (index + 1)">
                                                        <div class="step-content">
                                                            <!-- Nested accordion -->
                                                            <div :id="'accordion-nested-collapse-owasp-' + index"
                                                                data-accordion="collapse"
                                                                :data-active-classes="'active-section'">
                                                                <!-- <div v-for="(section_item, section_index) in chapter_item.sections"
                                                                :key="index + '_' + section_index"> -->
                                                                <template v-if="chapter_item">
                                                                    <template
                                                                        v-for="(section_item, section_index) in chapter_item.sections"
                                                                        :key="index+'_'+section_index">
                                                                        <h2 :id="'accordion-nested-collapse-owasp-heading-' + index + '-' + section_index"
                                                                            class="section-title">
                                                                            <button type="button" class=""
                                                                                :data-accordion-target="'#accordion-nested-collapse-owasp-body-' + index + '-' + section_index"
                                                                                aria-expanded="false"
                                                                                :aria-controls="'accordion-nested-collapse-owasp-body-' + index + '-' + section_index">
                                                                                <span class="w-9/12">
                                                                                    {{section_item.section_name }} 
                                                                                    <!-- {{ section_key }} -->
                                                                                </span>
                                                                                <div
                                                                                    class="w-3/12 flex items-end justify-end">
                                                                                    <span
                                                                                        class="issues-alert-class"> <span><font-awesome-icon
                                                                                            icon="fa-solid fa-circle-xmark"
                                                                                            size="lg" /> </span> {{
                                                                                                section_item.no_issues
                                                                                            }}
                                                                                        issues to be resolved</span>

                                                                                    <svg data-accordion-icon
                                                                                        class="w-3 h-3 rotate-180 shrink-0 my-auto"
                                                                                        aria-hidden="true"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        fill="none" viewBox="0 0 10 6">
                                                                                        <path stroke="currentColor"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"
                                                                                            stroke-width="2"
                                                                                            d="M9 5 5 1 1 5" />
                                                                                    </svg>
                                                                                </div>
                                                                            </button>
                                                                        </h2>
                                                                        <div :id="'accordion-nested-collapse-owasp-body-' + index + '-' + section_index"
                                                                            class="hidden"
                                                                            :aria-labelledby="'accordion-nested-collapse-owasp-heading-' + index + '-' + section_index">
                                                                            <div class="section-content">

                                                                                <!---------------------------------------------------------->

                                                                                <!-- Nested accordion - section-->
                                                                                <div :id="'accordion-nested-collapse-owasp-' + index + '-section-' + section_index"
                                                                                    data-accordion="collapse"
                                                                                    :data-active-classes="'active-section-nested-collapse'">
                                                                                    <!-- <div v-for="(req_item, req_index) in section_item.requirements"
                                                                                :key="index + '_' + section_index + '_' + req_index"> -->
                                                                                    <template
                                                                                        v-for="(req_item,  req_index) in section_item.all_requirements"
                                                                                        :key="index+'_'+section_index+'_'+req_index">

                                                                                        <h2 class="section-nested-collapse-title"
                                                                                            :id="'accordion-nested-collapse-owasp-heading-section-' + index + '-' + section_index + '-' + req_index">
                                                                                            <!--bg-[#729EE980] dark:bg-[#729EE9]  hover:bg-primary dark:hover:bg-primary-->
                                                                                            <!--                                                                    :class="req_item.implemented == true ? '!border-2 !border-green-protostars bg-white dark:bg-black-protostars hover:!bg-green-protostars hover:!border-green-protostars dark:border-2 dark:border-green-protostars dark:hover:border-green-protostars-500' : '!border-2 !border-danger-400   bg-white dark:bg-black-protostars hover:!bg-danger-500 hover:!border-danger-500      dark:border-2 dark:border-danger-500 dark:hover:border-danger'"-->
                                                                                            <button type="button"
                                                                                                class=" "
                                                                                                :data-accordion-target="'#accordion-nested-collapse-owasp-body-section-' + index + '-' + section_index + '-' + req_index"
                                                                                                aria-expanded="false"
                                                                                                :aria-controls="'accordion-nested-collapse-owasp-body-section-' + index + '-' + section_index + '-' + req_index">
                                                                                                <span class="w-9/12">
                                                                                                    {{req_item.name }}
                                                                                                    <!-- {{ req_key }} -->
                                                                                                </span>
                                                                                                <div
                                                                                                    class="w-3/12 flex items-end justify-end">
                                                                                                    <span
                                                                                                        class="issues-alert-class"><span><font-awesome-icon
                                                                                                            icon="fa-solid fa-circle-xmark"
                                                                                                            size="lg" /> </span>
                                                                                                        {{
                                                                                                            req_item.no_issues
                                                                                                        }} issues to be
                                                                                                        resolved</span>
                                                                                                    <svg data-accordion-icon
                                                                                                        class="w-3 h-3 rotate-180 shrink-0 inline-block my-auto"
                                                                                                        aria-hidden="true"
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                        fill="none"
                                                                                                        viewBox="0 0 10 6">
                                                                                                        <path
                                                                                                            stroke="currentColor"
                                                                                                            stroke-linecap="round"
                                                                                                            stroke-linejoin="round"
                                                                                                            stroke-width="2"
                                                                                                            d="M9 5 5 1 1 5" />
                                                                                                    </svg>
                                                                                                </div>
                                                                                            </button>
                                                                                        </h2>
                                                                                        <div :id="'accordion-nested-collapse-owasp-body-section-' + index + '-' + section_index + '-' + req_index"
                                                                                            class="hidden"
                                                                                            :aria-labelledby="'accordion-nested-collapse-owasp-heading-section-' + index + '-' + section_index + '-' + req_index">
                                                                                            <div
                                                                                                class="section-nested-collapse-content">
                                                                                                <template
                                                                                                    v-for="(req_code_item, req_code_index) in req_item.files"
                                                                                                    :key="req_code_index">
                                                                                                    <div v-if="req_code_item.implemented"
                                                                                                        class="section-code-container">
                                                                                                        <p
                                                                                                            class="font-Poppins font-medium text-base/normal text-[#293042] dark:text-white">
                                                                                                            <font-awesome-icon
                                                                                                                icon="fa-solid fa-square-check"
                                                                                                                size="lg"
                                                                                                                style="color: #00E89D;" />
                                                                                                            You follow
                                                                                                            this
                                                                                                            standard
                                                                                                            in the
                                                                                                            following
                                                                                                            code
                                                                                                            section in
                                                                                                            file:
                                                                                                            <span
                                                                                                                class="text-primary dark:text-primary-400">
                                                                                                                {{
                                                                                                                    req_code_item.name
                                                                                                                }}</span>
                                                                                                            <!-- <span
                                                                                                                class="text-primary dark:text-primary-400">
                                                                                                                {{
                                                                                                                    req_code_item.file_name
                                                                                                                }}</span> -->
                                                                                                        </p>

                                                                                                        <Codemirror
                                                                                                            v-if="req_code_item.implemented"
                                                                                                            class="mt-1"
                                                                                                            :ref="'codeMirror_issue_' + index + '_' + section_index + '_' + req_index + '_' + req_code_index"
                                                                                                            :id="'codeMirror_issue_' + index + '_' + section_index + '_' + req_index + '_' + req_code_index"
                                                                                                            v-model:value="req_code_item.code_snippet"
                                                                                                            :options="cmOptions"
                                                                                                            border
                                                                                                            :height="300" />
                                                                                                    </div>
                                                                                                    <div v-else
                                                                                                        class="section-code-container">
                                                                                                        <p
                                                                                                            class="font-Poppins font-medium text-base/normal text-[#293042] dark:text-white mt-1">

                                                                                                            <font-awesome-icon
                                                                                                                icon="fa-solid fa-circle-xmark"
                                                                                                                size="lg"
                                                                                                                style="color: #FF4D4D;" />
                                                                                                            You don’t
                                                                                                            follow
                                                                                                            this
                                                                                                            standard.
                                                                                                            We recommend
                                                                                                            you fix
                                                                                                            the
                                                                                                            following
                                                                                                            file:
                                                                                                            <!-- <span
                                                                                                                class="text-primary dark:text-primary-400">
                                                                                                                {{
                                                                                                                    req_code_item.file_name
                                                                                                                }}</span> -->
                                                                                                            <span
                                                                                                                class="text-primary dark:text-primary-400">
                                                                                                                {{
                                                                                                                    req_code_item.name
                                                                                                                }}</span>
                                                                                                        </p>
                                                                                                    </div>

                                                                                                </template>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!-- </div> -->
                                                                                    </template>

                                                                                </div>
                                                                                <!-- End: Nested accordion section-->

                                                                                <!---------------------------------------------------------->


                                                                            </div>
                                                                        </div>
                                                                        <!-- </div> -->
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <div
                                                                        class="w-10/12 mx-auto  mb-4 min-h-[300px] !h-8/12 flex justify-center justify-items-center items-center">
                                                                        <span
                                                                            class="text-gray-500 dark:text-grey-protostars-200 text-2xl font-semibold  leading-10">
                                                                            Implementation in progress, coming soon.
                                                                        </span>
                                                                    </div>
                                                                </template>

                                                            </div>
                                                            <!-- End: Nested accordion -->


                                                        </div>
                                                    </div>
                                                    <div v-else
                                                        class="w-10/12 mx-auto  mb-4 min-h-[300px] !h-8/12 flex justify-center justify-items-center items-center">
                                                        <span
                                                            class="text-gray-500 dark:text-grey-protostars-200 text-2xl font-semibold  leading-10">
                                                            Implementation in progress, coming soon.
                                                        </span>
                                                    </div>
                                                </CardBody>
                                            </CardPartial>


                                        </fwb-tab>
                                    </fwb-tabs>
                                </flowbite-themable>

                                <div class="w-full text-primary text-lg font-medium dark:text-grey-protostars-25 mb-8">
                                    <button class="w-15 float-start text-start " @click="clickBack"
                                        :disabled="current_step == 1"> Back</button>
                                    <button class="w-15 float-end text-end " @click="clickNext"
                                        :disabled="current_step == Object.keys(audit_steps).length">Next
                                    </button>
                                    <!-- {{ current_step }}
                                    {{ Object.keys(audit_steps).length }} -->
                                </div>
                            </section>

                        </div>
                    </template>
                    <div v-else
                        class="w-10/12 mx-auto  my-4 min-h-[300px] !h-8/12 flex justify-center justify-items-center items-center">
                        <div class="text-gray-500 dark:text-grey-protostars-200 text-2xl font-semibold  leading-10">
                            <span v-if="status == 'pending'">
                                The code analysis is still in progress, please check back later.

                            </span> <span v-else-if="status == 'failed'">
                                An unexpected error has occurred during the analysis
                                <!-- {{ fail_message }} -->
                            </span>
                        </div>
                    </div>
                    <!-------------------------------- --------------------------------->


                </div>
        </OverlayLoading>

    </div>
</template>

<script>
import { initFlowbite } from 'flowbite'
import { FwbTab, FwbTabs, FlowbiteThemable } from 'flowbite-vue'


import axios from 'axios';
// import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'


import Codemirror, { CmComponentRef } from "codemirror-editor-vue3";
import { Editor, EditorConfiguration } from "codemirror";
import { ref } from "vue";

// placeholder
import "codemirror/addon/display/placeholder.js";

// language
import "codemirror/mode/javascript/javascript.js";
// placeholder
import "codemirror/addon/display/placeholder.js";
// theme
import "codemirror/theme/dracula.css";
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/display/autorefresh'

import moment from 'moment'


export default {
    name: "AuditStepsComponent",
    components: {
        FwbTab, FwbTabs, FlowbiteThemable,
        Codemirror
    },//, Popover, PopoverButton, PopoverPanel },
    props: ["audit_id", "audit_steps", "audit_steps_code", "status", "fail_message"],
    data() {
        let temp = this;
        return {

            // audit_steps: [],

            code: 'console.log("Hello World"); \nconsole.log("Hello World2");\nconsole.log("Hello World3");',
            cmOptions: {
                //mode: "text/javascript", // Language mode
                // theme: "dracula", // Theme
                lineNumbers: true,
                // firstLineNumber: 2,
                // highlightLines: [3],
                styleActiveLine: false,
                textWrapping: true,
                autoRefresh: true,
                readOnly: true,
                // gutters: ["CodeMirror-linenumbers", "highlightedLine"]

            },

            cmComponentRef: null,

            colors: ['#FFCC05', '#61FBFE', '#00FFAD'],

            is_loading: false,

            activeTab: "tab4",
            // total_steps: this.audit_steps.length,
            total_steps: Object.keys(this.audit_steps).length,
            current_step: 4

        }
    },
    mounted() {
        // this.total_steps = this.audit_steps.length
        this.total_steps = Object.keys(this.audit_steps).length
        setTimeout(() => {
            initFlowbite();
        }, 1500);

    },
    beforeUnmount() {
    },
    updated() {
    },
    watch: {
    },
    methods: {

        clickNext() {
            if (this.current_step < this.total_steps) {
                this.current_step += 1
                this.activeTab = 'tab' + this.current_step
                setTimeout(() => {
                    initFlowbite();
                }, 500);
            }

        },
        clickBack() {
            if (this.current_step > 1) {
                this.current_step -= 1
                this.activeTab = 'tab' + this.current_step
                setTimeout(() => {
                    initFlowbite();
                }, 500);
            }
        },

        highlightCode(code_ref, lines_highlight, class_name) {
            console.log("highlightCode", code_ref, lines_highlight)
            console.log(Editor)
            console.log(Codemirror)
            console.log(this.$refs)

            let cmComponentRef = this.$refs[code_ref][0]

            console.log("cmComponentRef==")
            console.log(cmComponentRef)

            for (let i = 0; i < lines_highlight.length; i++) {
                cmComponentRef.cminstance.doc.markText({ line: lines_highlight[i] - 1, ch: 0 }, { line: lines_highlight[i], ch: 0 }, { className: class_name })

            }
        },
        handleCursorActivity(instance) {
            console.log("iiiiiiii")
            console.log(instance)
            // Get the current line number
            const currentLine = instance.getCursor().line;
            console.log(currentLine)

            // Add a custom class to the current line
            instance.addLineClass(currentLine, "wrap", "my-custom-class");
        },

    }
}
</script>

<style>
.highlighted {
    background: red;
}

.danger {
    background: #ED6B6BA1;
}

.success {
    background: #8AFFB2;

}
</style>
