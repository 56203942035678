<template>
    <div class=" ">
        <DashboardCustomer />
    </div>
</template>

<script>
import DashboardCustomer from './DashboardCustomer.vue';

export default {
    name:  "DashboardIndex",
    components:{DashboardCustomer}
}
</script>