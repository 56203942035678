<template>



    <div class="h-full-height  px-4 pb-10 mb-5">
        <BreadcrumbComponent :items="breadcrumb_items" />

        <OverlayLoading :is_loading="is_loading">

            <!-- <div class="w-full mt-2 px-3 md:px-4 xl:px-5 py-2  "> -->
            <div class="w-full  py-0.5 px-3 md:px-4 xl:px-4  ">
                <section class="tabs-container tabs-container-steps w-full mb-8 ">
                    <div class="w-full h-10" v-if="!display_audit_steps">
                        <button
                            class="text-grey-protostars dark:text-grey-protostars-25 w-40 float-start h-10 !px-0 !text-base !text-left"
                            @click="clickBackToAudits"><font-awesome-icon icon="fa-solid fa-arrow-left" />
                            Back</button>
                    </div>

                    <AuditStepsComponent v-if="display_audit_steps" :audit_id="audit_id" :audit_steps="audit_steps"
                        :audit_steps_code="audit_steps_code" @displayResults="changePageContent"
                        :status="owasp_status" />

                    <OWaspResultsComponent v-if="display_owasp" :audit_steps="owasp_results" :status="owasp_status"
                        :fail_message="fail_message" />

                    <!-- <flowbite-themable :theme="theme">

                            <fwb-tabs v-model="activeTab" variant="underline" class="py-4 tab-content">
                                <fwb-tab class="test m-10" name="audits"
                                    :title="icon_prev + '&#8592;  Step '+ current_step + ' of ' + total_steps">

                                    <AuditStepsComponent :audit_id="audit_id" :audit_steps="audit_steps"
                                        :audit_steps_code="audit_steps_code" />
                                </fwb-tab>
                                <fwb-tab class="test m-5" name="owasp"
                                    :title="current_step == total_steps ? 'Finish' : 'Next Step'">
                                    <OWaspResultsComponent :audit_steps="owasp_results" />


                                </fwb-tab>
                            </fwb-tabs>
                        </flowbite-themable> -->
                </section>
            </div>
        </OverlayLoading>

    </div>
</template>

<script>
import { initFlowbite } from 'flowbite'
// import { FwbTab, FwbTabs, FlowbiteThemable } from 'flowbite-vue'

import { audits_steps } from './auditslist.js'
import owasp_results_json from '@/assets/json/owasp_results.json'


import axios from 'axios';

import AuditStepsComponent from './AuditStepsComponent.vue';
import OWaspResultsComponent from './OWaspResultsComponent.vue';


export default {
    name: "AuditResultsIndex",
    components: {
        // FwbTab, FwbTabs, FlowbiteThemable,

        AuditStepsComponent, OWaspResultsComponent
    },
    data() {
        let temp = this;
        return {

            audit_steps: [],
            owasp_results: [],

            audit_id: null,

            breadcrumb_items: [
                {
                    icon: 'fa-solid fa-house',
                    // text:'',
                    to: '/dashboard'
                },
                {
                    text: 'Audits',
                    to: '/audits/list'
                },
                {
                    text: 'Create Audit'
                }
            ],
            audit_steps_code: null,

            is_loading: false,

            // activeTab: 'audits',
            // activeTab: 'owasp',
            // total_steps: 2,
            // current_step: 1,

            icon_prev: '',//'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>'
            theme: 'blue',


            display_owasp: false,
            display_audit_steps: true,

            owasp_status: "",
            fail_message: "",

            no_issues_chapter_auth: 0
        }
    },
    mounted() {

        this.audit_steps = audits_steps
        this.is_loading = true


        setTimeout(() => {
            initFlowbite();
        }, 500);

        console.log("iiiiiiiiiiii", this.$route.params.id)
        this.audit_id = this.$route.params.id

        ///////////////////////////////////////////////////

        console.log("owasp results")
        console.log(owasp_results_json)

        //////////////////////////////////////////////////


        if (this.audit_id == '' || this.audit_id == null) {
            axios
                .get("audits/list", {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: 'PROTOSTARS',
                        Authorization:
                            "Bearer " +
                            JSON.parse(window.localStorage.getItem("user")).access_token,
                    },
                })
                .then(async (response) => {
                    console.log(response);
                    console.log(JSON.parse(response.data[0].result_object.content))
                    console.log("-----------------------------------------------")

                    let audits_list = response.data
                    // this.formatAuditList(JSON.parse(response.data[0].result_object.content))

                    if (audits_list.length > 0) {
                        this.audit_id = audits_list[0].id

                        this.owasp_status = response.data[0].result_object.status
                        if (audits_list[0].result_object.content == 'failed') {
                            this.fail_message = audits_list[0].result_object.content
                        }
                        else {
                            this.audit_steps_code = audits_list[0].result_object.content
                        }
                        console.log(JSON.parse(this.audit_steps_code))

                        // this.owasp_results = JSON.parse(this.audit_steps_code)
                        this.owasp_results = this.formatAuditList(JSON.parse(response.data[0].result_object.content))

                       

                        console.log("owasp_results")
                        console.log(this.owasp_results)

                        setTimeout(() => {
                            initFlowbite();
                        }, 1500);

                    }

                    this.is_loading = false
                })
                .catch((error) => {
                    console.log(error)
                    this.is_loading = false;

                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }
                    this.$swal({
                        // title: "Title",
                        text: error_message,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-grey-protostars",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });


        } else {
            this.getSingleAuditDetails()
        }


    },
    beforeUnmount() {
    },
    updated() {
    },
    watch: {
        activeTab(newval) {
            this.current_step = newval == 'audits' ? 1 : 2
        }
    },
    methods: {
        getSingleAuditDetails() {
            console.log("get audit details", this.audit_id)

            axios
                .get("/audits/single/" + this.audit_id, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: 'PROTOSTARS',
                        Authorization:
                            "Bearer " +
                            JSON.parse(window.localStorage.getItem("user")).access_token,
                    },
                })
                .then(async (response) => {
                    console.log("===================================output")
                    console.log(response);
                    
                    // this.formatAuditList(JSON.parse(response.data.result_object.content))
                    this.breadcrumb_items[this.breadcrumb_items.length-1].text = response.data.title

                    this.owasp_status = response.data.result_object.status

                    if (response.data.result_object.status == 'failed') {
                        this.fail_message = response.data.result_object.content
                    }
                    else {
                        this.audit_steps_code = response.data.result_object.content
                    }

                    console.log(JSON.parse(this.audit_steps_code))


                    // this.owasp_results = JSON.parse(this.audit_steps_code)
                    this.owasp_results =  this.formatAuditList(JSON.parse(response.data.result_object.content))

                    console.log("owasp_results")
                    console.log(this.owasp_results)


                    setTimeout(() => {
                        initFlowbite();
                    }, 1500);

                    this.is_loading = false
                })
                .catch((error) => {
                    console.log(error)
                    this.is_loading = false;
                    
                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }
                    this.$swal({
                        // title: "Title",
                        text: error_message,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-grey-protostars",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });

        },

        changePageContent(val) {
            console.log("changePageContent", val)
            if (val == 'owasp') {
                this.display_owasp = true
                this.display_audit_steps = false
            }
        },
        clickBackToAudits() {
            console.log("clickBackToAudits")
            this.display_owasp = false
            this.display_audit_steps = true
        },

        formatAuditList(audits_list) {
            console.log("format audit list")
            console.log(audits_list)
            let tempAuditOutput = this.transformOriginalObject(audits_list)
            console.log(tempAuditOutput)

            return tempAuditOutput

        },
        transformOriginalObject(originalObj) {
            let output = [];

            for (let chapter in originalObj) {
                let chapterObj = {
                    chapter_name: chapter,
                    no_issues: 0,
                    sections: []
                };

                for (let section in originalObj[chapter]) {
                    let sectionObj = {
                        section_name: section,
                        no_issues: 0,
                        all_requirements: [],
                    };

                    for (let key in originalObj[chapter][section]) {
                        let keyObj = {
                            name: key,
                            no_issues: 0,
                            files: [],
                            implemented_files:[],
                            not_implemented_files: [],
                        };

                        for (let file in originalObj[chapter][section][key]) {
                            let fileObj = {
                                name: file,
                                implemented: originalObj[chapter][section][key][file].implemented,
                                code_snippet: originalObj[chapter][section][key][file].code_snippet
                            };

                            // Count the number of issues (where implemented is false)
                            if (!fileObj.implemented) {
                                keyObj.no_issues += 1;
                                sectionObj.no_issues += 1;
                                chapterObj.no_issues += 1;

                                keyObj.not_implemented_files.push(fileObj)
                            }else{
                                keyObj.implemented_files.push(fileObj)
                            }

                            keyObj.files.push(fileObj);
                        }

                        sectionObj.all_requirements.push(keyObj);
                    }

                    chapterObj.sections.push(sectionObj);
                }

                output.push(chapterObj);
            }

            return output;
        },


        // the functions convert requirements list from Ai with file names key and the values is list of rquirments objs
        // to object key is requirment title and value the list of files  
        convertObject(originalObj) {
            const newObj = {};
            for (const key in originalObj) {
                const innerObj = originalObj[key];
                for (const innerKey in innerObj) {
                    if (!newObj[innerKey]) {
                        newObj[innerKey] = new Set();
                    }
                    // console.log(innerKey)
                    // console.log(innerObj[innerKey])
                    let temp_obj = innerObj[innerKey]
                    temp_obj.file_name = key
                    newObj[innerKey].add(temp_obj);
                }
                // console.log("----------------------------------------------------")
            }
            return newObj;
        },

        transformObject(originalObj, arr) {
            // Step 1: Create the transformed object
            let transformedObj = {};

            // Iterate over the keys in the original object
            // for (let key in originalObj) {
            //     for (let subKey in originalObj[key]) {
            //         if (!transformedObj[subKey]) {
            //             transformedObj[subKey] = {};
            //         }
            //         if (!transformedObj[subKey][key]) {
            //             transformedObj[subKey][key] = true;
            //         }
            //     }
            // }
            for (const key in originalObj) {
                const innerObj = originalObj[key];
                for (const innerKey in innerObj) {
                    if (!transformedObj[innerKey]) {
                        transformedObj[innerKey] = new Set();
                    }
                    // console.log(innerKey)
                    // console.log(innerObj[innerKey])
                    let temp_obj = innerObj[innerKey]
                    temp_obj.file_name = key
                    transformedObj[innerKey].add(temp_obj);
                }
                console.log("----------------------------------------------------")
            }
            console.log("transformedObj")
            console.log(transformedObj)

            // Step 2: Create the output based on the sections in arr
            let output = {};
            arr.forEach(item => {
                output[item.name] = {};
                item.sections.forEach(section => {
                    // console.log(transformedObj[section.id])
                    // console.log(transformedObj[section], section in transformedObj , transformedObj.hasOwnProperty(section) )
                    if (transformedObj[section.id]) {
                        output[item.name][section.title] = (transformedObj[section.id]);
                    }
                });
            });
            console.log("output")
            console.log(output)

            let temp_output = []
            let no_issues_chapter = 0
            for (const key in output) {
                let temp_requirements_arr = []
                console.log(output[key])
                let no_issues_section = 0

                for (const requirement_item_key in output[key]) {
                    let temp_requr_items_arr = output[key][requirement_item_key]
                    console.log("temp_requr_items_arr")
                    console.log(temp_requr_items_arr)
                    let no_issues_requirement = 0

                    temp_requr_items_arr.forEach(function (temp_item) {
                        console.log(temp_item);
                        if (!temp_item.implemented) {
                            no_issues_requirement++;
                            no_issues_section++;
                            no_issues_chapter++;
                        }
                    });

                    temp_requirements_arr.push({ title: requirement_item_key, requirements_list: temp_requr_items_arr, no_issues_requirement: no_issues_requirement })
                }
                // output[key].forEach(requirement_item => {
                //     temp_requirements_arr.push({title: requirement_item})
                // });
                temp_output.push({
                    title: key,
                    requirements: temp_requirements_arr,
                    no_issues_section: no_issues_section
                })
            }

            console.log("TEmp output")
            console.log(temp_output)
            this.no_issues_chapter_auth = no_issues_chapter

            return temp_output;
        }
    }
}
</script>

<style>
.highlighted {
    background: red;
}

.danger {
    background: #ED6B6BA1;
}

.success {
    background: #8AFFB2;

}

</style>
