<template>
    <div class="col-span-12">
        <div class=" ">
            <div class="">
                <!-- {{  myForm.form.steps }} -->
                <FormSteps class=" " @select="changeStep" ref="formStepContainer">
                    <FormStep v-for="(step, key) in myForm.form.steps" :key="key" :name="step.label"
                        :label="step.label" :elements="step.elements" :conditions="step.conditions"
                        class="px-2 ">
                        <div class="name"><span>{{ step.label }} </span></div>
                    </FormStep>

                </FormSteps>
                <FormElements class="form-elements-test">
                    <!-- <template> -->
                    <FormBuilderSchema :schema_obj="myForm.schema" />
                    <!-- </template> -->

                </FormElements>
                <FormStepsControls class="form-controls-test" />
                <!-- :replace-class="[
                { 'form-step-active':['current1'] },
                { container_active: [{'form-step-active':'current221'} ]},
                { 'form-step': 'step' },
                { 'active': ['current'] },
                { 'form-step-completed': 'completed' }]" -->
            </div>
        </div>
    </div>
    <div class="">

    </div>
</template>

<script>
import { isProxy, toRaw } from 'vue';
import FormBuilderSchema from './FormBuilderSchema.vue'

export default {
    name: "FormBuilderStepsStructure",
    components: { FormBuilderSchema },//{ FwbButton, FwbModal,FwbTooltip },
    props: ['myForm'],
    data() {
        let temp = this;
        return {
            tab_index: 0
        }
    },
    mounted() {
    },
    methods: {
        changeStep(activeStep$, previousStep$) {
            // console.log("in change step")
            // console.log(activeStep$)
            // console.log(activeStep$['index'])
            // console.log(activeStep$.index)
            // console.log(previousStep$)
            // console.log(this.$refs.formStepContainer)
            // console.log(this.$refs.formStepContainer.current$)
            // console.log(this.$refs.formStepContainer.steps$Array)
            // console.log(this.$refs.formStepContainer.steps$Array[0])
            // console.log(this.$refs.formStepContainer.steps$Array[0].active)
            // console.log(this.$refs.formStepContainer.steps$Array[1])
            // console.log(this.$refs.formStepContainer.steps$Array[1].active)
            // console.log(this.$refs.formStepContainer.steps$Array[2])
            // console.log(this.$refs.formStepContainer.steps$Array[2].active)
            // console.log(this.$refs.formStepContainer.steps$Array[3])
            // console.log(this.$refs.formStepContainer.steps$Array[3].active)

            // console.log(isProxy(this.$refs.formStepContainer))
            // console.log(toRaw(this.$refs.formStepContainer))

        },
        upperFirst(name) {
            // console.log(name)
        }
    },
}
</script>