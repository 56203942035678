<template>
    <LayoutAuth>
        <ResetPasswordCodeCard :client_name="'PROTOSTARS'"
            :logo_src="isDark ? require('@/assets/images/protostars/logo-light.png') : require('@/assets/images/protostars/logo.png')"
            :base_url="'/protostars'" />
    </LayoutAuth>
</template>

<script>
import { inject } from 'vue';
import LayoutAuth from '@/components/Layout/LayoutAuth.vue'
import ResetPasswordCodeCard from '@/components/auth/ResetPasswordCodeCard.vue';

export default {
    name: "ResetPasswordCode",
    components: { LayoutAuth, ResetPasswordCodeCard },
    data() {
        return {
            client_name: 'PROTOSTARS',

            isDark: inject('isDark')
        }
    },
    mounted() {
    },
    updated() {
    },
    watch: {
    },
    methods: {}
}
</script>
