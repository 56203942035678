<template>
  <div>
    <!-- <navbar-protostars class="!border-b-2 !border-b-[#e5e7eb] !border-solid	"></navbar-protostars> -->

    <div class="card block h-[300px] w-10/12 mx-auto  ">

      <VueFlow ref="vueflowref" v-model="elements" class="basicflow h-full mt-3"
        :default-edge-options="{ type: 'smoothstep' }" :default-viewport="{ zoom: 1 }" :min-zoom="0.2" :max-zoom="4"
        :zoom-on-scroll="false" :nodes-draggable="false" :elements-selectable="true" multi-selection-key-code="shift"
        @nodes-selection-change="onNodeSelectionChange" :apply-default="false" @node-click="clickNode"
        @pane-click="clickPane">
        <!-- <Background pattern-color="#aaa" gap="8" /> -->

        <MiniMap />

        <Controls />
      </VueFlow>

    </div>


    <button @click="test" class="w-20 bg-red-500">asdfg</button>

    {{ elements }}


    <div class=" w-10/12 mx-auto my-4 text-center text-slate-800 text-xl font-bold  leading-[51px] tracking-tight">
      Please select an option</div>

    <div class="flex items-center justify-center w-10/12 mx-auto my-4">
      <!-- Component Start -->
      <form class="grid grid-cols-2 gap-2 w-full max-w-screen-sm">
        <div>
          <input class="hidden" id="radio_1" type="radio" name="comments" v-model="comments" checked value="web">
          <label class="flex flex-col p-4 cursor-pointer card" for="radio_1">
            <img src="@/assets/images/protostars/web-pricing.png" class="h-44 w-9/12 mx-auto" />
            <span class="text-black-protostars text-xl font-semibold  leading-tight text-center mt-4">Public Web Form
              <br /> &nbsp; </span>
            <span class="text-gray-500 text-xl font-semibold  leading-tight text-center mt-4">€500</span>

          </label>
        </div>
        <div>
          <input class="hidden" id="radio_3" type="radio" name="comments" v-model="comments" value="web_mobile">
          <label class="flex flex-col p-4   cursor-pointer card" for="radio_3">
            <img src="@/assets/images/protostars/web-mobile-pricing.png" class="h-44 w-10/12 mx-auto" />
            <span class="text-black-protostars text-xl font-semibold  leading-tight text-center mt-4">Public Web Form
              <br />& Mobile App</span>
            <span class="text-gray-500 text-xl font-semibold  leading-tight text-center mt-4">€1000</span>

          </label>
        </div>
      </form>
      <!-- Component End  -->

    </div>


    <div class="w-10/12 mx-auto mt-8 mb-4 h-20">
      <div class="w-full flex justify-center">

        <button class="btn btn-protostars btn-primary text-white px-4 py-2 rounded-lg "
          @click="clickPublish">Publish</button>

      </div>
    </div>


  </div>
</template>



<script>
import { VueFlow, useVueFlow } from '@vue-flow/core'
// import { Background } from '@vue-flow/background'
import { Controls } from '@vue-flow/controls'
import { MiniMap } from '@vue-flow/minimap'
import { ref } from 'vue'
import { initialElements } from './initial-elements.js'
import { MarkerType, Position } from '@vue-flow/core'

const { setNodesSelection } = useVueFlow();


const { onConnect, addEdges } = useVueFlow()

// const elements = ref(initialElements)

onConnect((params) => addEdges(params))

// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';

export default {
  name: "TestVueFlow",
  components: { VueFlow, Controls, MiniMap },
  data() {
    let temp = this;
    return {
      comments: 'web',
      elements: [
        {
          id: '1',
          type: 'input',
          label: 'Registration',
          selected: false,
          position: { x: 10, y: 50 },
          sourcePosition: Position.Right,
          display: 'all'
        },
        {
          id: '2',
          label: 'Situation Analysis',
          position: { x: 250, y: 50 },
          selected: false,
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          display: 'all'
        },
        {
          id: '3',
          label: 'Generated Form Review',
          position: { x: 500, y: 50 },
          selected: false,
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          display: 'all'
        },
        {
          id: '4',
          type: 'output',
          label: 'Public Web Form',
          selected: false,
          position: { x: 750, y: 0 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          display: 'web'
        },
        {
          id: '5',
          type: 'output',
          label: 'Mobile App',
          position: { x: 750, y: 150 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          selected: false,
          display: 'mobile'
        },
        // { id: '3', label: 'Node 3', position: { x: 250, y: 100 }, sourcePosition: Position.Right, targetPosition: Position.Left },
        // { id: '4', label: 'Node 4', position: { x: 500, y: 150 }, sourcePosition: Position.Right, targetPosition: Position.Left },
        // {
        //   id: '5',
        //   type: 'output',
        //   label: 'Node 5',
        //   position: { x: 750, y: 50 },
        //   sourcePosition: Position.Right,
        //   targetPosition: Position.Left,
        // },
        { id: 'e1-2', source: '1', target: '2', markerEnd: MarkerType.Arrow, display: 'all' },
        { id: 'e2-3', source: '2', target: '3', markerEnd: MarkerType.Arrow, display: 'all' },
        { id: 'e3-4', source: '3', target: '4', markerEnd: MarkerType.Arrow, display: 'all' },
        { id: 'e3-5', source: '3', target: '5', markerEnd: MarkerType.Arrow, display: 'all' },
        // {
        //   id: 'e4-5',
        //   source: '4',
        //   target: '5',
        //   style: { stroke: 'orange' },
        //   labelBgStyle: { fill: 'orange' },
        // },
        // { id: 'e3-4', source: '3', target: '4' },
      ]
    }
  },
  mounted() {

    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  computed: {
    filteredElements() {
      return this.elements.filter((element) => {
        // Apply your conditions here
        // console.log(this.comments)
        // console.log(element.display)
        return element.display === 'all' || (this.comments != null && this.comments.includes(element.display));
      });
    },
  },
  methods: {
    setNodesSelection,
    clickPublish() {
      this.$router.push('/success_publish')
    },
    onNodeSelectionChange(selectedNodes) {
      console.log(selectedNodes)
    },
    test() {
      console.log("select node")
      console.log(this.$refs.vueflowref)
      console.log("get selcted nodes")
      console.log(JSON.parse(JSON.stringify(this.$refs.vueflowref)))
      console.log(JSON.parse(JSON.stringify(this.$refs.vueflowref)).getSelectedElements)

      // console.log(this.$refs.vueflowref.getSelectedElements)
      // console.log(this.elements)
    },
    clickNode(temp) {
      console.log("clicked node")
      console.log(this.filteredElements)
      console.log(temp)
      // this.elements[2].selected = true
      for (let i = 0; i < this.elements.length; i++) {
        this.elements[i].selected = false
      }
      temp.node.selected = true
      console.log(temp)

      console.log(this.$refs.vueflowref)

      // this.filteredElements
      // setNodesSelection([3])
      // addSelectedNodes
    },
    clickPane(temp) {
      console.log("Click pane")
      console.log(temp)
    }
  }
}

</script>

<style scoped>
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.27.1/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.27.1/dist/theme-default.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/controls@latest/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/minimap@latest/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/node-resizer@latest/dist/style.css';



.vue-flow__minimap {
  transform: scale(75%);
  transform-origin: bottom right;
}

input:checked+label {
  border: 2px solid var(--grey_primary);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>