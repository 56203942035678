<template>
    <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar"
        type="button"
        class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
        <span class="sr-only">Open sidebar</span>
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd" fill-rule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
            </path>
        </svg>
    </button>

    <aside id="logo-sidebar"
        class="sidebar sidebar-protostars fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full md:translate-x-0"
        aria-label="Sidebar">
        <div class="h-full py-4 overflow-y-auto bg-gradient-to-b from-[#293042] to-[#1155CCD4]">
            <a href="/" class="flex items-center px-5 mb-8">
                <img src="@/assets/images/protostars/logo-light.png" class="my-4" />
            </a>
            <ul class="space-y-2 font-medium mt-5">
                <li>
                    <router-link to="/dashboard" class="sidebar-item-link">
                        <img src="@/assets/images/protostars/sidebar/dashboard-pie-light-icon.png" class="sidebar-icon">
                        <img src="@/assets/images/protostars/sidebar/dashboard-pie-dark-icon.png" class="sidebar-icon-dark">
                        <span class="ms-3">Dashboard</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/audits/audit_steps" class="sidebar-item-link">
                        <img src="@/assets/images/protostars/sidebar/dashboard-light-icon.png" class="sidebar-icon">
                        <img src="@/assets/images/protostars/sidebar/dashboard-dark-icon.png" class="sidebar-icon-dark">
                        <span class="ms-3">Live Security Audit</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/audits/list" class="sidebar-item-link">

                        <img src="@/assets/images/protostars/sidebar/history-light-icon.png" class="sidebar-icon">
                        <img src="@/assets/images/protostars/sidebar/history-dark-icon.png" class="sidebar-icon-dark">

                        <span class="flex-1 ms-3 whitespace-nowrap">Audit History</span>
                        <!-- <span
                            class="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span> -->
                    </router-link>
                </li>
                <li>
                    <router-link to="/user_management" class="sidebar-item-link">

                        <img src="@/assets/images/protostars/sidebar/users-circle-light-icon.png" class="sidebar-icon">
                        <img src="@/assets/images/protostars/sidebar/users-circle-dark-icon.png"
                            class="sidebar-icon-dark">

                        <span class="flex-1 ms-3 whitespace-nowrap">User Management</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/conversations" class="sidebar-item-link">

                        <img src="@/assets/images/protostars/sidebar/chat-light-icon.png" class="sidebar-icon">
                        <img src="@/assets/images/protostars/sidebar/chat-dark-icon.png" class="sidebar-icon-dark">

                        <span class="flex-1 ms-3 whitespace-nowrap">Conversations</span>
                        <!-- <span
                            class="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span> -->
                    </router-link>
                </li>
                <li>
                    <router-link to="/questionnaires" class="sidebar-item-link">

                        <img src="@/assets/images/protostars/sidebar/file-light-icon.png" class="sidebar-icon">
                        <img src="@/assets/images/protostars/sidebar/file-dark-icon.png" class="sidebar-icon-dark">

                        <span class="flex-1 ms-3 whitespace-nowrap">Questionnaries</span>
                        <!-- <span
                            class="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span> -->
                    </router-link>
                </li>
                <li>
                    <router-link to="/glossary" class="sidebar-item-link">

                        <img src="@/assets/images/protostars/sidebar/verified-light-icon.png" class="sidebar-icon">
                        <img src="@/assets/images/protostars/sidebar/verified-dark-icon.png" class="sidebar-icon-dark">

                        <span class="flex-1 ms-3 whitespace-nowrap">Compilance</span>
                        <!-- <span
                            class="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span> -->
                    </router-link>
                </li>
                <li>
                    <!-- <router-link to="/form_builder" class="sidebar-item-link"> -->
                    <router-link to="/flow_builder" class="sidebar-item-link">

                        <img src="@/assets/images/protostars/sidebar/settings-light-icon.png" class="sidebar-icon">
                        <img src="@/assets/images/protostars/sidebar/settings-dark-icon.png" class="sidebar-icon-dark">

                        <span class="flex-1 ms-3 whitespace-nowrap">Settings</span>
                        <!-- <span
                            class="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span> -->
                    </router-link>
                </li>
            </ul>


            <ul class="space-y-2 font-medium absolute bottom-12 right-0 left-0">
                <li> <a class="sidebar-item-link cursor-pointer" @click="clickLogout">
                        <img src="@/assets/images/protostars/sidebar/logout-light-icon.png" class="sidebar-icon">
                        <img src="@/assets/images/protostars/sidebar/logout-dark-icon.png" class="sidebar-icon-dark">
                        <span class="ms-3">Logout</span>
                    </a>
                </li>
            </ul>

        </div>
    </aside>

</template>

<script>
export default {
    name: 'SidebarDefault',
    components: {
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        clickLogout() {
            localStorage.clear()
            this.$router.go('/protostars/login')
        },
    }
}

</script>
