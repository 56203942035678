<template>

    <div class="auth-container protostars-auth-container  ">
        <div class="row h-full m-auto">
            <div class="xl:w-4/12 lg:w-5/12 md:w-7/12 w-[98%] mx-auto my-auto">                <slot></slot>
            </div>
        </div>
    </div>

</template>


<script>
import { inject } from 'vue';

export default {
    name: "LoginView",
    data() {
        return {
            isDark: inject('isDark')
        }
    },
    mounted() {

    },
    updated() {
    },
    watch: {
    },
    methods: {


    }
}
</script>
