<template>


    <CardPartial class="mb-2 w-full" :card_class="'dark:border-2 dark:border-grey-protostars-100'">
        <CardHeader
            :header_class="'border-b-2 border-b-z border-b-grey-protostars-50 lg:h-20 h-auto flex align-middle  dark:border-b-grey-protostars-100'">
            <div class="row align-middle m-auto w-full">
                <div class="lg:w-7/12 md:w-6/12 w-full md:flex md:align-middle md:items-center md:mb-0 mb-4">
                    <Vueform class="w-full" add-class="vueFormContainer vueFormFilter" :float-placeholders="false"
                        v-model="searchData">
                        <TextElement name="search" inputType="search" placeholder="Search">

                            <template #addon-before>
                                <font-awesome-icon class="addon" icon='fa-solid fa-magnifying-glass' />
                            </template>
                        </TextElement>
                    </Vueform>
                </div>
                <div
                    class=" lg:w-5/12 md:w-6/12 w-full md:flex md:align-middle md:justify-end md:items-center md:mb-0 mb-2">
                    <button href="/audits/create_audit" class="btn-protostars bg-blue text-white lg:float-right"
                        @click="clickAddAudit">Add New Team</button>
                </div>
            </div>
        </CardHeader>
        <CardBody :body_class="'!py-1 !px-0'">
            <div class=" overflow-x-auto  mt-5">
                <!-- <div class="row align-middle m-auto w-full">
                        <div class="lg:w-7/12 md:w-6/12 w-full"> -->
                <table class="w-full table-protostars max-w-full">
                    <thead class="">
                        <tr>
                            <th scope="col" class="">
                                ID
                            </th>
                            <th scope="col" class="">
                                Name
                            </th>
                            <th scope="col" class="">
                                Leader
                            </th>
                            <th scope="col" class="">
                                Date Updated
                            </th>
                            <th scope="col" class="text-center">
                                Role
                            </th>
                            <th scope="col" class="text-center">
                                Members
                            </th>
                            <th scope="col" class="text-center">

                            </th>

                        </tr>
                    </thead>
                    <tbody class="" v-if="teams_list.length > 0">
                        <tr class="cursor-pointer" v-for="(team_item, index) in teams_list" :key="index"
                            @click="clickViewAudit(team_item.id)">
                            <th scope="row" class="">
                                {{ team_item.id }}
                            </th>
                            <td>
                                {{ team_item.name }}
                            </td>
                            <td>
                                {{ team_item.leader }}
                            </td>
                            <td>
                                {{ team_item.date_updated }}
                            </td>
                            <td class="">
                                {{ team_item.roles }}
                            </td>
                            <td class="">
                                {{ team_item.members }}
                            </td>
                            <td class="">
                                <button :id="'dropdownLeftEndButton_' + (index + 1)"
                                    :data-dropdown-toggle="'dropdownLeftEnd_' + (index + 1)"
                                    data-dropdown-placement="left-end"
                                    class="me-3 mb-3 md:mb-0  p-0 w-6 h-6 text-center inline-flex items-center "
                                    type="button">
                                    <img src="@/assets/images/protostars/more-icon.png" class="w-6 h-6" />
                                </button>

                                <!-- Dropdown menu -->
                                <div :id="'dropdownLeftEnd_' + (index + 1)"
                                    class="z-20 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                    <ul class="py-2 text-sm text-gray-700 dark:text-gray-200"
                                        :aria-labelledby="'dropdownLeftEndButton_' + (index + 1)">
                                        <li>
                                            <a href="#"
                                                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                <img src="@/assets/images/view-icon.png"
                                                    class="w-4 h-4 mr-1 inline-block" />
                                                View</a>
                                        </li>
                                        <li>
                                            <a href="#"
                                                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                <img src="@/assets/images/alert-icon.png"
                                                    class="w-4 h-4 mr-1 inline-block" />Alert</a>
                                        </li>
                                        <li>
                                            <a href="#"
                                                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                <img src="@/assets/images/edit-icon.png"
                                                    class="w-4 h-4 mr-1 inline-block" />Edit Team</a>
                                        </li>
                                        <li>
                                            <a href="#"
                                                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                <img src="@/assets/images/delete-icon-default.png"
                                                    class="w-4 h-4 mr-1  inline-block" />Delete</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody class="" v-else>
                        <tr>
                            <td colspan="8" class="text-center">No data found</td>
                        </tr>
                    </tbody>

                </table>
                <!-- </div>
                    </div> -->
            </div>

            <div class="mt-6 mb-2 w-full row justify-between px-4 pb-4">
                <div class="lg:w-1/2 w-full flex items-center lg:mt-2 mt-5">
                    <p class="dataset-info w-full lg:text-left text-center">Showing 1 to 7 of 100 entries
                    </p>
                </div>
                <div class="lg:w-1/2 w-full flex lg:mt-2 mt-5">
                    <div class="w-auto max-w-full inline-flex items-center lg:float-right lg:mr-0 mx-auto">
                        <vue-awesome-paginate class="paginate-container float-right float-"
                            :total-items="teams_list.length" :items-per-page="per_page" :max-pages-shown="3"
                            :show-ending-buttons="true" v-model="currentPage" :on-click="onClickHandler">

                            <template #prev-button>
                                <font-awesome-icon icon="fa-solid fa-angle-left" />
                            </template>
                            <template #next-button>
                                <font-awesome-icon icon="fa-solid fa-angle-right" />
                            </template>
                            <template #first-page-button>
                                <font-awesome-icon icon="fa-solid fa-angles-left" />
                            </template>
                            <template #last-page-button>
                                <font-awesome-icon icon="fa-solid fa-angles-right" />
                            </template>

                        </vue-awesome-paginate>
                    </div>
                </div>

            </div>
        </CardBody>
    </CardPartial>


</template>


<script>
// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';
// // import AudioRecorder from 'vue-audio-recorder'
import { initFlowbite } from 'flowbite'
// import axios from 'axios';
// import BreadcrumbIndex from '@/components/breadcrumb/Index.vue'

export default {
    name: "UsersList",
    components: {},
    data() {
        let temp = this;
        return {
            teams_list: [
                { id: 1, name: "Frontend", leader: "Sara Reda", date_updated: "1 May 2024", members: "Sara Reda", roles: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet" },
                { id: 2, name: "Backend", leader: "Mohamed Fayez", date_updated: "30 Apr 2024", members: "Mohamed Fayez, Hisham", roles: "Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet" },

            ],

            per_page: 10,
            currentPage: 1,

            searchData: { search: null },
        }
    },
    mounted() {
        setTimeout(() => {
            initFlowbite();
        }, 500);
    },
    beforeUnmount() {
    },
    updated() {
    },
    watch: {
    },
    methods: {
    }
}
</script>
