<template>



    <div class="h-full-height  mb-5">
        <OverlayLoading :is_loading="is_loading">
            <!-- <template #content> -->

                <div class="w-full h-full mt-0 px-3 md:px-1 xl:px-1">

                    <h2 class="page-title">Security Audit</h2>
                    <p class="page-description">
                        Security-focused LLM technologies enhance compliance with the EU Cyber Resilience Act for
                        Software CE marking. Protostars facilitates software security and compliance gap analysis for
                        NIS2 Directive and DORA in third-party software supply chains.
                    </p>

                    <div v-if="status == 'complete'" id="accordion-collapse" data-accordion="collapse" class="mt-4 mb-4"
                        data-active-classes="!border-blue-400 dark:!border-blue-protostars border-2">
                        <div v-for="(item, index) in audit_steps" :key="index">
                            <h2 :id="'accordion-collapse-heading-' + (index + 1)">
                                <!-- <button type="button"
                            class="flex items-center justify-between w-full 
                            p-5 font-medium rtl:text-right text-gray-500 border 
                            border-b-0 border-gray-200 rounded-t-xl focus:ring-4 
                            focus:ring-gray-200 dark:focus:ring-gray-800 
                            dark:border-gray-700 dark:text-gray-400 
                            hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
                            data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                            aria-controls="accordion-collapse-body-1">
                            ></button> -->
                                <button type="button" class=" mt-3 flex items-center justify-between w-full 
                        p-5 font-medium rtl:text-right text-black-protostars border  
                        border-gray-200 rounded-xl focus:ring-4 
                        focus:ring-gray-200 dark:focus:ring-gray-800 
                        dark:border-gray-700 dark:text-white 
                        hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 bg-white  dark:bg-black-protostars 
                        " :data-accordion-target="'#accordion-collapse-body-' + (index + 1)"
                                    :aria-expanded="index == 3"
                                    :aria-controls="'accordion-collapse-body-' + (index + 1)">
                                    <span class="text-base/5 text-black-protostars dark:text-white">{{ item.title
                                        }}</span>
                                    <div>
                                        <span class="mr-2 text-sm/4 ">

                                            <!-- <span v-if="item.status == 'danger'" class="mr-3 text-[#FF4D4D]"> {{
                                                item.status_msg
                                            }}</span> -->
                                            <font-awesome-icon v-if="item.status == 'success'"
                                                icon="fa-solid fa-circle-check" size="xl" class="text-[#00E89D]" />
                                            <font-awesome-icon v-if="item.status == 'warning'"
                                                icon="fa-solid fa-circle-check" size="xl" class="text-[#FFCC05]" />
                                            <font-awesome-icon v-if="item.status == 'danger'"
                                                icon="fa-solid fa-circle-xmark" size="xl" class="text-[#FF4D4D]" />
                                        </span>
                                        <svg data-accordion-icon="" class="w-6 h-6 shrink-0 rotate-180 inline-block"
                                            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd"></path>
                                        </svg>
                                    </div>
                                </button>
                            </h2>
                            <div :id="'accordion-collapse-body-' + (index + 1)" class="hidden"
                                :aria-labelledby="'accordion-collapse-heading-' + (index + 1)">
                                <div class="py-5 pl-5 pr-0 relative">
                                    <template v-if="item.is_code">
                                        <!-- Nested accordion -->
                                        <div id="accordion-nested-collapse">
                                            <h2 :id="'accordion-nested-collapse-heading-1'">
                                                <button type="button" @click="$emit('displayResults', 'owasp')"
                                                    class="flex items-center justify-between w-full h-12 p-5 text-left rounded-xl font-medium rtl:text-right text-sm 
                                                        text-black-protostars  border-2 mb-3 border-gray-200 bg-white  dark:bg-black-protostars  focus:ring-1 
                                                        focus:ring-primary/30 dark:focus:ring-primary-400/30 dark:border-gray-700 dark:text-white hover:text-black-protostars dark:hover:text-grey-protostars-100
                                                          gap-3  focus:ring-gray-200 dark:focus:ring-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800">


                                                    <span>OWASP</span>
                                                    <svg data-accordion-icon class="w-3 h-3 rotate-90 shrink-0"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="none" viewBox="0 0 10 6">
                                                        <path stroke="currentColor" stroke-linecap="round"
                                                            stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                                                    </svg>
                                                </button>
                                            </h2>

                                            <h2 :id="'accordion-nested-collapse-heading-1'">
                                                <button type="button" @click="$emit('displayResults', 'iso')" disabled
                                                    class="flex items-center justify-between w-full h-12 p-5 text-left rounded-xl font-medium rtl:text-right text-sm  opacity-60 
                                                        text-black-protostars  border-2 mb-3 border-gray-200 bg-white  dark:bg-black-protostars  focus:ring-1 
                                                        focus:ring-primary/30 dark:focus:ring-primary-400/30 dark:border-gray-700 dark:text-white 
                                                          gap-3  focus:ring-gray-200 dark:focus:ring-gray-800     ">
                                                    <span>ISO</span>
                                                    <svg data-accordion-icon class="w-3 h-3 rotate-90 shrink-0"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="none" viewBox="0 0 10 6">
                                                        <path stroke="currentColor" stroke-linecap="round"
                                                            stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                                                    </svg>
                                                </button>
                                            </h2>

                                            <h2 :id="'accordion-nested-collapse-heading-1'">
                                                <button type="button" @click="$emit('displayResults', 'guidelines')"
                                                    disabled class="flex items-center justify-between w-full h-12 p-5 text-left rounded-xl font-medium rtl:text-right text-sm  opacity-50
                                                        text-black-protostars  border-2 mb-3 border-gray-200 bg-white  dark:bg-black-protostars  focus:ring-1 
                                                        focus:ring-primary/30 dark:focus:ring-primary-400/30 dark:border-gray-700 dark:text-white 
                                                          gap-3  focus:ring-gray-200 dark:focus:ring-gray-800 ">
                                                    <span>Regulator Guidelines</span>
                                                    <svg data-accordion-icon class="w-3 h-3 rotate-90 shrink-0"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="none" viewBox="0 0 10 6">
                                                        <path stroke="currentColor" stroke-linecap="round"
                                                            stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                                                    </svg>
                                                </button>
                                            </h2>
                                        </div>



                                    </template>
                                    <template v-else>
                                        <template v-for="(desc_item, desc_index) in item.description"
                                            :key="index+'_'+desc_index">
                                            <div class="flex w-full justify-between">
                                                <h3 v-if="desc_item.title"
                                                    class="block lg:w-10/12 md:w-9/12 w-full mb-2 font-Poppins font-semibold text-base/normal text-[#293042] dark:text-white ">
                                                    {{ desc_item.title }}
                                                </h3>
                                                <div v-else>
                                                    <!-- {{desc_item.files_download}} -->
                                                    <p v-for="(file_item, index) in desc_item.files_download"
                                                        :key="index"
                                                        class="mb-2 font-Poppins font-medium text-base/normal text-primary dark:text-primary hover:underline">
                                                        <a :href="file_item.path" target="_blank"
                                                            :download="file_item.title + '.json'"> {{ file_item.title
                                                            }}</a>
                                                    </p>
                                                    <p
                                                        class="mt-4 mb-2 font-Poppins font-medium text-base/normal text-primary dark:text-primary hover:underline">
                                                        <router-link :to="desc_item.link_more">Click here for more
                                                            information</router-link>
                                                    </p>

                                                </div>

                                            </div>

                                            <div class="flex w-full">
                                                <div class="block w-8/12">
                                                </div>
                                            </div>



                                        </template>

                                    </template>

                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- <div v-else
                        class="w-10/12 mx-auto  mb-4 min-h-[300px] !h-8/12 flex justify-center justify-items-center items-center">
                        <div class="">
                            <span
                                class="text-gray-500 dark:text-grey-protostars-200 text-2xl font-semibold  leading-10">
                                There is no active audit at the
                                moment. You can
                                visit the audit
                                history list or create a new one.
                            </span>
                        </div>
                    </div> -->
                    <div v-else
                        class="w-10/12 mx-auto  my-4 min-h-[300px] !h-8/12 flex justify-center justify-items-center items-center">
                        <div class="text-gray-500 dark:text-grey-protostars-200 text-2xl font-semibold  leading-10">
                            <span v-if="status == 'pending'">
                                The code analysis is still in progress, please check back later.

                            </span> <span v-else-if="status == 'failed'">
                                An unexpected error has occurred during the analysis
                                <!-- {{ fail_message }} -->
                            </span>
                        </div>
                    </div>


                </div>
            <!-- </template> -->
        </OverlayLoading>

    </div>
</template>

<script>
import { initFlowbite } from 'flowbite'

import axios from 'axios';
// import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'


// import Codemirror, { CmComponentRef } from "codemirror-editor-vue3";
// import { Editor, EditorConfiguration } from "codemirror";
import { ref } from "vue";

// placeholder
import "codemirror/addon/display/placeholder.js";

// language
import "codemirror/mode/javascript/javascript.js";
// placeholder
import "codemirror/addon/display/placeholder.js";
// theme
import "codemirror/theme/dracula.css";
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/display/autorefresh'

import moment from 'moment'


export default {
    name: "AuditStepsComponent",
    // components: { Codemirror },//, Popover, PopoverButton, PopoverPanel },
    props: ["audit_id", "audit_steps", "audit_steps_code", "status"],
    data() {
        let temp = this;
        return {

            cmOptions: {
                //mode: "text/javascript", // Language mode
                // theme: "dracula", // Theme
                lineNumbers: true,
                // firstLineNumber: 2,
                // highlightLines: [3],
                styleActiveLine: false,
                textWrapping: true,
                autoRefresh: true,
                readOnly: true,
                // gutters: ["CodeMirror-linenumbers", "highlightedLine"]

            },
            cmComponentRef: null,

            is_loading: false

        }
    },
    mounted() {
        setTimeout(() => {
            initFlowbite();
        }, 1500);

    },
    beforeUnmount() {
    },
    updated() {
    },
    watch: {
    },
    methods: {

        highlightCode(code_ref, lines_highlight, class_name) {
            console.log("highlightCode", code_ref, lines_highlight)
            // console.log(Editor)
            // console.log(Codemirror)
            console.log(this.$refs)

            let cmComponentRef = this.$refs[code_ref][0]

            console.log("cmComponentRef==")
            console.log(cmComponentRef)

            for (let i = 0; i < lines_highlight.length; i++) {
                cmComponentRef.cminstance.doc.markText({ line: lines_highlight[i] - 1, ch: 0 }, { line: lines_highlight[i], ch: 0 }, { className: class_name })

            }
        },
        handleCursorActivity(instance) {
            console.log("iiiiiiii")
            console.log(instance)
            // Get the current line number
            const currentLine = instance.getCursor().line;
            console.log(currentLine)

            // Add a custom class to the current line
            instance.addLineClass(currentLine, "wrap", "my-custom-class");
        },


    }
}
</script>

<style>
.highlighted {
    background: red;
}

.danger {
    background: #ED6B6BA1;
}

.success {
    background: #8AFFB2;

}

/* required class */
/* .my-editor {
    background: #2d2d2d;
    color: #ccc;

    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
} */

/* optional class for removing the outline */
/* .prism-editor__textarea:focus {
    outline: none;
}
*/
/* .marked {
    background-color: #ffdd99;
} 

pre.line-numbers {
	position: relative;
	padding-left: 3.8em;
	counter-reset: linenumber;
}

pre.line-numbers > code {
	position: relative;
	white-space: inherit;
}

.line-numbers .line-numbers-rows {
	position: absolute;
	pointer-events: none;
	top: 0;
	font-size: 100%;
	left: -3.8em;
	width: 3em; 
	letter-spacing: -1px;
	border-right: 1px solid #999;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

}

	.line-numbers-rows > span {
		display: block;
		counter-increment: linenumber;
	}

		.line-numbers-rows > span:before {
			content: counter(linenumber);
			color: #999;
			display: block;
			padding-right: 0.8em;
			text-align: right;
		} */
</style>
