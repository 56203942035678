<template >
    <div class="col-span-12">

        <!-- {{ myForm.form.tabs }} -->
        <FormTabs class=" " @select="changeTab" ref="formTabsContainer">
            <FormTab v-for="(tab, key) in  myForm.form.tabs" :key="key" :name="tab.label" :label="tab.label"
                :elements="tab.elements" :conditions="tab.conditions"  class="px-2 ">
                <!-- <div class="name"><span>{{ tab.label }} </span></div> -->
            </FormTab>

        </FormTabs>
        <FormElements class="form-elements-test">
            <!-- <template> -->
            <FormBuilderSchema :schema_obj="myForm.schema" />
            <!-- </template> -->

        </FormElements>
        <!-- :replace-class="[
                { 'form-step-active':['current1'] },
                { container_active: [{'form-step-active':'current221'} ]},
                { 'form-step': 'step' },
                { 'active': ['current'] },
                { 'form-step-completed': 'completed' }]" -->

    </div>
</template>

<script>
import { isProxy, toRaw } from 'vue';
import FormBuilderSchema from './FormBuilderSchema.vue'

export default {
    name: "FormBuilderTabsStructure",
    components: { FormBuilderSchema },//{ FwbButton, FwbModal,FwbTooltip },
    props: ['myForm'],
    data() {
        let temp = this;
        return {
            tab_index: 0
        }
    },
    mounted() {
    },
    methods: {
        changeTab(activeTab$, previousTab$) {
            // console.log("in change tab")
            // console.log(activeTab$)
            // console.log(previousTab$)            
            // console.log(this.$refs.formTabsContainer)


        },
        upperFirst(name) {
            // console.log(name)
        }
    },
}
</script>