<template>
    <div class="card card-auth ">
        <img :src="logo_src" class="logo-img" />
        <Vueform add-class="vueFormContainer" :endpoint="false" :float-placeholders="false" v-model="formData" validate-on="change|step"
            class="" ref="codeFormRef" :remove-class="{ div: ['grid'] }">
            <StaticElement name="welcomeH3" tag="h3" :content="'Reset password'" :attrs="{ class: ['title-h3'] }" />
            <StaticElement name="welcomeText" tag="p" :content="'Please enter the code that you received'"
                :attrs="{ class: ['text-p'] }" />

            <TextElement name="code" input-type="text" label="Code" placeholder="Enter code" :field-name="'Code'"
                rules="required" class="mb-3" />

            <HiddenElement name="client" :default="client_name" />
            <HiddenElement name="email" :default="email" />


            <ButtonElement button-label="Send Code" :submits="true"
                :button-class="['btn-protostars', 'btn-auth-submit']" full :loading="is_loading" @click="clickVerify" />

        </Vueform>
    </div>
</template>

<script>
import { inject } from 'vue';
import AuthService from '@/services/AuthService';

export default {
    name: "ResetPasswordCodeCard",
    props: {
        client_name: {
            type: String,
        },
        logo_src: {
            type: String
        },
        base_url: {
            type: String
        }
    },
    data() {
        let temp = this;
        return {
            formData: null,

            is_loading: false,
            selected_method: null,
            email: null,

            isDark: inject('isDark')

        }
    },
    mounted() {
        this.email = window.sessionStorage.getItem("email");
        this.selected_method = this.$route.params.method
    },
    updated() {
    },
    watch: {
    },
    methods: {
        clickVerify() {
            // this.$router.push("/situation_analysis")


            let code_form = this.$refs.codeFormRef
            code_form.validate()
            let form_errors = code_form.formErrors
            if (form_errors.length > 0) {
                console.log("not valid")

            } else {
                console.log('validated')
                this.is_loading = true
                this.sendCodeForm()
            }


        },

        async sendCodeForm() {
            console.log("in send code request")

            let response = await AuthService.sendResetPasswordCodeRequest(this.formData, this.client_name)
            console.log("reset password code card")
            console.log(response)

            if (response.code == 200) {
                // window.sessionStorage.setItem("email", this.formData.email);
                // window.sessionStorage.setItem("user", JSON.stringify(response.response.user));
                // window.sessionStorage.setItem("user_type", "user");
                // this.$router.push({ name: "ChooseVerificationMethod" });
                this.$router.push({ name: "ResetPassword" });

            } else {
                this.$swal({
                    theme: "",
                    // title: "Title",
                    text: response.response.error_message,
                    icon: "error",
                    // timer: 3000,

                }).then((r) => {
                    // console.log(r.value);
                    this.is_loading = false;
                });

            }

            this.is_loading = false

        }

    }
}
</script>
