<template>
    <div class="card card-auth">
        <img :src="logo_src" class="logo-img" />
        <Vueform add-class="vueFormContainer" :endpoint="false" :float-placeholders="false" v-model="formData" validate-on="change|step"
            class="" ref="forgetPasswordRef" :remove-class="{ div: ['grid'] }">
            <StaticElement name="welcomeH3" tag="h3" :content="'Forget Password'"
                :attrs="{ class: ['sub-title-h3'] }" />
            <StaticElement name="welcomeText" tag="p"
                :content="'Please enter your registered email to send you an link to reset your password'"
                :attrs="{ class: ['text-p'] }" />

            <HiddenElement name="client" :default="client_name" />

            <TextElement name="email" input-type="email" label="Email" placeholder="Enter email" :field-name="'Email'"
                rules="required" class="mb-3" />

            <ButtonElement button-label="Send Email" :submits="true"
                :button-class="['btn-protostars', 'btn-auth-submit']" full :loading="is_loading"
                @click="clickSendEmail" />

        </Vueform>

        <div class="auth-text-link">
            <span class="">Remember password?
            </span>
            <span class="">
                <router-link :to="base_url + '/login'">
                    Login
                </router-link>
            </span>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
    name: "ForgetPasswordCard",
    components: {},
    props: {
        client_name: {
            type: String,
        },
        logo_src: {
            type: String
        },
        base_url: {
            type: String
        }
    },
    data() {
        let temp = this;
        return {

            formData: null,

            is_loading: false

        }
    },
    mounted() {

    },
    updated() {
    },
    watch: {
    },
    methods: {
        clickSendEmail() {
            let forget_password_form = this.$refs.forgetPasswordRef
            forget_password_form.validate()
            let form_errors = forget_password_form.formErrors
            if (form_errors.length > 0) {
                console.log("not valid")

            } else {
                console.log('validated')
                this.is_loading = true
                this.sendForgetPassword()
            }

        },
        async sendForgetPassword() {
            this.is_loading = true

            let response = await AuthService.sendForgetPasswordEmailRequest(this.formData, this.client_name)
            console.log("forget password card")
            console.log(response)

            if (response.code == 200) {
                this.$swal({
                    theme: "",
                    // title: "Title",
                    text: response.response.success,
                    icon: "success",
                    // timer: 3000,

                }).then((r) => {
                    // console.log(r.value);
                    this.$router.push({ name: "ResetPasswordCode" });
                    this.is_loading = false;
                });
            } else {
                this.$swal({
                    theme: "",
                    // title: "Title",
                    text: response.response.error_message,
                    icon: "error",
                    // timer: 3000,

                }).then((r) => {
                    // console.log(r.value);
                    this.is_loading = false;
                });

            }

            this.is_loading = false

        },

    }
}
</script>
