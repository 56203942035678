<template>
    <div class="h-full">
        <!-- <navbar-protostars class="!border-b-2 !border-b-[#e5e7eb] !border-solid	"></navbar-protostars> -->

        <div class="w-10/12 mx-auto  mb-4 min-h-[600px] !h-8/12 flex justify-center justify-items-center items-center">
            <div class="">
                <span class="text-gray-500 text-4xl font-semibold  leading-10">
                    Your form has been published successfully.<br />
                    Here is the public URL
                </span>
                <span class="text-yellow-protostars text-4xl font-semibold  leading-10" v-if="public_form_url">
                    <a :href="public_form_url" target="_blank"> {{ public_form_url }} </a>
                </span>
            </div>
        </div>

    </div>
</template>



<script>
// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';

export default {
    name: "SuccessPublishForm",
    components: {},
    data() {
        let temp = this;
        return {
            public_form_url: null
        }
    },
    mounted() {

        window.scrollTo({ top: 0, behavior: 'smooth' });

        this.public_form_url = window.location.origin + '/form/' + this.$route.params.form_id
        console.log(window.location)
        console.log(this.$route)
        console.log(window.location.origin + '/form/' + this.$route.params.form_id)
        console.log(this.public_form_url)
    }
}

</script>