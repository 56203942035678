<template>


    <CardPartial class="mb-2 w-full" :card_class="'dark:border-2 dark:border-grey-protostars-100'">
        <CardHeader
            :header_class="'border-b-2 border-b-z border-b-grey-protostars-50 lg:h-20 h-auto flex align-middle  dark:border-b-grey-protostars-100'">
            <div class="row align-middle m-auto w-full">
                <div class="lg:w-7/12 md:w-6/12 w-full md:flex md:align-middle md:items-center md:mb-0 mb-4">
                    <Vueform class="w-full" add-class="vueFormContainer vueFormFilter" :float-placeholders="false"
                        v-model="searchData">
                        <TextElement name="search" inputType="search" placeholder="Search">

                            <template #addon-before>
                                <font-awesome-icon class="addon" icon='fa-solid fa-magnifying-glass' />
                            </template>
                        </TextElement>
                    </Vueform>
                </div>
                <div
                    class=" lg:w-5/12 md:w-6/12 w-full md:flex md:align-middle md:justify-end md:items-center md:mb-0 mb-2">
                    <button type="button" data-modal-target="employeeDataModal" data-modal-toggle="employeeDataModal"
                        class="btn-protostars bg-blue text-white lg:float-right" @click="clickAddAudit">Add New
                        User</button>


                </div>
            </div>
        </CardHeader>
        <CardBody :body_class="'!py-1 !px-0'">
            <div class=" overflow-x-auto  mt-5">
                <!-- <div class="row align-middle m-auto w-full">
                        <div class="lg:w-7/12 md:w-6/12 w-full"> -->
                <table class="w-full table-protostars max-w-full">
                    <thead class="">
                        <tr>
                            <th scope="col" class="">
                                ID
                            </th>
                            <th scope="col" class="">
                                Name
                            </th>
                            <th scope="col" class="">
                                Email
                            </th>
                            <th scope="col" class="">
                                Phone Number
                            </th>
                            <th scope="col" class="">
                                Date Joined
                            </th>
                            <th scope="col" class="">
                                Team
                            </th>
                            <th scope="col" class="">
                                Role
                            </th>
                            <th scope="col" class="">

                            </th>

                        </tr>
                    </thead>
                    <tbody class="" v-if="employees_list.length > 0">
                        <tr class="cursor-pointer" v-for="(employee_item, index) in employees_list" :key="index"
                            @click="clickViewAudit(employee_item.id)">
                            <th scope="row" class="">
                                {{ employee_item.id }}
                            </th>
                            <td>
                                {{ employee_item.name }}
                            </td>
                            <td>
                                {{ employee_item.email }}
                            </td>
                            <td>
                                {{ employee_item.phone_number }}
                            </td>
                            <td class="">
                                {{ employee_item.date_joined }}
                            </td>
                            <td class="">
                                {{ employee_item.team }}
                            </td>
                            <td class="min-w-56">
                                {{ employee_item.roles }}
                            </td>
                            <td class="">
                                <button :id="'dropdownLeftEndButton_' + (index + 1)"
                                    :data-dropdown-toggle="'dropdownLeftEnd_' + (index + 1)"
                                    data-dropdown-placement="left-end"
                                    class="me-3 mb-3 md:mb-0  p-0 w-6 h-6 text-center inline-flex items-center "
                                    type="button">
                                    <img src="@/assets/images/protostars/more-icon.png" class="w-6 h-6" />
                                </button>

                                <!-- Dropdown menu -->
                                <div :id="'dropdownLeftEnd_' + (index + 1)"
                                    class="z-20 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                    <ul class="py-2 text-base/normal text-grey-protostars-400 dark:text-gray-200"
                                        :aria-labelledby="'dropdownLeftEndButton_' + (index + 1)">
                                        <li>
                                            <a href="#"
                                                class="flex align-middle items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                <img src="@/assets/images/view-icon.png"
                                                    class="w-4.5 h-4.5 mr-2 inline-block" />
                                                View</a>
                                        </li>
                                        <li>
                                            <a href="#"
                                                class="flex align-middle items-center  px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                <img src="@/assets/images/edit-icon.png"
                                                    class="w-4.5 h-4.5 mr-2 inline-block" />Edit User</a>
                                        </li>
                                        <li>
                                            <a href="#"
                                                class="flex align-middle items-center  px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                <img src="@/assets/images/delete-icon-default.png"
                                                    class="w-4.5 h-4.5 mr-2  inline-block" />Delete</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody class="" v-else>
                        <tr>
                            <td colspan="8" class="text-center">No data found</td>
                        </tr>
                    </tbody>

                </table>
                <!-- </div>
                    </div> -->
            </div>

            <div class="mt-6 mb-2 w-full row justify-between px-4 pb-4">
                <div class="lg:w-1/2 w-full flex items-center lg:mt-2 mt-5">
                    <p class="dataset-info w-full lg:text-left text-center">Showing 1 to 7 of 100 entries
                    </p>
                </div>
                <div class="lg:w-1/2 w-full flex lg:mt-2 mt-5">
                    <div class="w-auto max-w-full inline-flex items-center lg:float-right lg:mr-0 mx-auto">
                        <vue-awesome-paginate class="paginate-container float-right float-"
                            :total-items="employees_list.length" :items-per-page="per_page" :max-pages-shown="3"
                            :show-ending-buttons="true" v-model="currentPage" :on-click="onClickHandler">

                            <template #prev-button>
                                <font-awesome-icon icon="fa-solid fa-angle-left" />
                            </template>
                            <template #next-button>
                                <font-awesome-icon icon="fa-solid fa-angle-right" />
                            </template>
                            <template #first-page-button>
                                <font-awesome-icon icon="fa-solid fa-angles-left" />
                            </template>
                            <template #last-page-button>
                                <font-awesome-icon icon="fa-solid fa-angles-right" />
                            </template>

                        </vue-awesome-paginate>
                    </div>
                </div>

            </div>
        </CardBody>
    </CardPartial>


    <!-- add/edit user -->

    <!-- Main modal -->
    <div id="employeeDataModal" tabindex="-1" aria-hidden="true" data-modal-backdrop="static"
        class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">

        >
        <div class="relative p-4 w-full max-w-md max-h-full">

            <!-- Modal content -->
            <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">

                <!-- Modal header -->
                <div
                    class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                        Add New Employee
                    </h3>
                    <button type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-toggle="employeeDataModal">
                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <Vueform add-class="vueFormContainer" :float-placeholders="false" v-model="employee_data_form"
                    validate-on="change|step" class="w-10/12 mx-auto" ref="employeeAddEditForm">
                    <TextElement name="first_name" input-type="text" label="First name" placeholder="Enter first name"
                        :field-name="'First name'" rules="required" class="mb-3" />
                    <TextElement name="last_name" input-type="text" label="Last name" placeholder="Enter last name"
                        :field-name="'Last name'" rules="required" class="mb-3" />
                    <TextElement name="email" input-type="email" label="Email" placeholder="Enter email"
                        :field-name="'Email'" rules="required" class="mb-3" />

                    <PhoneElement name="phone_number" label="Phone number" field-name="Phone number" rules="required"
                        class="mb-3" />

                    <SelectElement name="team" :native="false" :can-clear="true" label="Team" :items="[
                        'Frontend',
                        'Backend',
                        'Analysis',
                    ]" class="mb-3" />

                    <TagsElement name="project" :native="false" :can-clear="true" label="Project" :items="[
                        'Project 1',
                        'Project 2',
                        'Project 3',
                    ]" class="mb-3" />


                    <TagsElement name="role" :native="false" :can-clear="true" label="Roles" :items="[
                        'Role 1',
                        'Role 2',
                        'Role 3',
                    ]" class="mb-3" />


                    <ButtonElement button-type="button" button-label="Submit" :loading="false" :submits="true"
                        align="right" />


                </Vueform>

                <!-- {{ employee_data_form }} -->

            </div>
        </div>
    </div>

</template>


<script>
// import NavbarProtostars from '@/components/Layout/NavbarProtostars.vue';
// // import AudioRecorder from 'vue-audio-recorder'
import CardBody from '@/components/partials/card_components/CardBody.vue';
import { initFlowbite } from 'flowbite'
// import axios from 'axios';
// import BreadcrumbIndex from '@/components/breadcrumb/Index.vue'

export default {
    name: "UsersList",
    components: {},
    data() {
        let temp = this;
        return {
            employees_list: [
                { id: 1, name: "sara reda", email: "sara@mail.com", phone_number: "+201234567890", date_joined: "1 May 2024", team: "Fronend", roles: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet" },
                { id: 2, name: "mohamed fayez", email: "mohamed@mail.com", phone_number: "2+0123432123432", date_joined: "30 Apr 2024", team: "Backend", roles: "Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet" },

            ],

            per_page: 10,
            currentPage: 1,

            searchData: { search: null },

            employee_data_form: null
        }
    },
    mounted() {
        setTimeout(() => {
            initFlowbite();
        }, 500);


        console.log(this.$refs.employeeAddEditForm)
    },
    beforeUnmount() {
    },
    updated() {
    },
    watch: {
    },
    methods: {
    }
}
</script>
