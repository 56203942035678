<template>
    <div :class="'protostars-form-progress-container h-100'">

        <b-row class="w-100 h-100 mx-0">
            <b-col xl="3" lg="4" md="5"
                class="protostars-progress-bg-container protostars-container-border-radius position-fixed  h-100 px-1">
                <img src="@/assets/images/protostars/logo-light.png" class="logo" />
                <div class="wizard">
                    <div class="process process-protostars">
                        <ul>
                            <li class="step completed">
                                <div class="name"><span>Customer Jobs</span></div>
                            </li>
                            <li class="step current">
                                <div class="name"><span>Customer Gains</span></div>
                            </li>
                            <li class="step ">
                                <div class="name"><span>Customer Pains</span></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </b-col>
            <b-col xl="9" lg="8" md="7" :class="'form-wizard-container mx-1 '">
                <b-row class="justify-content-center">
                    <b-col cols="11">
                        <b-row class="mx-0">
                            <b-col>
                                <!-- <Language :form_group_class="form_group_class"
                                    :select_language_class="select_language_class" /> -->
                            </b-col>

                        </b-row>

                        <!-- <b-progress :value="tabIndex + 1" :max="total_Steps"
                            :class="'progress-cyber-risk my-1 ' + progress_class"></b-progress> -->
                    </b-col>
                </b-row>
                <b-form novalidate class="register-form my-5" ref="customerRegisterForm">
                    <b-row class="mt-1">
                        <b-col lg="6" md="9" sm="11" class="m-auto p-0">
                            <b-card no-body class="card-transparent-background mt-3">
                                <b-card-title>
                                    What are your customer jobs, pains & gains?
                                </b-card-title>
                                <b-card-sub-title>
                                    Please provide us with your details to start your risk assessment now.
                                </b-card-sub-title>

                                <b-card-body>

                                </b-card-body>

                                <b-card-footer class="py-3 px-0">
                                    <b-button-group>
                                        <b-button variant="protostars-secondary-outline" class="btn-protostars float-left">Back</b-button>
                                        <b-button variant="protostars-primary" class="btn-protostars float-right">Save</b-button>
                                    </b-button-group>
                                </b-card-footer>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-form>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import $ from 'jquery'
export default {
    name: "AddCustomerCards",
    data() {
        let temp = this;
        return {

        }
    },
    mounted() {
        // $("body").removeClass('background-body')
        $(".navbar").css("display", "none")
    }

}
</script>
